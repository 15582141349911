.tool_item_container {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.tool_item_containerXsDown {
  display: flex;
  align-items: center;
  overflow: auto;
}

.tool_item_link {
  text-decoration: none;
}

.tool_item_itemBox {
  height: 170px;
  min-width: 280px;
  max-width: 290px;
  overflow-x: hidden;
  margin-right: 15px;

  @media (max-width: 599px) {
    min-width: 98%;
    margin-right: 2%;
  }
}

.tool_item_toolImage {
  display: flex;
  align-items: center;
  width: 100%;
  background-position: center;
  background-size: cover;
  height: 100%;

  @media (max-width: 599px) {
    min-width: 100%;
  }
}
.tool_item_toolContainerText {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  width: 285px;
  height: 169px;

  @media (max-width: 599px) {
    min-width: 100%;
  }
}

.tool_item_toolText {
  color: #fff;
  font-size: 20px;
  margin-left: 12px;
  margin-top: 40px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  height: 55px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tool_item_root {
  display: flex;
  justify-content: center;
  width: 101%;
}
