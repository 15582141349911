.test_classContainer {
  margin-bottom: 60px;
}

.test_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 220px;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 20px;
  padding-top: 25px;
  padding-left: 8vw;
  padding-right: 8vw;

  @media (max-width: 600px) {
    margin-bottom: 50px;
  }
}

.test_BreadCrumbLink {
  color: #fff !important;
  text-transform: uppercase !important;
  text-decoration: none !important;
  font-size: 10pt !important;

  @media (max-width: 436px) {
    font-size: 11px !important;
  }
}

.test_BreadCrumb {
  color: #fff !important;
  margin-bottom: 20px !important;
  font-weight: bold !important;

  @media (max-width: 599px) {
    margin-bottom: 10px !important;
    margin-top: -14px !important;
  }
}

.test_BreadCrumbLabel {
  color: #fff !important;
  text-transform: uppercase !important;
  font-size: 10pt !important;
}

.test_titleBar {
  display: flex;
  align-items: center;
  padding-left: 30px;
  color: #fff;
  background-color: #f8b249;
  font-size: 13pt;
  height: 75px;
}

.test_subTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20pt;
  margin-bottom: 30px;

  @media (max-width: 599px) {
    font-size: 18px;
  }
}

.test_allButton {
  display: flex;
  text-transform: uppercase;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border: #0d509b 1px solid;
  background-color: #0d509b;
  color: #fff;
  border-radius: 0px;
  font-size: 12pt;
  padding-left: 10px;
  transition: color 0.3s ease-out, border-color 0.3s ease-out;

  &:hover {
    color: #5dacfc;
    border-color: #5dacfc;
    cursor: pointer;
  }

  @media (max-width: 599px) {
    font-size: 12px;
    height: 35px;
    color: #fff;
    width: 100%;
  }
}

.test_cardQuestion {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.test_textQuestion {
  font-size: 14pt;
  margin-bottom: 20px;

  @media (max-width: 599px) {
    font-size: 15px;
  }
}

.test_containerAnswer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  margin-bottom: 10px;
  border: 2px solid #d3d3d3;
  cursor: pointer;
  font-size: 18px;
  color: #2e2e2e;
  padding-left: 20px;
  padding-right: 10px;

  @media (max-width: 599px) {
    height: 45px;
    font-size: 16px;
  }
}

.test_containerAnswerSelected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  margin-bottom: 10px;
  background-color: #0b4e9a;
  cursor: pointer;
  font-size: 18px;
  color: #fff;
  padding-left: 20px;
  padding-right: 10px;

  @media (max-width: 599px) {
    font-size: 16px;
    height: 45px;
  }
}

.test_containerAnswerCorrect {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  margin-bottom: 10px;
  background-color: #1bb37d;
  cursor: pointer;
  font-size: 18px;
  color: #fff;
  padding-left: 20px;
  padding-right: 10px;

  @media (max-width: 599px) {
    font-size: 16px;
    height: 45px;
  }
}

.test_containerAnswerWrong {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  margin-bottom: 10px;
  background-color: #fb6347;
  cursor: pointer;
  font-size: 18px;
  color: #fff;
  padding-left: 20px;
  padding-right: 10px;

  @media (max-width: 599px) {
    font-size: 16px;
    height: 45px;
  }
}

.test_positionBtnRemake {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
