.next_benefit_container {
  width            : 100%;
  min-width        : 650px;
  //height         : 600px;
  margin-top       : 30px;
  object-fit       : cover;
  display          : flex;
  align-items      : flex-start;
  justify-content  : center;
}

.next_benefit_itemBox {
  background-color    : #fff;
  display             : flex;
  flex-direction      : column;
  align-items         : center;
  //height          : 620px;
  //min-height        : 598px;
  margin              : 10px 15px 10px 10px;
  min-width           : 233px;
  max-width           : 233px;

  @media only screen and (max-width: 1230px) {
    min-width  : 205px;
    max-width  : 205px;
    //height   : 165px;
    overflow   : auto;
  }

  @media (max-width: 599px) {
    min-width  : 205px;
    max-width  : 205px;
    //height   : 165px;
  }
}

.next_benefit_header {
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  justify-content : center;
  height          : 160px;
  min-height      : 160px;
  width           : 100%;
  background-color: #fff;
}

.next_benefit_levelText {
  color      : #2e2e2e;
  font-size  : 20px;
  font-weight: 400;
  line-height: 30px;
}

.itemLi {
  font-size: 20px;
  color    : #0d509b;
}

.next_benefit_text {
  color        : #2e2e2e;
  font-size    : 12px;
  text-align   : center;
  font-weight  : 400;
  max-width    : 95%;
  margin-bottom: 8px;

  @media only screen and (max-width: 900px) {
    font-size: 12px;
  }
}

.next_benefit_description {
  min-height     : 135px;
  max-height     : 135px;
  display        : flex;
  flex-direction : column;
  overflow       : auto;
  justify-content: flex-start;
  align-items    : center;
  padding        : 10px 10px 10px 10px;
  display        : flex;
}

.next_benefit_teste3 {
  color    : #45af98;
  font-size: 14px;
  margin   : 2px;
}

.next_benefit_textContent {
  color      : #2e2e2e;
  font-size  : 16px;
  font-weight: 500;
  line-height: 30px;
  text-align : center;
}

.next_benefit_header2 {
  width          : 100%;
  height         : 80px;
  display        : flex;
  flex-direction : column;
  align-items    : center;
  justify-content: center;
}

.next_benefit_description2 {
  min-height     : 327px;
  max-height     : 327px;
  display        : flex;
  flex-direction : column;
  overflow       : auto;
  justify-content: flex-start;
  align-items    : center;
  padding        : 10px 10px 10px 10px;
  display        : flex;
}

.next_benefit_hr {
  background-color: #E6E6E6;
  width           : 90%;
  height          : 1px;
}