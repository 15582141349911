.latest_achiv_icon_study {
  width: 108px;
  margin-top: -26px;
  margin-left: -16px;

  @media (max-width: 599px) {
    width: 60px;
    height: 60px;
    margin-top: 20px;
    margin-bottom: 22px;
  }

  @media (max-width: 1066px) {
    margin-left: -22px;
  }

  @media (max-width: 1135px) {
    margin-top: -20px;
  }
}

.latest_achiv_pL {
  padding: 0 20px;
}

.latest_achiv_container {
  width: 100%;
}

.latest_achiv_text {
  display: flex;
  flex-direction: column;
}

.latest_achiv_buttonContainer {
  margin-top: 20px;
}

.latest_achiv_txtTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 2px;
}

.latest_achiv_txtDescription {
  font-size: 14px;
}
