.dados_container {
  width: 100%;
  height: 489px;
  border-radius: 2px;
  padding: 30px;
  overflow: auto;

  &.border_hidden {
    border: none;
  }

  strong{
    margin-bottom: 10px;
  }

  li {
    text-decoration: none;
    list-style-type: none;
    line-height: 28px;
  }
}