.classContainer {
  margin-bottom: 70px !important;
  padding-right: 10px;

  @media only screen and (max-width: 599px) {
    padding-right: 0px;
  }
}


.courses_breadCrumbLink {
  color: #fff !important;
  text-transform: uppercase !important;
  text-decoration: none !important;
  font-size: 10pt !important;

  @media (max-width: 436px) {
    font-size: 11px !important;
  }
}

.courses_breadCrumb {
  color: #fff !important;
  margin-bottom: 20px !important;
  font-weight: bold !important;

  @media (max-width: 599px) {
    margin-bottom: 10px !important;
    margin-top: -14px !important;
  }
}

.courseItem {
  min-height: 300px;
  background-color: #397FC0;
  border-radius: 0px;
  margin-bottom: 10px;
  box-shadow: 4px 4px 10px #0003;
  padding: 20px;
  padding-top: 25px;

  @media only screen and (max-width: 599px) {
    min-height: 250px;
  }
}

.course_TitleBar {
  color: #fff;
  font-size: 25px;
  font-weight: bold;
  padding-bottom: 20px;

  @media only screen and (max-width: 599px) {
    font-size: 20px;
  }
}

.container_recomendados {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  width: 100%;
  margin-top: -250px;
  overflow-x: scroll;
  overflow-y: hidden;
  box-sizing: border-box;
  padding: 30px 0px 10px 0px !important;

  @media only screen and (max-width: 599px) {
    margin-top: -205px;
  }
}

.msg {
  font-size: 14px;
  margin-left: 19px;
  color: #5c5a5a;
}

.coutseSpacing {
  opacity: 0;
  margin-left: 48px;
}
