@import '../../Layout/variables';

.photograph-dialog-incomes {
  z-index: 1000 !important;

  .loading-area {
    justify-content: center;
    margin-top: 40px;
  }
 

  .MuiPaper-rounded {
    border-radius: 0;
  }

  .dialog-header {
    margin: 0;
    display: flex;
    padding: 10px;
    position: relative;
    height: 60px;
    background-color: $primary-color;
    align-items: center;

    .MuiFormControl-root {
     padding: 0;
     margin: 0;
    }

    .MuiInputBase-root {
      #month-select{
        margin-top: 1px;
      }
      
      border-radius: 0;
      border: none;
      font-family: 'Caros Soft Regular';
      font-size: 14px;
      color: white;
      padding-left: 4px; 
      text-decoration: underline;
      input {
        cursor: pointer;
        padding: 0;
        font-size: 14px;
        text-transform: uppercase;
        text-decoration: underline;
      }

      .MuiSvgIcon-root {
        color: white;
      }

      fieldset {
        border: none;
        padding: 0;
      }
    }

    .MuiSelect-root {
      
      border: none;
      padding: 0 30px 0 0px;

      border-radius: 0;
      &:focus {
        background-color: inherit;
      }
    }



    .close-button {
      position: absolute;
      right: 5px;
      top: 5px;
      color: white;
    }

    &::before {
      content: '';
      background-image: url(/images/mdb-icon-on-dark.svg);
      height: 41px;
      width: 38px;
      display: block;
      position: absolute;
      top: 7px;
      left: 15px;
    }

    h2 {
      margin: 0 0 0 48px;
      color: #fff;
      font-size: 14px;
      font-family: 'Caros Soft Regular';
      font-weight: 300;
      text-transform: uppercase;
    }
  }

  .progress-floater {
    .close-button {
      position: absolute;
      right: 5px;
      top: 5px;
      color: white;
    }
  }

  .dialog-content {
    height: 550px;
    @media (max-height: 800px) {
      height: 400px;
    }
    @media (max-height: 615px) {
      height: 300px;
    }

    overflow-x: scroll;
    background-color: #fff;
    padding: 30px 40px 0 40px;
    text-align: center;
    border-top: none;
    color: #444448;
    flex-wrap: wrap;

    h1 {
      margin-top: 0;
      color: $primary-color;
      font-size: 42px;
    }

    h4 {
      margin-bottom: 0;
      color: $secondary-color;
      font-size: 16px;
    }

    h6 {
      font-size: 14px;
      font-family: 'Caros Soft Regular';
      text-transform: uppercase;
      font-weight: 300;
      margin: 23px 0;
      & span {
        color: #004178;
        font-family: 'Caros Soft ExtraBold';
      }
    }

    p {
      margin: 0;
    }

    span[role='button'] {
      color: $secondary-color;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .dialog-footer {
    background-color: #fff;
    padding: 20px 40px 30px 40px;

    &.space-between {
      justify-content: space-between;
    }
  }

  .MuiButton-contained.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .MuiDialog-paperWidthXs {
    max-width: 475px;
  }
  .MuiDialog-paperWidthMd {
    max-width: 820px;
  }
}
