.photographic-tables {
  table {
    background-color: #fff;
    thead {
      tr {
        border-left: 1px solid rgba(0, 0, 0, 0);
        border-right: 1px solid rgba(0, 0, 0, 0);
        th {
          color: #004178;
          font-family: 'Caros Soft Bold';
          font-size: 12px;
          text-transform: uppercase;
          border-bottom: none;
        }
      }
    }
    tbody {
      tr {
        border-top: 1px solid rgba(0, 0, 0, 0);
        border-left: 1px solid rgba(0, 0, 0, 0);
        border-right: 1px solid rgba(0, 0, 0, 0);
        

        &:hover {
          border: 1px solid #4faf98;
          box-shadow: 0 0 2px #4faf98;

          td {
            &:last-child {
              button {
                color: rgba(0, 65, 120, 0.7);
              }
            }
          }
        }

        td {
          color: #2e3b52;
          font-family: 'Caros Soft Regular';
          font-size: 14px;
          border: 0;
          &:last-child {
            button {
              cursor: pointer;
              border: none;
              background-color: transparent;
              align-items: right;
              color: rgba(0, 65, 120, 0.5);
              &:hover {
                color: #004178;
              }
            }
          }
        }
        &:nth-of-type(even) {
          background-color: rgba(203, 221, 254, 0.25);
        }
      }
    }
  }
}
