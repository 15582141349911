@import '../../../Layout/variables';
.incomes-final {
  .dialog-header {
    h2 {
      margin: 0 0 0 48px;
      color: #fff;
      font-size: 14px;
      font-family: 'Caros Soft Regular';
      font-weight: 300;
      text-transform: uppercase;
    }
  }
  .dialog-content {
    display: flex;
    justify-content: center;
    .content-holder {
      max-width: 480px;
      padding: 0 20px;

      h1 {
        font-size: 28px;
        font-family: 'Caros Soft ExtraBold';
        text-transform: uppercase;
        margin: 0;
      }

      h6 {
        font-size: 14px;
        font-family: 'Caros Soft Regular';
        text-transform: uppercase;
        font-weight: 300;
        margin: 0;
      }

      img {
        margin: 20px 0 30px 0;
      }
      p {
        text-align: center;
      }
      .bold {
        font-weight: bold;
      }
      .clickhere {
        color: #004178;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .dialog-footer {
    justify-content: flex-end;
  }
}
