.txtTitle{
  font-size: 28px;
  font-weight: 500;
}

.cardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 40px;
  position: relative;

  .card2{
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 152px;
    height: 180px;
    border-radius: 1px;
    background: #FFF9;
    // margin: 0px 0px 12px 12px;
  }

  .card {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 152px;
    height: 180px;
    border-radius: 1px;
    background: #FFF9;
    border: 1px solid #e2e2e2;
    border-bottom: 6px solid #e5e5e5;
    margin: 0px 0px 12px 12px;
    &:nth-child(1) {
      // margin: 0px 0px 12px 0px;
      margin: 0px 15px 20px 0px;
    }

    &.darkBlue {
      border-bottom: 6px solid #004178;
    }
    &.blue {
      border-bottom: 6px solid #85bffd;
    }
    &.red {
      border-bottom: 6px solid #F62121;
    }
    &.green {
      border-bottom: 6px solid #A3D2B1;
      
    }

    .perfil {
      margin-top: 20px;
      width: 64px;
      height: 64px;
      border-radius: 100px;
      background: #e5e5e5;

      &.started {
        background: #85bffd;
      }
      &.darkBlue{
        background: #004178;
      }
      &.blue {
        background:#85bffd;
      }
      &.red {
        background:#F62121;
      }
      &.green {
        background:#A3D2B1;
      }
      &.false{
        img {
          -webkit-filter: grayscale(1);
         }
        
      }
    }

    .titleTable {
      margin-top: 5px;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.1;
      overflow: hidden;
      max-height: 40px;
      width: 97%;
      text-align: center;
      display: -webkit-box;
      white-space: normal;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    .coins {
      font-size: 11px;

    }

    .subText {
      position: absolute;
      bottom: 2px;
      font-size: 12px;
      color: #666;

      &.started {
        color: #85bffd;
      }
    }

    .txtReward2 {
      position: absolute;
      font-weight: bold;
      bottom: 2px;
      font-size: 12px;
      color: #666;
      cursor: default;

      &.darkBlue {
        color: #d3d3d3;
      }
    }

    .txtReward {
      position: absolute;
      font-weight: bold;
      bottom: 2px;
      font-size: 12px;
      color: #004178;
      cursor: pointer;


      &.blue {
        color: #85bffd;
      }
      &.darkBlue {
        color: #004178;
      }
      &.red {
        color: #F62121;
      }
      &.green {
        color: #A3D2B1;
      }

      &:hover {
        transition: all 200ms;
        color: #85bffd;
      }
    }
  }
}

.open_table_fragment {
  display: flex;
  position: relative;
}

.containerExpand {
  height: 194px;
  z-index: 9999;
  animation: screen 0.4s cubic-bezier(0.1, 0.82, 0.25, 1);

  @keyframes screen {
    0% {
      transform: scale(1.4);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
.card3{
    z-index: 9999;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    height: 240px;
    border-radius: 1px;
    margin: 0px 0px 12px 12px;
    background-color: #d3d3d3;
    color: #2E2E2E;;

    &.darkBlue {
      background-color: #004178;
      color: #FFFFFF;
    }
    &.blue {
      background-color: #85BFFD;
    }
    &.red {
      background-color: #F62121;
      color: #FFFFFF;
    }
    &.green {
      background-color: #A3D2B1;
    }
    &.white {
      background-color: #FFFFFF;
    }

    &:nth-child(1) {
      margin: 0px 0px 12px 0px;
    }

    &.started {
      background: #85bffd;
    }
}
  .card {
    z-index: 9999;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 202px;
    height: 315px;
    border-radius: 1px;
    border: 1px solid #e2e2e2;
    border-bottom: 6px solid #e5e5e5 !important;
    margin: 0px 0px 12px 12px;
    color: #2E2E2E;
    background-color: #d3d3d3;
    
    &.darkBlue {
      background-color: #004178;
      border-bottom: 6px solid #013460!important;
    }
    &.blue {
      background-color: #85BFFD;
      border-bottom: 6px solid #619EDF!important;
    }
    &.red {
      background-color: #F62121;
      border-bottom: 6px solid #C61313 !important;
      color: #fff;
    }
    &.green {
      background-color: #A3D2B1;
      border-bottom: 6px solid #7FAC8C !important;
    }

    &:nth-child(1) {
      margin: 0px 0px 12px 0px;
    }

    &.started {
      border-bottom: 6px solid #619edf;
      background: #85bffd;
    }
    &.white {
      background: #FFF;
    }
    
    .cardMessage{
      display: flex;
      align-items: center;
      justify-content: center;

      .messagePurchase {
        color: #45AF98;
        font-size: 12px;
  
      }
      .messageError {
        font-size: 12px;
        color: #F62121;
      }
    }

 

    .perfil {
      margin-top: 20px;
      width: 102px;
      height: 102px;
      border-radius: 100px;
      background: #e5e5e5;

      &.started {
        background: #b8d6f6;
      }
      &.darkBlue {
        background:#115792;
      }
      &.blue {
        background:#B8D6F6;
      }
      &.red {
        background:#FB4B4B;
      }
      &.green {
        background:#B6E3C3;
      }
    }

    .titleTableExpaned {
      max-height: 50px;
      width: 97%;
      text-align: center;
      
    }

    .subTextExpanded {
      position: absolute;
      bottom: 0;
      font-size: 12px;
      color: #666;
      margin-top: 15px;

      &.started {
        color: #004178;
      }
    }

    .description {
      margin-top: 5px;
      text-align: center;
      width: 90%;
      font-size: 12px;
      font-weight: 400;

      &.started {
        color: #004178;
      }
    }

    .description2 {
      margin-top: 13px;
      text-align: center;
      width: 90%;
      font-size: 12px;
      font-weight: 400;
    }

    .containerBtn {
      display: flex;
      flex-direction: row;
      justify-Content: space-between;
      padding: 5px;

      .containerBtnCenter{
        display: flex;
        justify-Content: center;
        width: 100%;
      }

    }
    .btn{
      background-color: #004178;
      font-size: 12px;
      color: #fff;
      width: 90px;
      border-radius: 0px;

      &.cancelar {
        color: #004178;
        background-color: #fff;
        border: 1px solid #004178;
      }
    }

    .txtRewardExpanded {
      position: absolute;
      bottom: 0;
      font-weight: bold;
      font-size: 12px;
      color: #004178;
      cursor: pointer;

      &.darkBlue {
        color: #70B1E8;
      }
      //  &.blue {
      //   color: #85bffd;
      // }
      // &.red {
      //   color: #F62121;
      // }
      // &.green {
      //   color: #A3D2B1;
      // }

      &:hover {
        transition: all 200ms;
        color: #85bffd;
      }
    }
  }
}