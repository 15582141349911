@import '../../../Layout/variables';

.persona-list-body {
    .content-holder {
        padding: 0;
    }

    .persona-list-content {

        .persona-item-holder {

            padding: 5px;

            .persona-item {
                position: relative;
                height  : 250px;
                cursor  : pointer;

                .persona-label {
                    position: absolute;
                    top     : 5px;
                    right   : 10px;
                }

                img {
                    max-width : 60%;
                    max-height: 60%;
                }
            }
        }

        a {
            color          : $primary-color;
            text-decoration: none;
        }

        ul {
            list-style: none;

            li::before {
                content    : "\2022";
                color      : $primary-color;
                font-weight: bold;
                display    : inline-block;
                width      : 1em;
                margin-left: -1em;
            }
        }
    }
}