.default-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #ccc;
  font-size: 21px;
}

.containerInbox {
  margin-bottom: 60px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 599px) {
    padding-right: 0px;
  }

  .containerGreen {
    min-height: 300px;
    background-color: #45af98;
    width: 100%;
    margin-bottom: 10px;
    box-shadow: 4px 4px 10px #0003;
    padding: 20px;
    padding-top: 25px;
    color: #fff;
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .articleButton.invert {
      margin-top: 6px;
    }
    @media only screen and (max-width: 599px) {
      min-height: 250px;
      font-size: 20px;
    }

    span{
      margin-left: 35px;
      font-weight: 700;
    }
  }

  .container_principal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    height: 100vh;
    margin-top: -190px;
    overflow-x: scroll;
    overflow-y: hidden;
    box-sizing: border-box;
    background-color: transparent;
    margin-bottom: 50px;


    @media only screen and (max-width: 599px) {
      margin-top: -205px;
    }


    .inboxBoxLeft{
      background-color: #FFF;
      width: 35%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .inboxHeaderBoxLeft{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 107px;
        min-height: 107px;

        .inboxHr{
          background-color: #C4C4C4;
          height: 1px;
          width: 60%;
        }
        .inboxContainerBtn{
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
  
          @media only screen and (max-width: 1100px) {
            width: 100%;
            flex-direction: column;
            height: 500px;
            padding: 10px 10px;
          }
        }
      }

      .inboxPosts{
        min-height: 90px;
        max-height: 795px;
        height: 795px;
        overflow-y: auto;
      
        .inboxContainerMsg{
          width: 100%;
          display: flex;
          flex-direction: row;
          height: 112px;
          margin-bottom: 2px;

          .inboxMsg{
            background-color: #FFF;
            width: 95%;
            height: 112px;
            display: flex;
            flex-direction: column;
            padding-left: 25px;
            justify-content: center;
            cursor: pointer;
  
            &.naoLida{
              background-color: #F1EFE3;
            }
  
            &.warning{
              background-color: #FCDBCF;
            }
  
            .containerIconsMessage{
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-right: 25px;
  
              .inboxMsgTitle{
                display: flex;
                align-items: center;
                color: #2e2e2e;
                font-size: 16px;
                font-weight: 500;

                img {
                  width: 30px;
                  height: 30px;
                  margin-right: 10px;
                  border-radius: 50%;
                }
                .inboxMsgNameAvatar{
                  width: 30px;
                  height: 30px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-weight: bold;
                  margin-right: 10px;
                  border-radius: 50%;
                  background-color: #d3d3d3;
                  text-transform: uppercase;
                }
              }
            }
            .inboxMsgDate{
              color: #989898;
              font-size: 12px;
              margin-left: 43px;
          }
        }

        }
        
      .inboxMsgSide{
        background-color: #FFF;
        width: 20%;
        height: 112px;
        display: flex;
        justify-content: center;
        align-content: center;
        cursor: pointer;

        &.naoLida{
          background-color: #F1EFE3;
        }

        &.warning{
          background-color: #FCDBCF;
        }
        div{
          display: flex;
          align-items: center;
        }
      }
    }

    .inboxBoxFooter{
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 107px;
      min-height: 107px;
      bottom: 0;


      .inboxHr{
        background-color: #C4C4C4;
        height: 1px;
        width: 60%;
        margin-bottom: 40px;

        @media only screen and (max-width: 1100px) {
          margin-bottom: 20px;
        }
      }
      .inboxContainerBtn2{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;

        @media only screen and (max-width: 1100px) {
          width: 100%;
          flex-direction: column;
          height: 500px;
          padding: 10px 10px;
        }
      }
    }
  }

    .inboxBoxRight{
      background-color: #FFF;
      width: 63%;
      display: flex;
      flex-direction: column;
      overflow: auto;
      padding-bottom: 30px;

      .inboxHeaderBoxRight{
        height: 75px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 25px;

        .inboxBoxRightTitle1{
          display: flex;
          align-items: center;
          color: #004178;
          font-size: 16px;
          font-weight: 500;

          img{
            width: 50px;
            height: 50px;
            margin-right: 10px;
            border-radius: 50%;
          }
          .inboxBoxRightAvatarname{
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            margin-right: 10px;
            border-radius: 50%;
            background-color: #d3d3d3;
            text-transform: uppercase;
          }
        }

        .inboxBoxRightTitle2{
          color: #989898;
          font-size: 12px;
        }
      }

      .inboxRightContent{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0px 60px 0 60px;


        .inboxText{
          height: 70%;
          max-height: 70%;
        

          .message{
            min-width: 20%;
            background-color: #F1EFE3;
            padding: 25px;
            box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
            border-radius: 10px;
            color: #2E2E2E;
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            color: #2E2E2E;
            margin-bottom: 25px;
            word-break: break-word;
            text-decoration: none;
            cursor: default;

            &.received{
              background-color: #CBDDFE;
            }
          }

          div{
            color: #148;
            cursor: pointer;
            text-decoration: underline;
          }
        }

        .inboxConaterSendMessage{
          display: flex;
          flex-direction: column;
          height: 30%;
          max-height: 30%;
          min-height: 30%;

          ::-webkit-input-placeholder {
            color: #B8B8B8;
         }

           .perfilInputText2 {
            height: 150px;
            width: 100%;
            padding: 10px;
            font-size: 15px;
            border: 1px solid #ECEBED;
            background-color: #fff;
            padding-left: 15px;
            margin-bottom: 35px;
            box-sizing: border-box;
            resize: none;

            @media (max-width: 599px) {
              font-size: 12px;
              height: 30px;
            }
            
          }
          .containerBtnSendMessage{
            width: 100%;
            display: flex;
            flex-direction: row-reverse;
          }
          
        }
      }
    }
  }
}
// Mobile 

.containerInboxMobile {
  padding-right: 10px;  
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding-right: 0px;
  width: 100%;
  position: absolute;
  right: 0;
  height: 100vh;

  .containerGreenMobile {
    height: 200px;
    background-color: #45af98;
    width: 100%;
    margin-bottom: 32px;
    box-shadow: 4px 4px 10px #0003;
    padding: 20px;
    padding-top: 25px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;

    .teste{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: -10px;
    }

    div{
      margin-left: 2px;
    }
  }

  .containerPrincipalMobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90vw;
    height: 90vh;
    overflow: hidden;
    box-sizing: border-box;
    margin-top: -144px;
    margin-bottom: 70px;
    background-color: #FFF;


    .inboxMobile{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .inboxHeaderMobile{
        width: 100vw;
        display: flex;
        align-items: center;
        padding: 20px 20px;
        flex-direction: column;
        justify-content: space-between;
        height: 110px;
        min-height: 110px;

        .inboxContainerBtnMobile{
          width: 100vw;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 0px 14% 0 2%;
        }
        .inboxHrMobile{
          margin-top: 10px;
          background-color: #C4C4C4;
          height: 1px;
          width: 60%;
          margin-left: -11%;

        }

      }

      .inboxPostsMobile{
        min-height: 90px;
        max-height: 697px;
        height: 697px;
        overflow-y: auto;

        .inboxContainerMsgMobile{
          width: 90%;
          display: flex;
          flex-direction: row;
          height: 90px;
          margin-bottom: 2px;

          .inboxMsgMobile{
            background-color: #FFF;
            width: 95%;
            height: 90px;
            display: flex;
            flex-direction: column;
            padding-left: 25px;
            padding-right: 10px;
            justify-content: center;
            margin-bottom: 2px;
            cursor: pointer;
  
            &.naoLida{
              background-color: #F1EFE3;
            }
  
            &.warning{
              background-color: #FCDBCF;
            }
  
            .containerIconsMessageMobile{
              display: flex;
              align-items: center;
              justify-content: space-between;
  
              .inboxMsgTitleMobile{
                display: flex;
                align-items: center;
                color: #2e2e2e;
                font-size: 14px;
                font-weight: 500;

                img{
                  width: 30px;
                  height: 30px;
                  margin-right: 10px;
                  border-radius: 50%;
                }
                .inboxMsgNameAvatarMobile{
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-transform: uppercase;
                  width: 30px;
                  height: 30px;
                  margin-right: 10px;
                  border-radius: 50%;
                  background-color: #d3d3d3;
                  font-weight: bold;
                }
              }
  
            }
            .inboxMsgDateMobile{
              color: #989898;
              font-size: 12px;
              margin-left: 35px;
            }
        }
         .inboxMsgSideMobile{
          background-color: #FFF;
          display: flex;
          justify-content: center;
          align-content: center;
          cursor: pointer;
  
          &.naoLida{
            background-color: #F1EFE3;
          }
  
          &.warning{
            background-color: #FCDBCF;
          }
          div{
            display: flex;
            align-items: center;
          }
         } 
      }
    }

    .inboxBoxFooterMobile{
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 107px;
      min-height: 107px;
      bottom: 0;
      

      .inboxHrMobile{
        background-color: #C4C4C4;
        height: 1px;
        width: 60%;
        margin-bottom: 40px;
      }
      .inboxContainerBtnMobile{
        display: flex;
        width: 100%;
        padding-left: 29px;
        padding-right: 30px;
        // width: 100%;
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        // justify-content: space-evenly;
        
      }
    }
  }

    .inboxBoxMessageMobile{
      background-color: #FFF;
      width: 90vw;
      display: flex;
      flex-direction: column;

      .inboxHeaderBoxMobile{
        height: 80px;
        display: flex;
        flex-direction: column;
        padding: 25px;
       

        .inboxBoxMessageTitle1{
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #004178;
          font-size: 16px;
          font-weight: 500;

          .Title1BoxMobile{
            display: flex;
            justify-content: center;
            align-items: center;

            img{
              margin-right: 10px;
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
            .Title1BoxMobileAvatarName{
              display: flex;
              align-items: center;
              justify-content: center;
              text-transform: uppercase;
              width: 30px;
              height: 30px;
              margin-right: 10px;
              border-radius: 50%;
              background-color: #d3d3d3;
              font-weight: bold;
            }
          }
        }

        .inboxBoxMessageTitle2{
          color: #989898;
          font-size: 12px;
          margin-left: 41px;
        }
      }

      .inboxContentMobile{
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        .inboxTextMobile{
          height: 70%;
          min-height: 120px;
          padding: 10px 20px 20px 20px;
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          color: #2E2E2E;
          overflow: auto;

          .message{
            min-width: 20%;
            background-color: #F1EFE3;
            padding: 25px;
            box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
            border-radius: 10px;
            color: #2E2E2E;
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            color: #2E2E2E;
            margin-bottom: 25px;
            word-break: break-word;
            text-decoration: none;
            cursor: default;


            &.received{
              background-color: #CBDDFE;
            }
          }
          div{
            color: #148;
            cursor: pointer;
            text-decoration: underline;
          }
        }

        .inboxConaterSendMessageMobile{
          height: 200px;
          min-height: 200px;
          display: flex;
          flex-direction: column;
          padding: 10px;

          ::-webkit-input-placeholder {
            color: #B8B8B8;
          }

          .perfilInputText2Mobile{
            height: 65px;
            width: 100%;
            padding: 10px;
            font-size: 15px;
            border: 1px solid #ECEBED;
            background-color: #fff;
            padding-left: 15px;
            margin-bottom: 10px;
            box-sizing: border-box;
            resize: none;

            @media only screen and (min-height:730px){
              height: 100px;
            }
          }
          .containerBtnSendMessageMobile{
            
            width: 100%;
            display: flex;
            flex-direction: row-reverse;
          }
        }
      }
    }
  }
}

