.last_certificate_icon_study {
  width      : 90px;
  margin-top : -17px;
  margin-left: -16px;


  @media (max-width: 599px) {
    width        : 60px;
    height       : 60px;
    margin-top   : 20px;
    margin-bottom: 22px;
  }

  @media (max-width: 1066px) {
    margin-left: -22px
  }

  @media (max-width: 1135px) {
    margin-top: -20px
  }
}

.last_certificate_pL {
  padding: 0 20px
}

.last_certificate_container {
  width: 100%;
}

.last_certificate_text {
  display       : flex;
  flex-direction: column;
}


.last_certificate_txtTitle {
  font-size  : 42px;
  font-weight: 600;
}

.last_certificate_msg {
  font-size  : 16px;
  font-weight: 400;
  line-height: 20px;
}