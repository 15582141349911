@import '../../../Layout/variables';

.article_itemContainer {
  width: 99% !important;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08) !important;
  margin-bottom: 15px !important;
  height: 124px !important;
  flex-wrap: nowrap !important;
  &.no-scroll {
    overflow: hidden !important;
  }

  &.right {
    width: 100% !important;
    height: 180px !important;
    display: flex !important;
    flex-direction: column !important;

    @media (max-width: 599px) {
      overflow: hidden !important;
    }
  }
}

.article_image {
  width: 182px;
  height: 124px;
  object-fit: cover;
  position: relative;

  .readed-article{
    position: absolute;
    bottom: 0;
    right: 0;
    fill: $secondary-color;
    stroke: $secondary-color;
    width: 32px;
    height: 32px;
    background: rgba(209, 209, 209, .3);
  }

  @media (max-width: 599px) {
    width: 35%;
  }

  &.right {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center center;
    background-size: cover;
  }

}

.article_infoContainer {
  flex-grow: 2;
  height: 100%;
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &.right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.article_InfoTitle {
  width: 100%;
  padding: 30px 20px 0px 23px;

  font-size: 18px;
  font-weight: bold;
  color: #2e2e2e;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: -webkit-box;
  overflow: hidden;
  min-height: 100px;
  white-space: normal;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  @media (max-width: 599px) {
    width: 250px;
    height: 40px;
    overflow: auto;
    font-size: 12px;
  }
}

.article_courseButtonContainer {
  width: 170px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.right {
    width: 100%;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.article_borderRight {
  background-color: #cbddfe;
  width: 5px;
  height: 100%;
}

.article_container {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 180px;
  margin-top: -180px;
}

.article_toolTitle {
  color: #fff;
  font-size: 16px;
  text-align: center;
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;

  @media (max-width: 599px) {
    margin-top: 42px;
  }
}

.article_addFavorite {
  padding: 3px;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  position: relative;
  top: -123px;
  left: 5px;
  border: none;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 599px) {
    top: -180px;
  }
}
