.menuProfile{
  z-index: 1;
}
.menuProfile_positionLevels {
  color: #fff;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  flex-wrap: nowrap;
  font-weight: bold;
}

.menuProfile_menuList {
  width: 100% !important;
  overflow: auto !important;
  display: flex !important;
  align-items: center !important;
}

.menu_profile_TextField {
  width: 280px !important;
  height: 50px !important;
  color: #fff !important;
  background: #003461;
  position: fixed !important;
  top: 70px !important;
  right: 35px !important;

  @media (max-width: 1205px) {
    position: relative;
  }
}

.menu_profile_TextFieldHidden {
  display: none !important;
}

.menu_profile_list {
  position: fixed;
  top: 135px;
  right: 35px;
  width: 276px;
  padding: 10px 10px 10px 10px;
  background: #f1efe2;
  list-style: none;
  border-bottom: 8px solid #004178;
  box-shadow: 0px 4px 10px #0003;

  @media (max-width: 1090px) {
    display: none;
  }

  .notFoundMsg{
    font-weight: 700;
    color: #004178;

    span{
      color: var(--green);
    }
  }

  h3 {
    color: var(--darkBlue);
  }

  span {
      li {
        padding: 7px 10px 7px 20px;
        width: 100%;
        color: #2e2e2e;

        + li {
          border-top: 1px solid var(--green)
        }
      }
  }
}