.class-content {
  .classDetailContentText {
    p br {
      display: none !important;
    }

    * {
      font-family: 'Trebuchet MS; Lucida Sans Unicode; Lucida Grande; Lucida Sans; Arial; sans-serif !important';
      color: #2e2e2e;
      text-align: left;
      font-size: 14px;
      width: 100%;
      white-space: initial;
    }

    ol,
    ul {
      padding: 0 0 0 18px !important;
    }
  }
}

.course_detail_breadCrumbLink {
  color: #fff !important;
  text-transform: uppercase !important;
  text-decoration: none !important;
  font-size: 10pt !important;

  @media (max-width: 436px) {
    font-size: 11px !important;
  }
}

.course_detail_breadCrumb {
  color: #fff !important;
  margin-bottom: 20px !important;
  font-weight: bold !important;

  @media (max-width: 599px) {
    margin-bottom: 10px !important;
    margin-top: -14px !important;
  }
}

.course_detail_breadCrumbLabel {
  color: #fff !important;
  text-transform: uppercase !important;
  font-size: 10pt !important;
}

.courseDetailFeedContainer {
  padding-right: 10px;
  margin-bottom: 60px;

  @media (max-width: 599px) {
    padding-right: 0px;
    margin-bottom: 60px;
  }
}

.courseDetailPageItem {
  min-height: 200px;
  background-color: #fff;
  margin-bottom: 10px;
}

.courseHeaderImage {
  height: 220px;
  background-position: center center;
  background-size: cover;

  @media (max-width: 599px) {
    height: 160px;
  }
}

.courseHeaderInfo {
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-bottom: #f4f4f4 2px solid;

  @media (max-width: 599px) {
    border-bottom: none;
  }
}

.courseHeaderInfoLabel {
  font-weight: bold;
  font-size: 10pt;
  color: #5a5a5a;
}

.courseHeaderInfoTitle {
  flex-grow: 1;
  font-weight: bold;
  font-size: 15pt;
  color: #333;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.courseDetailExtraInfo {
  padding-bottom: 20px;

  @media (max-width: 599px) {
    margin-top: 10px;
    display: flex;
    width: fit-content;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.courseDetailExtraInfoBox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-right: 20px;
  color: #9f9f9f;
  font-weight: bold;
  font-size: 9pt;

  @media (max-width: 599px) {
    width: 90px;
    margin-bottom: 20px;
    margin-right: 0;
    justify-content: flex-start;
  }
}

.courseExtraInfoBoxProgressBar {
  background-color: #e6e6e6;
  width: 100px;
  height: 8px;
  margin-top: 7px;
  margin-right: 20px;
  border-radius: 4px;
}

.courseExtraInfoBoxProgressBarFill {
  background-color: #1bb37e;
  width: 30%;
  height: 100%;
  border-radius: 4px;
}

.courseDescription {
  padding: 20px;
}

.courseDescriptionLabel {
  font-weight: bold;
  font-size: 15pt;
  color: #333;
  margin-bottom: 15px;

  @media (max-width: 599px) {
    font-size: 18px;
  }
}

.courseDescriptionText {
  font-size: 12pt;
  color: #666;

  @media (max-width: 599px) {
    font-size: 13px;
  }
}

.courseCard {
  background-color: #f5f5f5;
  padding: 30px;

  @media (max-width: 599px) {
    padding: 23px;
  }
}

.courseCardImage {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ffe4be;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.courseCardImageIcon {
  width: 70%;
  height: 70%;
}

.courseCardLabel {
  color: #333;
  font-weight: bold;
  font-size: 10pt;
  text-align: center;
  margin-bottom: 2px;

  @media (max-width: 599px) {
    display: flex;
    justify-content: center;
  }
}

.courseCardText {
  font-weight: 400;
  color: #666;
  font-size: 9pt;
  text-align: center;
}

.courseStructure {
  padding: 20px;
}

.courseStructureLabel {
  font-weight: bold;
  font-size: 15pt;
  color: #333;
  margin-bottom: 15px;
}

.courseClassList {
  display: flex;
  flex-direction: column;
}

.classItem {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.classItemDotContainer {
  width: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 599px) {
    width: auto;
    margin-right: 10px;
  }
}

.classItemDot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #a6a6a6;
}

.classItemDotGreen {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #1bb37d;
}

.classItemBox {
  flex-grow: 1;
  background-color: #fff;
  box-shadow: 2px 3px 10px #0002;
  padding: 15px;
  padding-left: 25px;
  border-right: #f90 5px solid;
  margin-bottom: 15px;

  display: flex;
  flex-direction: row;

  @media (max-width: 599px) {
    // maxWidth: 250px;
  }
}

.classItemBoxInfo {
  flex-grow: 1;
}

.classItemBoxButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;

  @media (max-width: 599px) {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: flex-start;
    width: 100%;
  }
}

.classItemInfoLabel {
  font-weight: bold;
  font-size: 9pt;
  color: #999;

  @media (max-width: 599px) {
    font-size: 10px;
  }
}

.classItemInfoTitle {
  padding-top: 7px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 12pt;
  color: #333;

  @media (max-width: 599px) {
    font-size: 15px;
  }
}

.courseIcon {
  margin-right: 4px;
}

.imgCourseSmUp {
  width: 80%;
  height: 175px;
  object-fit: cover;
}

.courseDetailContentButton {
  width: 150px;
  margin-bottom: 20px;

  @media (max-width: 599px) {
    width: auto;
  }
}

.courseContainerImg {
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 180px;
}
