.menuTop_menuItem {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  margin-right: 20px;
  margin-left: 20px;
  cursor: pointer;
  border-bottom: #45af9800 4px solid;

  &:first-child {
    margin-left: 0;
  }

  /* TABLET */
  @media only screen and (
    min-width: 600px
  ) and (max-width: 960px) {
    font-size: 12px;
    margin-right: 12px;
    margin-left: 0;
  }

  /* IPAD PRO */
  @media only screen and (
    min-width: 960px
  ) and (max-width: 1280px) {
    font-size: 16px;
    margin-right: 12px;
    margin-left: 0;
  }

  &:hover {
    border-bottom: #45af98 4px solid;

    &.fixed {
      height: 40px;
      margin-bottom: -25px;
      flex-wrap: nowrap;
    }
  }

  &.none {
    display: none;
    height: 40px;
    margin-bottom: -25px;
    flex-wrap: nowrap;
  }

  &.fixed {
    height: 40px;
    margin-bottom: -25px;
    flex-wrap: nowrap;

    @media (max-width: 1230px) {
      font-size: 14px;
    }

    @media (max-width: 1173px) {
      font-size: 12px;
    }
  }

  @media (max-width: 599px) {
    font-size: 13px;
  }
}

.menuTop_activeMenuItem {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  margin-right: 20px;
  margin-left: 20px;
  cursor: default;
  border-bottom: #45af98 4px solid;

  &.fixed {
    height: 40px;
    margin-bottom: -25px;
    flex-wrap: nowrap;
  }
  @media (max-width: 1280px) {
    margin-right: 12px;
    margin-left: 0px;
  }
  @media (max-width: 959px) {
    font-size: 12px;
  }
}

.menuTop_menuItemD {
  color: #d3d3d3;
  text-decoration: none;
  font-size: 16px;
  margin-right: 20px;
  margin-left: 20px;
  border-bottom: #45af9800 4px solid;
  cursor: default;

  &.fixed {
    height: 40px;
    margin-bottom: -25px;
    flex-wrap: nowrap;
  }
  @media (max-width: 1280px) {
    margin-right: 12px;
    margin-left: 0px;
  }
  @media (max-width: 959px) {
    font-size: 12px;
  }
}

.menu_logo {
  margin-top: -15px;
  margin-bottom: -10px;
  width: 70px;
  height: 70px;
}

.navbar {
  display: flex;
  align-items: center;
  width: 100%;
  transition: top 0.6s;

  &.fixed {
    padding: 10px;
    transition: all 0.6s;
    flex-wrap: nowrap;
    overflow-x: auto !important;
    overflow-y: hidden !important;
    position: fixed;
    top: 0;
    background: #0b4178;
    left: 0;
    z-index: 999999;
    font-size: 13px;
    min-width: 100%;

    @media (max-width: 1090px) {
      position: relative;
    }
  }

  .menuTop_profile {
    margin: 0 30px auto auto;
    right: 30px;

    .menu-profile {
      flex-direction: row;
    }
  }
}
