.list_tool_fin_link {
  text-decoration: none;
  overflow: hidden;
}

.list_tool_fin_container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.list_tool_fin_itemBox {
  height: 250px;
  min-width: 48%;
  margin-left: 20px;
}

.list_tool_fin_toolImage {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  background-position: center;
  background-size: cover;
  height: 100%;

  @media (max-width: 599px) {
    min-width: 100%;
    width: 260px;
  }
}

.list_tool_fin_toolContainerText {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  box-shadow: 4px 4px 10px #0003;
}

.list_tool_fin_toolText {
  color: #fff;
  font-size: 22px;
  max-width: 500px;
  margin-top: 30px;
  height: 50px;
  font-weight: bold;
  margin-left: 30px;

  @media (max-width: 599px) {
    width: 90%;
    font-size: 18px;
  }
}

.list_tool_fin_descriptionText {
  color: #fff;
  max-width: 500px;
  font-size: 20px;
  margin-top: -12px;
  padding: 0 30px;
  height: 85px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media (max-width: 599px) {
    width: 100%;
    font-size: 16px;
    -webkit-line-clamp: 4;
    text-align: center;
  }
}
