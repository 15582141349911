@import '../../Layout/variables';

.small-profile-gauge {


    .chart-container {
        position: relative;

        &.comparator {

            .inner {
                bottom: 0% !important;
                left  : 50% !important;
                top   : auto !important;
            }

            .outer-description {
                font-size: 20px;
            }

            .inner-description {
                font-size: 30px;
            }

        }

        .semicircle-container {
            position: absolute !important;
            z-index : 0;

            &.outer {
                top : 0;
                left: 0;
            }

            &.inner {
                top        : 25%;
                left       : 50%;
                margin-left: -154px;
            }
        }

        .outer-description,
        .inner-description {
            font-weight: bolder;
            display    : block;
            text-align : center;
            z-index    : 10;
            position   : absolute;
        }

        .outer-description {
            font-size: 25px;
        }

        .inner-description {
            font-size: 35px;
        }
    }

    .chart-legend {
        margin-top     : 5px;
        margin-bottom  : 5px;
        display        : flex;
        justify-content: center;
        align-items    : center;

        .legend-icon {
            display         : inline-block;
            width           : 16px;
            height          : 16px;
            border-radius   : 50%;
            background-color: #ccc;
            margin-right    : 10px;
        }

        .legend-text {
            width         : 120px;
            font-size     : 12px;
            font-weight   : bold;
            text-transform: uppercase;
        }

        &.your-result {
            .legend-icon {
                background-color: $primary-color;
                background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $primary-color), color-stop(100%, $secondary-color));
                background-image: -webkit-linear-gradient($primary-color 0%, $secondary-color 100%);
                background-image: -moz-linear-gradient($primary-color 0%, $secondary-color 100%);
                background-image: linear-gradient($primary-color 0%, $secondary-color 100%);
                -ms-filter      : "progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#004178', endColorstr='#4EAF98')";
            }
        }

        &.br-result {
            .legend-icon {
                $br-color       : #d4d416;
                background-color: $secondary-color;
                background-image: -webkit-gradient(linear, left top, left bottom, color-stop(30%, $secondary-color), color-stop(100%, $br-color));
                background-image: -webkit-linear-gradient($secondary-color 30%, $br-color 100%);
                background-image: -moz-linear-gradient($secondary-color 30%, $br-color 100%);
                background-image: linear-gradient($secondary-color 30%, $br-color 100%);
                -ms-filter      : "progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#4EAF98', endColorstr='#d4d416')";
            }
        }
    }

}