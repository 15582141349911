.course_resp_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: -5px;
  overflow: hidden;
}

.course_resp_itemBox {
  height: 170px;
  min-width: 280px;
  max-width: 290px;
  margin-left: 10px;
  overflow-x: hidden;

  @media (max-width: 599px) {
    min-width: 265px;
    margin-right: 2%;
  }
}

.course_resp_addFavorite {
  padding: 3px;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  position: relative;
  left: 5px;
  border: none;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}

.course_resp_toolImage {
  display: flex;
  align-items: center;
  min-width: 15vw;
  background-position: center;
  background-size: cover;
  height: 100%;
}

.course_resp_toolContainerText {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.course_resp_toolTitle {
  color: #fff;
  font-size: 16px;
  width: 98%;
  height: 30px;
  padding-top: 10px;
  // margin-left: 8px;
  margin-top: -10px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.course_resp_toolText {
  max-width: 100%;
  color: #fff;
  font-size: 16px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 8px;
}

.course_resp_texts {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  height: 100px;
  margin-top: 10px;
  padding: 0px 12px 0px 12px;
}

.course_resp_courseButtonContainer {
  display: flex;
  flex-direction: row-reverse;
  padding: 0px 12px 12px 0px;
  overflow: hidden;
  margin-bottom: 1px;
}

.course_resp_containerTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 25px;
}

.course_resp_card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5DACFC;
  width: 100px;
  height: 25px;
  color: #fff;
  font-size: 12px;
}

.course_resp_root {
  overflow: hidden;
}

.course_resp_root2 {
  display: flex;
  justify-content: center;
  width: 100%;
}