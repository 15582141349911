.registerStepper {
  .imageLeft {
    position: relative;

    &.img1 {
      background-image: url(/images/cadastro_imagem.jpg);
      background-position: unset;

      @media (max-width: 1024px) {
        background-position-x: 16%;
      }
    }

    &.img2 {
      background-image: url(/images/cadastro_imagem2.jpg);
    }

    &.img4 {
      background-image: url(/images/cadastro_imagem4.jpg);
    }

    &::before {
      content: '';
      background-image: url(/images/mdb-icon.svg);
      height: 41px;
      width: 38px;
      display: block;
      position: absolute;
      top: 22px;
      left: 30px;
    }
  }

  .step3-logo-container {
    display: flex;
    justify-content: center;
  }

  .step2-left {
    background-color: #004178;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .green-header {
    background-color: #4faf98;
  }
}
