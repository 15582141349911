.mb-30 {
  margin-bottom: 30px;
}

.mission_list_menu {
  display: flex;
  @media (max-width: 599px) {
    justify-content: center;
  }

  .mission_list_btnActive {
    background: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;
    color: var(--darkBlue);
    height: 30px;
    margin: 20px 20px 0px 0px;
    flex-wrap: wrap-reverse;
    border-bottom: 4px solid #fff;

    &.active {
      border-bottom: 4px solid var(--darkBlue);
    }

    &:hover {
      border-bottom: 4px solid var(--darkBlue);
    }

    @media (max-width: 599px) {
      font-size: 11px;
      margin: 20px 0 0 0;
    }
  }
}

.mission_list_cardQuest {
  position: relative;
  width: 100%;
  margin-top: 20px;
  min-height: 200px;
  border-right: 5px solid var(--lightBlue);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);

  &.green {
    border-right: 5px solid var(--lightGreen);
  }

  @media (max-width: 599px) {
    margin-top: 0px;
    min-height: 250px;
    padding-bottom: 20px;
  }

  .mission_list_positionFavorite {
    display: flex;
    align-items: center;
    margin: 15px 0 0 0;
    position: absolute;

    button {
      border: none;
      background: none;
      width: 28px;
      height: 28px;
      cursor: pointer;
    }

    svg {
      width: 28px;
      height: 28px;
    }

    label {
      font-size: 18px;
      margin: 0 0 0 16px;
      font-weight: 700;

      @media (max-width: 740px) {
        font-size: 16px;
        margin: 0 0 0 5px;
      }

      @media (max-width: 599px) {
        margin: 0px 0 0 10px;
      }
    }

    @media (max-width: 599px) {
      margin: 10px 0 0 0px;
    }
  }

  .mission_list_header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  .mission_list_points {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    color: #004178;
    height: 30px;
    background-color: var(--lightBlue);

    @media (max-width: 740px) {
      width: 100px;
    }

    &.green {
      background-color: var(--lightGreen);
    }

    @media (max-width: 599px) {
      font-size: 12px;
      width: 90px;
      height: 22px;
    }
  }

  .mission_list_contentText {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 10px 20px 20px 45px;

    &.responsive {
      overflow: hidden;
      height: 132px;
    }

    @media (max-width: 599px) {
      padding: 0px 20px 0px 20px;
    }

    .mission_list_title {
      font-size: 16px;
      font-weight: 400;

      @media (max-width: 826px) {
        font-size: 18px;
        margin: 30px 0px 0 0px;
        max-width: calc(100% - 150px);
        height: 47px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      @media (max-width: 599px) {
        font-size: 18px;
        margin: 30px 0px 0 0;
        max-width: calc(100% - 100px);
        height: 47px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .mission_list_positionLink {
      position: absolute;
      right: 20px;
      top: 50px;

      @media (max-width: 599px) {
        bottom: 0px !important;
        top: 110px;
        font-size: 12px !important;
        position: static;
      }
    }

    .mission_list_link {
      text-decoration: none;
      color: var(--primary);

      @media (max-width: 826px) {
        width: auto !important;
        font-size: 12px !important;
      }

      &.icon {
        width: 40px !important;
        height: 40px !important;
        margin-top: -18px;

        @media (max-width: 845px) {
          margin-top: -10px;
        }

        @media (max-width: 599px) {
          margin-top: -30px;
          width: 30px;
          height: 30px;
        }
      }
    }

    .mission_list_conclued {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (max-width: 599px) {
        margin-top: 20px !important;
      }
    }

    .miss_container_accordion {
      width: 80%;

      @media (max-width: 599px) {
        margin-left: 0;
      }

      .MuiPaper-elevation1 {
        box-shadow: none;
      }

      .MuiAccordionSummary-root.Mui-focused {
        background-color: unset;
      }
    }
  }

  .mission_list_icon {
    margin-right: -50px;
    margin-bottom: 10px;

    @media (max-width: 599px) {
      width: 30px;
      height: 30px;
      margin-right: 0;
    }
  }
}

.mission_list_description {
  width: 70%;
  margin-top: -10px;
  font-size: 16px;
  font-weight: 500;

  &.responsive {
    font-size: 12px;
    overflow: hidden;
    display: -webkit-box;
    white-space: normal;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    height: 33px !important;
  }

  @media only screen and (max-width: 780px) {
    font-size: 14px;
    height: 37px;
    width: 175px;
    overflow: auto;
  }

  @media (max-width: 599px) {
    font-size: 14px;
    width: 100%;
    height: 44px;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-width: 0px;
  }
}

.mission_list_btnContinueResponsive {
  position: absolute;
  right: 10px;
  top: 86px;
  z-index: 99999;

  &.icon {
    top: 86px;
  }

  .mission_list_iconResponsive {
    width: 40px !important;
    height: 40px !important;
    color: #d3d3d3;
    margin: 0px 0px 10px 0px;
  }
}

.mission_list_btnContinue {
  position: absolute;
  right: 20px;
  top: 80px;
  z-index: 99999;
}

.mission_list_progress {
  width: 100%;
  display: block;
  border: 0 none;
  background: #e5e5e5;
  border-radius: 14px;

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    height: 0;
  }

  &::-webkit-progress-value {
    border-radius: 12px;
    background: var(--lightGreen);
  }

  &::-webkit-progress-bar {
    background: transparent;
  }

  &::-moz-progress-bar {
    border-radius: 12px;
    background: var(--lightGreen);
  }

  @media (max-width: 845px) {
    width: 120px !important;
  }

  @media (max-width: 599px) {
    width: 100% !important;
    margin: 0px 0px 0 0;
  }
}

.mission_list_contentProgress {
  width: 80%;
  height: 33px;

  @media (max-width: 599px) {
    width: 98%;
    margin-top: 10px;
    margin-left: 0px;
    padding-right: 10px;
  }
}

.mission_list_progress {
  width: 80%;
  display: block;
  /* default   : inline-block */
  border: 0 none;
  background: #e5e5e5;
  border-radius: 14px;

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    height: 10px;
  }

  &::-webkit-progress-value {
    border-radius: 12px;
    background: #00b47c;
  }

  &::-webkit-progress-bar {
    background: transparent;
  }

  &::-moz-progress-bar {
    border-radius: 12px;
    background: #00b47c;
  }
}

.mission_list_msg {
  font-size: 18px !important;
  color: #646464;
}

.MuiCollapse-container {
}

.miss_list_accordion {
  margin-top: 30px;
  margin-bottom: 20px;
  background-color: unset !important;

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .MuiAccordionSummary-root {
    flex-direction: row-reverse;
    padding: 0px;
    margin-left: -15px;
  }

  .miss_title_accordion {
    margin-left: 10px;
  }

  .miss_details_accordion {
    padding-bottom: 20px;
  }
}
