.articleContainer {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 12px;

  @media only screen and (max-width: 599px) {
    // height: 175px;
  }
}

.articleItemContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #fff;
  box-shadow: 2px 2px 6px #2e2e2e;
  margin-bottom: 15px;
  height: 100%;
  flex-wrap: nowrap;
}

.articleImage {
  display: flex;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center center;
  background-size: cover;
  height: 100%;
}

.articleInfoContainer {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  padding: 12px 12px 10px 12px;
}

.articleInfoExtraInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
}

.articleInfoExtraInfo {
  font-size: 9pt;
  color: #2e2e2e;
  height: 30px;
  margin-right: 20px;
}

.articleItemRightContainer {
  display: flex;
  flex-direction: column;
}

.articlePointsContainer {
  display: flex;
  flex-direction: row-reverse;
}

.articlePoints {
  background-color: #5dacfc;
  color: #fff;
  font-size: 8pt;
  padding: 5px;
}

.articleBorderTop {
  background-color: #024c9c;
  margin-bottom: 5px;
  width: 40%;
  height: 2px;
}

.articleInfo {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100% !important;
  flex-direction: column;
  height: 45px;

  .articleButtonContainer {
    display: flex;
    width: 100%;
    height: 30px;
    margin-bottom: 1px;
    justify-content: flex-end;
  }

  @media only screen and (max-width: 599px) {
    padding: 0 0 20px 0;
    // margin-bottom: 0px;
    // margin-top: -20px;
  }
}

.font_info {
  display: flex;
  margin-bottom: 5px;
  margin-left: 30px;
  margin-top: -25px;
  @media only screen and (max-width: 599px) {
    font-size: 12px;
  }
}

.color_info {
  color: #fff;
  margin-bottom: -6px;
}

.description_text {
  margin-left: 10px;
  margin-top: -10px;
  margin-bottom: 22px;
  color: #fff;
  font-size: 24px;
  font-weight: bold;

  @media only screen and (max-width: 599px) {
    font-size: 18px;
  }
}

.articler_img {
  width: 100%;
  background-repeat: no-repeat;
}

.articleTitle {
  color: #fff;
  font-size: 14px;
  margin-top: 22px;
  min-height: 88px;
  max-height: 88px;
  overflow: hidden;
  display: -webkit-box;
  white-space: normal;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;

  @media only screen and (max-width: 599px) {
    font-size: 12px;
  }
}

.containerLeft {
  min-width: 315px;
  width: 50%;
  margin-right: 25px;
  height: 100%;

  @media only screen and (max-width: 599px) {
    min-width: 100%;
  }
}

.containerRight {
  display: flex;
  min-width: 355px;
  width: 50%;
  margin-left: 25px;
  height: 100%;
  border-radius: 1px 0px 0px 0px;

  &:nth-child(1) {
    margin-left: 0px;
  }

  @media only screen and (max-width: 599px) {
    min-width: 100%;
  }
}

.containerCenter {
  display: flex;
  min-width: 380px;
  width: 30%;
  margin-right: 20px;

  @media only screen and (max-width: 599px) {
    min-width: 287px;
  }
}

.articleBorderRight {
  background-color: #004178;
  width: 6px;
  height: 100%;
}

.imageCenter {
  background-repeat: no-repeat;
  width: 50%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  border-right: 6px solid #004178;
}

.titleArticle {
  color: #2e2e2e;
  font-size: 14px;
  margin-top: 22px;
  @media only screen and (max-width: 599px) {
    font-size: 11px;
  }
}

.borderTitleArticle {
  background-color: #4faf98;
  width: 8%;
  height: 3px;
}

.containerText {
  width: 100%;
  height: 54%;
}

.textArticle {
  color: #2e2e2e;
  display: -webkit-box;
  margin-top: -10px;
  overflow: hidden;
  font-size: 20px;
  max-height: 85px;
  white-space: normal;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  @media only screen and (max-width: 599px) {
    font-size: 14px;
  }
}

.contentButton {
  width: 100%;
  height: 16%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.side {
  width: 49%;
  height: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
}
.artcile_message {
  font-size: 16px;

  .artcileContainerLink{
    text-decoration: none;
    margin-left: 3px;
    text-decoration: none;
    color: #0a4b7f;
    font-weight: 500;
  }
}

.artcileMessageMobile {
  min-width: 100%;

  .artcileContainerLinkMobile{
    text-decoration: none;
    margin-left: 3px;
    text-decoration: none;
    color: #0a4b7f;
    font-weight: 500;
  }
}