.articleButton {
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  flex-wrap: nowrap;
  padding: 5px 20px;
  justify-content: center;
  align-items: center;
  border: transparent 1px solid;
  color: #004178;
  border-radius: 0px;
  font-size: 10pt;
  text-decoration: none;
  transition: color 0.6s ease-out;
  min-width: 117px;
  cursor: pointer;
  background: none;
  white-space: nowrap;

  @media only screen and (max-width: 650px) {
    font-size: 10px !important;
    height: 30px;
    width: auto;
  }

  svg {
    padding: 0px;
    margin-left: 3px;
  }

  &.full-solid {
    border-color: #004178;
    background-color: #004178;
    color: #fff;
    &:hover {
      border-color: var(--primary);
      background-color: var(--primary);
      color: #fff;
    }
  }

  &.solid {
    border-color: var(--primary);
    background-color: var(--primary);
    color: #fff;

    &:hover {
      color: var(--primary);
      background-color: #fff;
    }
  }

  &.orange {
    border-color: #fe6240;
    background-color: #fff;
    color: #fe6240;

    &:hover {
      color: #fff;
      background-color: #fe6950;
    }
  }

  &.primary {
    border-color: var(--primary);
    color: var(--primary);

    &:hover {
      color: var(--primaryLight);
      border-color: var(--primaryLight);
    }
  }

  &.green {
    border-color: var(--green);
    color: var(--green);

    &:hover {
      color: #46deb2;
      border-color: #46deb2;
    }
  }

  &.danger {
    border-color: var(--danger);
    color: var(--danger);
    background-color: none;

    &:hover {
      color: #e42e2e;
      border-color: #e42e2e;
    }
  }

  &.disabled {
    color: var(--grayLight);
    background: #fff;
    border: var(--grayLight) 1px solid;
    cursor: default;
    &:hover {
      color: var(--grayLight);
      background: #fff;
      border: var(--grayLight) 1px solid;
      cursor: default;
    }
  }

  &.secondary {
    border-color: var(--secondary);
    color: var(--secondary);

    &:hover {
      color: var(--secondaryLight);
      border-color: var(--secondaryLight);
    }
  }

  &.invert {
    border-color: var(--white);
    color: var(--white);

    &:hover {
      color: var(--whiteLight);
      border-color: var(--whiteLight);
    }
  }

  &.darkBlue {
    border-color: var(--darkBlue);
    color: #fff;
    background-color: var(--darkBlue);

    &:hover {
      border-color: var(--primary);
      background-color: var(--primary);
    }
  }

  &.ligthDark {
    border-color: #4b4b4b;
    color: #4b4b4b;
    background-color: var(--white);

    &:hover {
      border-color: #4b4b4b;
      background-color: var(--white);
    }
  }

  &.transparent {
    border-color: none;
    color: var(--primary);
    background-color: none;

    &:hover {
      border-color: none;
      background-color: none;
    }
  }
}
