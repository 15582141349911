.achivement_list_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 200px;
  width: 150px;
  margin-bottom: 10px;
  padding-top: 25px;
  margin-top: 10px;
  animation: 1s fadeAnimationLoad;

  @media (max-width: 599px) {
    width: 100%;
    padding: 0px;
  }
}

.achivement_list_cardCertificate {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  min-height: 200px;
  width: 150px;
  margin-bottom: 10px;
  padding-top: 25px;
  margin-top: 10px;
  animation: 1s fadeAnimationLoad;
}

.achivement_list_cardPerfil {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 115px;
  margin-top: -25px;
  flex-direction: column;

  @media (max-width: 599px) {
    width: 100px;
    margin-top: 0px;
    height: 100px;
  }
}

.achivement_list_titleShadow {
  font-size: 18px;
  font-weight: bold;
  color: #2e2e2e;
  margin-top: 20px;
  text-align: center;
  display: -webkit-box;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  @media (max-width: 599px) {
    width: 134px;
    position: relative;
    font-size: 16px;
  }
}

.achivement_list_subTextShadow {
  bottom: 0;
  color: #7f7f7f;
  width: 105px;
  text-align: center;
}

.achivement_list_imageShadow {
  box-shadow: 0px 0px 13px 6px #0003;
  width: 50%;
}

.achivement_list_imageShadowCertificate {
  box-shadow: 0px 10px 13px 6px #0003;
  width: 85%;
}

@keyframes fadeAnimationLoad {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.achivement_list_msg {
  font-size: 22px;
  color: #646464;
}
