.registerStepper1 {

    min-height: 74vh;
    max-height: 75vh;

    @media (max-width: 700px) {
        min-height: 40vh;
    }

    .cpf-holder {
        position: relative;

        .cpf-help-button {
            position     : absolute;
            top          : 9px;
            width        : 32px !important;
            padding      : 0;
            min-width    : unset;
            height       : 32px;
            right        : 12px;
            border-radius: 50%;
            color        : #929292;

            @media (max-width: 600px) {
                right: 30px;
            }

            @media (max-width: 700px) and (min-width:601px) {
                right: 35px;
            }

            @media (max-width: 960px) and (min-width:701px) {
                right: 0px;
            }

            @media (max-width: 1280px) {}

            @media (max-width: 1920px) {}
        }
    }

    .register-done-title {
        color: #004178;
    }

    .register-done-special-message {
        color      : #4faf98;
        font-weight: 700;
    }

    .MuiBox-root-54 {
        padding-left: 0;
    }

    .ButtonCircularProgress-circularProgress-53 {
        color: #fff;

    }

}

.MuiPopover-root {
    z-index: 1500 !important;
}

.cpf-help-popover {

    .MuiPopover-paper {
        background-color: #F1EFE3;

        .popover-title {
            color        : #004178;
            font-weight  : 600;
            margin-bottom: 15px;
        }

        .popover-content {
            color    : #666;
            font-size: 14px;
        }
    }
}