.conquest_nv_modal {
  overflow: auto;
  width   : 100vw;

  .content {
    justify-content: center;
    align-items    : center;
    display        : flex;
    flex-direction : column;
    margin-left    : 80px;
    width          : 100%;
    height         : 100%;
    margin-top     : -50px;
    background     : none;
    animation      : show 0.5s cubic-bezier(0.1, 0.82, 0.25, 1);

    @keyframes show {
      0% {
        transform: scale(1.4);
        opacity  : 0;
      }

      100% {
        transform: scale(1);
        opacity  : 1;
      }
    }

    .conquest_nv_icon {
      min-width        : 650px;
      min-height       : 650px;
      z-index          : 9999;
      background-size  : contain;
      background-repeat: no-repeat;
      display          : flex;
      align-items      : center;
      flex-direction   : column;
      color            : #fff;
      position         : relative;

      .conquest_nv_header {
        display       : flex;
        align-items   : center;
        flex-direction: column;
        position      : absolute;
        top           : 85px;
        text-align    : center;
        left          : 120px;

        span {
          text-transform: uppercase;
          font-weight   : bold;
          font-size     : 38px;
          line-height   : 50px;

          &:nth-child(1) {
            color: #5ab098;
          }
        }
      }

      .conquest_nv_positionImg {
        position: absolute;
        top     : 232px;
        left    : 181px;

        img {
          border-radius: 100px;
          width        : 150px;
          height       : 150px;
        }
      }

      .conquest_nv_bottom {
        display       : flex;
        align-items   : center;
        flex-direction: column;
        position      : absolute;
        text-align    : center;
        left          : 170px;
        bottom        : 175px;
        color         : #5ab098;

        span {
          text-transform: uppercase;
          font-weight   : bold;
          font-size     : 30px;
          line-height   : 28px;

          &:nth-child(2) {
            font-size: 16px;
          }
        }
      }

      .conquest_nv_bottomDesc {
        display       : flex;
        align-items   : center;
        flex-direction: column;
        position      : absolute;
        text-align    : center;
        left          : 96px;
        bottom        : 80px;

        small {
          font-size     : 10px;
          text-transform: uppercase;
          font-weight   : bold;
          width         : 320px;
        }
      }
    }
  }

  @media (max-width: 599px) {
    content {
      .conquest_nv_icon {
        min-width : 500px;
        min-height: 500px;

        .conquest_nv_header {
          top : 65px;
          left: 95px;

          span {
            font-size  : 28px;
            line-height: 40px;
          }
        }

        .conquest_nv_positionImg {
          position: absolute;
          top     : 177px;
          left    : 138px;

          img {
            width : 120px;
            height: 120px;
          }
        }

        .conquest_nv_bottom {
          left  : 130px;
          bottom: 140px;
          color : #5ab098;

          span {
            font-size  : 22px;
            line-height: 20px;

            &:nth-child(2) {
              font-size: 12px;
            }
          }
        }

        .conquest_nv_bottomDesc {
          left  : 50px;
          bottom: 58px;

          small {
            font-size: 9px;
            width    : 300px;
          }
        }
      }
    }
  }
}