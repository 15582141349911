@import '../../../Layout/variables';
.incomes-select-items {
  .container{
    display: flex;
    justify-content: center;
    background-color: #fff;
  }
  .dialog-content{
    max-width: 480px;
    padding: 0 20px;
  }
  .content-holder {
    h6 {
      margin-bottom: 52px;
      color: #004178;
      font-size: 18px;
      font-weight: bold;
    }
    .value-and-date{
      .MuiInputBase-input{
        width: 120px;
      }
    }
    .form-group {
      position: relative;
      flex: 1;
      margin-right: 24px;
      &:focus-within{
        label{
          color: #004178;
        }
      }

      label {
        position: absolute;
        top: -7px;
        left: 10px;
        font-family: 'Caros Soft Regular';
        font-size: 12px;
        background-color: #fff;
        padding: 0 4px;
        color: rgba(0, 0, 0, 0.54);
       
      }
      .form-control{
        width: 100%;
        font-family: 'Caros Soft Regular';
        font-size: 16px;
        height: 40px;
        border-radius: 4px;
        padding: 10.5px 14px;
        border: 1px solid rgba(0, 0, 0, 0.23);
        background: inherit;
        color: #2E2E2E;
        &:hover{
          border: 1px solid rgba(0, 0, 0, 0.87);
        }
        &:focus{
          border: 1px solid #004178;
          outline: 1px solid #004178;
        }
      }
      
    }
    

    .MuiFormControl-root {
      margin-bottom: 24px;
    }
    .MuiSelect-selectMenu {
      min-height: 0px;
      padding-top: 9px;
      padding-bottom: 9px;
    }

    .MuiSelect-select:focus {
      background-color: transparent;
    }
    label#select-expenses-label.MuiInputLabel-formControl {
      top: -6px;
      left: 14px;
      position: absolute;
      transform: translate(0px, 18px) scale(1);
      font-family: 'Caros Soft Regular';
    }

    label#select-expenses-label.MuiInputLabel-shrink {
      transform: translate(0, 1.5px) scale(0.75);
      transform-origin: top left;
    }

    .MuiFormControl-root {
      font-family: 'Caros Soft Regular';
      
    }

    .MuiInputBase-root {
      text-align: left;
      font-family: 'Caros Soft Regular';
      height: 40px;
    }
    .MuiInputLabel-root {
      font-family: 'Caros Soft Regular';
    }
    .MuiFormHelperText-root {
      font-family: 'Caros Soft Regular';
    }
  }
}

#menu- {
  font-family: 'Caros Soft Regular';
}

.MuiList-root[aria-labelledby='select-expenses'] li {
  font-family: 'Caros Soft Regular';
  &:last-child {
    justify-content: center;
    color: #004178;
  }
}
