.dialogPdf_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 400px;

  img {
    margin: -15px 0 10px 0;
  }

  strong {
    margin-bottom: 10px;
    font-size: 16px;
    color: #004178;
  }

  p {
    font-size: 16px;
    margin: 0;
    line-height: 1.8;
    margin-bottom: 10px;
  }

  span {
    font-size: 16px;
    color: #45AF98;
    margin-bottom: 30px;
  }

  button {
    width: 165px !important;
    height: 40px !important;
  }
}