@import 'variables';

.imagePerfil {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;

  span {
    width: 42px;
    height: 42px;
    background-color: #bdbdbd !important;
  }

  // border: 23px solid #FFF;

  img {
    width: 42px !important;
    height: 42px !important;
  }
}

.searchLink {
  text-decoration: none;
  color: inherit;
}

.iconPositionHidden {
  color: #fff;
  margin: 5px 8px 0px 0px;
  width: 30px !important;
  height: 30px !important;
  cursor: pointer;

  @media only screen and (max-width: 599px) {
    display: none;
  }
}

.mainHeader {
  background-color: #004178;
  height: 375px;
  width: 100vw;
  box-sizing: border-box;
  padding: 0px 5vw 0px 5vw;

  @media only screen and (max-width: 599px) {
    height: 210px;
  }
}

.topBar {
  width: 100%;
  flex-wrap: wrap;
  padding-top: 12px;
  height: 80px;

  @media only screen and (max-width: 599px) {
    padding-top: 5px;
  }
}

.topMenu {
  margin-top: 30px;

  @media only screen and (max-width: 599px) {
    display: none !important;
  }
}

.mainContainer {
  min-height: 300px !important;
  width: 90vw !important;
  margin-top: -185px !important;
  box-sizing: border-box !important;

  @media only screen and (max-width: 599px) {
    margin-top: -110px !important;
  }
}



.headerTools {
  flex-grow: 1;


  .logo-container {
    display: flex;
    width: auto;
    height: 80px;
    align-items:center ;
    
    .logo {
      height: 80px;
      max-width: 100%;

    }

    .logoXs {
      height: 60px;
      max-width: 100%;
      display: none;
    }

    @media only screen and (max-width: 400px) {
      .logo{
        display: none;
      }
      .logoXs{
        display: flex;
      }
    }



  }
  
}

.MuiAvatar-colorDefault {
  background: transparent !important;
}

.avatar {
  color: #1d518a;
  cursor: pointer;

  &.initialAvatar {
    background-color: #bdbdbd !important;
  }

  @media only screen and (max-width: 599px) {
    width: 35px;
    height: 35px;
  }
}

.greeting {
  font-size: 22px;
  color: #fff;
  font-weight: normal;
  margin-top: -10px;

  @media only screen and (max-width: 599px) {
    font-size: 18px;
  }
}

.iconsFlex {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;

  @media only screen and (max-width: 599px) {
    margin-bottom: -200px;
  }
}

.icons {
  color: #fff;
  margin: 0px 10px 0px 0px;
  width: 30px !important;
  height: 30px !important;
  cursor: pointer;
}

.menu-profile {
  .iconsHeader {
    width: 25px !important;
    height: 25px !important;
    margin-right: 22px;
  }
}

.iconsHeader {
  color: #fff;
  margin-right: 27px;
  width: 30px !important;
  height: 30px !important;
  cursor: pointer;

  @media only screen and (max-width: 599px) {
    margin-right: 20px;
    width: 23px !important;
    height: 23px !important;
  }
}

.iconFixed {
  color: #fff;
  margin: 0px 13px 0px 0px;
  width: 30px !important;
  height: 30px !important;
  cursor: pointer;
}

.iconPosition {
  color: #fff;
  margin: 3px 12px 0px 0px;
  width: 30px !important;
  height: 30px !important;
  cursor: pointer;
  margin-top: 3px;
}

.iconContact {
  color: #fff;
  margin: 3px 12px 0px 0px;
  width: 30px !important;
  height: 30px !important;
  cursor: pointer;
  margin-top: 3px;
}

.main_iconTour {
  color: #fff;
  margin: 5px 8px 0px 0px;
  width: 26px !important;
  height: 26px !important;
  cursor: pointer;

  @media only screen and (max-width: 599px) {
    display: none;
  }
}

.iconNotification {
  color: #2e2e2e;
  margin: 0px 10px -10px 0px;
  width: 30px;
  height: 30px;
}

.tabNavigation {
  width: 100vw !important;
  height: 48px !important;
  position: fixed !important;
  bottom: 0 !important;
  display: none !important;
  background-color: #004178 !important;

  @media only screen and (max-width: 599px) {
    display: flex !important;
    z-index: 11 !important;
    min-width: auto;
  }
}

.active-mobile-menu {
  color: #808080 !important;
}

.ItemNavigation {
  color: #fff !important;
  min-width: auto !important;
}

.btnNotification {
  background: none !important;
  border: none !important;
  cursor: pointer !important;
  height: 32px !important;
  margin-left: -9px !important;
}

.numberNotification {
  top: -40px;
  color: #2e2e2e;
  right: -15px;
  padding: 4px;
  height: 25px;
  width: 25px;
  position: relative;
  background: #fff;
  border-radius: 50%;
}

.notificationsBox {
  margin-top: 5px;
  max-width: 400px;
  max-height: 400px;
}

.notificationsLinkNew {
  text-decoration: none;
  font-weight: bold;
  color: #2e2e2e;

  &:hover {
    color: #286ab2;
  }
}

.notificationsLink {
  text-decoration: none;
  color: #888;

  &:hover {
    color: #286ab2;
  }
}

.notificationLine {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.notificationImage {
  width: 50px;
  height: 50px;
}

.notificationText {
  flex-grow: 1;
  font-size: 13px;
  padding: 5px;
}

.contentPoints {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: -10px 0px 20px 0px;

  @media only screen and (max-width: 599px) {
    margin: 10px 0px 20px 0px;
  }

  @media only screen and (max-width: 435px) {
    margin: 10px 0px 15px 0px;
  }
}

.fontPoints {
  @media only screen and (max-width: 599px) {
    font-size: 12px;
  }

  font-size: 20px;
  color: #fff;
}

.greenText {
  @media only screen and (max-width: 599px) {
    font-size: 13.5px !important;
  }

  font-size: 20px !important;
  color: #45af98 !important;
  cursor: pointer !important;
}

.disabled-menu-icon {
  filter: invert(50%) sepia(2%) saturate(2735%) hue-rotate(191deg) brightness(120%) contrast(80%);
}

.iconsMenu {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.iconsMainMenu {
  border-width: 2px;
  border-color: transparent;
  border-style: solid;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-color: #004178;
  margin-top: -15px;
  box-shadow: 0px 2px #0013247a;
}

.drawMainMenu {
  background-color: #004178 !important;
  color: white;
}

.customAlert2 {
  position: absolute;
  right: 20px;
  top: 80px;
  animation: 2s AnimationAlert;
  background-color: #cbddfe;
  color: #004178;

  @keyframes AnimationAlert {
    from {
      opacity: 0;
      top: 200px;
    }

    to {
      opacity: 1;
    }
  }
}

.rocketAlert {
  position: absolute;
  right: 20px;
  top: 80px;
  animation: 2s AnimationAlert;
  background-color: #000;
  color: #004178;

  @keyframes AnimationAlert {
    from {
      opacity: 0;
      top: 200px;
    }

    to {
      opacity: 1;
    }
  }
}

.mainTextField {
  width: 240px !important;
  height: 50px;
  color: #fff !important;
  background: #003461;
  margin: -17px 20px;
  position: relative;

  @media screen and (max-width: 908px) {
    width: 160px !important;
  }

  @media screen and (max-width: 820px) {
    width: 130px !important;
  }

  @media screen and (max-width: 786px) {
    width: 180px !important;
    position: absolute !important;
    top: 33px !important;
    left: -30px !important;
    height: 35px !important;
  }

  @media only screen and (max-width: 599px) {
    top: -5px;
    left: -70px !important;
    width: 180px;
    height: 35px;
    position: relative;
  }
}

.main_closedBtn {
  border: none;
  background: none;
  position: absolute;
  right: 5px;
  cursor: pointer;

  svg {
    width: 14px;
    fill: #6f808d;
  }
}

.iconInput {
  position: absolute;
  right: 10px;

  @media screen and (max-width: 300px) {
    display: none;
  }
}

div.searchContent {
  position: absolute;
  top: 50px;
  left: 20px;
  width: 276px;
  padding: 10px 10px 10px 10px;
  background: #f1efe2;
  list-style: none;
  border-bottom: 8px solid #004178;
  box-shadow: 0px 4px 10px #0003;
  z-index: 20;

  @media screen and (max-width: 786px) {
    top: 76px;
    width: 100%;
    left: 0;
    margin-left: -10px;
  }

  @media screen and (max-width: 599px) {
    top: 76px;
    width: 105%;
    margin-left: -49px;
  }

  .notFoundMsg {
    font-weight: 700;
    color: #004178;

    span {
      color: var(--green);
    }
  }

  h3 {
    color: var(--darkBlue);
  }

  span {
    li {
      padding: 7px 10px 7px 20px;
      width: 100%;
      color: #2e2e2e;

      + li {
        border-top: 1px solid var(--green);
      }
    }
  }
}

span[role='button'] {
  color: $secondary-color;
  cursor: pointer;

  &.primary {
    color: $primary-color;
    font-weight: bold;
  }

  &:hover {
    text-decoration: underline;
  }
}

.filterActive {
  color: green;
}

.mt20 {
  margin-top: 20px !important;
}

.pb20 {
  padding-bottom: 20px;
}

.p20 {
  padding: 20px;
}

.p5 {
  padding: 5px;
}

.pl5 {
  padding-left: 5px;
}

.pr5 {
  padding-right: 5px;
}

.pt20 {
  padding-top: 20px;
}

.pl10 {
  padding-left: 10px;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px;
}

.mb0 {
  margin-bottom: 0 !important;
}

.MuiAutocomplete-listbox {
  border: 2px solid $primary-color;
}

.MuiDialog-paper.MuiPickersModal-dialogRoot {
  max-width: 325px;
}

.img-fluid {
  width: 100%;
}

.rendered-content {
  * {
    font-family: 'Caros Soft Regular', 'trebuc', 'Trebuchet MS', 'Lucida Sans Unicode',
      'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;
  }

  img {
    // max-width: unset !important;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
  }
}

.default-subtitle {
  color: #45af98;
  font-size: 20px;
  font-family: Caros Soft Bold;
}
