.containerEditUser{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 70px;

  .ContentContainerProfile{
    display: flex;
    flex-direction: column;
    width: 440px;
    height: 623px;
    background-color: #fff;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    padding: 30px 30px 0 30px;

    .profileHeader{
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #004178;
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 20px;

      .switchProfile{
        font-weight: 500;
        color: #45AF98;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .profileBox1{
      display: flex;
      flex-direction: column;
      font-weight: bold;
      color: #4B4B4B;
      font-size: 12px;
      margin-bottom: 40px;

      .profileInput{
        font-weight: 400;
        margin-top: 5px;
        font-size: 14px;
        width: 332px;
        height: 40px;
        border: 1px solid rgba(15, 39, 118, 0.1);
        padding: 5px;
      }

      .pContaierCheckBox{
        max-height: 290px;
        min-height: 290px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        margin-top: 15px;

        div{
          display: flex;
          flex-direction: row;
          align-items: center;
          font-weight: normal;
          font-size: 14px;
          margin-bottom: 8px;
        }

        input{
          border-radius: 0px;
          margin-right: 8px;
          border: 1px solid rgba(15, 39, 118, 0.1);
        }
      }

    }
    .editContainerBtnsP{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 380px;
      padding: 30px;
      margin-top: -33px;

      a {
        div {
          display: flex !important;
          justify-content: center !important;
        }
      }

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

.msgProfile{
  color: rgb(255, 0, 0);
  text-align: center;
  font-size: 14px;
}

