.Gaugr__container {
  text-align: center;
  width: 100%;
  animation: 1s fadeAnimationLoad;
}

@keyframes fadeAnimationLoad {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Gauge {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__svg {
    width: 9em;
    height: 6em;
    overflow: visible;
    margin-top: 10px;
  }

  &__midpoint {
    stroke: #fff;
    stroke-width: 0.027;
  }

  &__background {
    fill: #dbdbe7;
  }

  &__filled {
    fill: rgb(160, 152, 223);
  }

  &__marker {
    stroke: #2c3e50;
    stroke-width: 0.01;
    transition: all 0.1s ease-out;
  }

  &__arrow {
    fill: #6a6a85;
    transition: all 0.1s ease-out;
  }

  &__metric {
    margin-top: 0.4em;
    font-size: 3em;
    line-height: 1em;
    font-weight: 900;
    font-feature-settings: "zero", "tnum" 1;
  }

  &__label {
    max-width: 150px;
    margin: 0 auto 20px;
  }

  &__units {
    color: #8b8ba7;
    line-height: 1.3em;
    font-weight: 300;
  }

  &__share {
    border-top: 1px solid #e5e5e5;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    margin-left: 20px;
    margin-bottom: 20px;
    max-width: 250px;
    text-align: left;
  }

  &__flex {
    display: flex;
    justify-content: center;
  }
}
.seta {
  animation: gira 1s reverse;
}

@keyframes gira {
  to {
    // transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg) skew(-45deg);
    // transform: rotate(160deg) skew(80deg);
  }
}
