.toolContainerButton {
  display: flex;
  justify-content: space-evenly;
  margin: 60px 0 50px;

  @media (max-width: 599px) {
    margin: 10px 10px 20px 10px;
    .articleButton {
      width: 100% !important;
    }
  }
}

.tool_listItem {
  border: 1px solid #eee;
  padding: 15px;
  margin: 5px 0;
  width: 350px;

  @media (max-width: 599px) {
    width: 100%;
  }
}

.tool_list {
  padding-left: 30px;
  font-size: 15px;
  line-height: 25px;
  list-style-type: none;

  @media (max-width: 599px) {
    padding: 0px 10px 0 10px;
  }
}

.tool_container {
  background: #fff;
  width: 90%;
  margin-left: 5%;
  margin-bottom: 15px;
}

.tool_classContainer {
  padding-right: 10px;
  margin-bottom: 70px;

  @media (max-width: 599px) {
    padding-right: 0px;
  }
}

.tools {
  background: #fff;
  width: 68vw;
  height: 300px;

  @media (max-width: 599px) {
    width: 86vw;
    margin-left: 10px;
  }
}

.content_tool_list {
  background: #fff;
  width: 67vw;
  margin-left: 6px;
  overflow: auto;

  @media (max-width: 599px) {
    margin-left: 6px;
    width: 84vw
  }
}

.toolsQuestion {
  display: flex;
  margin-left: 30px;
  margin-top: 50px;
  font-size: 16px;

  @media (max-width: 599px) {
    font-size: 14px;
    margin-left: 15px;
    margin-top: 25px;
  }
}

.toolsAnswers {
  display: flex;
  margin-left: 30px;
  margin-top: 15px;
  font-size: 16px;

  @media (max-width: 599px) {
    font-size: 12px;
    margin-left: 10px;
    margin-top: 12px;
  }
}

.toolsTxtAnswers {
  margin-right: 8px;
}

.tool_contentButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 599px) {
    margin: 20px;

    .articleButton {
      width: 100% !important;
      height: 25px;
    }
  }
}

.tool_inputText {
  height: 25px;
  border-radius: 0px;
  font-size: 16px;
  border: 2px solid #D3D3D3;

  @media (max-width: 599px) {
    font-size: 12px;
    height: 20px;
    margin-left: 6px;
  }
}

.tool_title {
  color: #fff;
  margin-left: 20px;
  margin-top: 0px;
  text-transform: uppercase;
}

.tool_cardImage {
  width: 100%;
  height: 350px;
  background-position: center center;
  background-size: cover;
}

.tool_shadon {
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.tool_planning_breadCrumbLink {
  color: #fff !important;
  text-transform: uppercase !important;
  text-decoration: none !important;
  font-size: 10pt !important;
  padding-left: 10px;

  @media (max-width: 436px) {
    font-size: 11px !important;
  }
}

.tool_planning_breadCrumb {
  color: #fff !important;
  margin-bottom: 20px !important;
  font-weight: bold !important;

  @media (max-width: 599px) {
    margin-bottom: 10px !important;
    margin-top: -14px !important;
  }
}

.tool_subtitle {
  display: flex;
  align-items: center;
  padding-left: 40px;
  color: #fff;
  background: #024C9C;
  font-size: 22px;
  width: 90%;
  height: 75px;
  margin-left: 5%;
  margin-top: -200px;
  @media (max-width: 599px) {
    font-size: 14px;
    padding-left: 10px;
  }
}

.tool_titleTool {
  margin-left: 10px;
  @media (max-width: 599px) {
    font-size: 20px;
  }
}