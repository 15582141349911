.index-form {
  scroll-behavior: smooth;

  .questions-description {
    color: #004178;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .question-spacer {
    height: 100px;
    width: 100%;

    @media (max-width: 700px) {
      height: 20px;
    }

    &.half {
      height: 50px;

      @media (max-width: 700px) {
        height: 25px;
      }
    }

    &.end {
      border-bottom: 2px solid #e8e8e8;
    }
  }

  .questions-container {
    .question-item {
      text-align: center;
      position: relative;
      opacity: 0.4;
      transition: opacity 1s ease-in;

      &.active {
        opacity: 1;
      }

      &.blocked {
        &:before {
          content: 'Você já respondeu anteriormente.';
          display: block;
          width: 250px;
          height: 30px;
          position: absolute;
          top: -30px;
          line-height: 30px;
          background-color: #4faf98;
          color: white;
          right: 0;
        }

        .question-option-container {
          cursor: unset !important;
        }
      }

      .question-title {
        color: #2e2e2e;
        font-size: 16px;
        width: 80%;
        display: inline-block;
        margin-bottom: 60px;
      }

      .question-options {
        justify-content: center;

        @media (max-width: 599px) {
          justify-content: flex-start;
        }

        .question-option {
          @media (max-width: 599px) {
            margin-bottom: 15px;

            &:last-child {
              margin-bottom: 0px;
            }
          }

          .question-option-container {
            cursor: pointer;

            @media (max-width: 599px) {
              display: flex;
              width: 90%;
              margin: 0 auto;
              align-items: center;

              .question-option-indicator,
              .question-option-label {
                display: inline-flex;
                margin: unset;
              }

              .question-option-label {
                margin-left: 15px;
                width: 70%;
                justify-content: flex-start;
                text-align: left;
              }
            }

            .question-option-label {
              padding: 0 10px;
            }

            .question-option-indicator {
              width: 60px;
              height: 60px;
              border: 2px solid #c4c4c4;
              border-radius: 50%;
              margin: 0 auto 10px auto;

              @media (max-width: 599px) { 
                width: 60px;
                height: 60px;
                margin: 0;
              }

              @media (min-width: 599px) and (max-width: 799px) {
                width: 40px;
                height: 40px;
              }
            }

            &.active {
              .question-option-indicator {
                background-color: #4faf98;
                border: 2px solid #8ae0cc;
                position: relative;
                transition: 0.3s ease-in;

                &:before {
                  content: '';
                  background-image: url(/images/check-icon.svg);
                  display: block;
                  width: 25px;
                  height: 17px;
                  top: 50%;
                  margin-top: -8px;
                  position: absolute;
                  left: 50%;
                  margin-left: -12px;
                }
              }

              .question-option-label {
                color: #4faf98;
              }
            }

            .question-option-label {
              @media (min-width: 599px) and (max-width: 799px) {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  .hidden {
    display: none;
  }
}
