@import '../../../Layout/variables';

.welcome-body {

    .welcome-content-holder {
        background-color: white;
        padding         : 0;

        .image-holder {
            padding            : 0;
            margin             : 0;
            min-height         : 600px;
            background-size    : cover;
            background-position: center;

            .backdrop {
                background-color: rgba(0, 0, 0, 0.7);
                color           : white;
                padding         : 20px;
            }

            &.image1 {
                background-image: url(/images/cadastro_imagem4.jpg);

            }

            &.image2 {
                background-image: url(/images/welcome-1.jpg);
            }
        }

        .welcome-content {
            .welcome-item {
                margin-top   : 10px;
                margin-bottom: 10px;
            }
        }
    }
}