.testQuiz_header {
  color: #004178;
  font-weight: "bold";
  font-size: 14px;
  width: 100%;
  height: 50px;
  display: flex;
  padding: 15px;
  position: relative;
  align-items: center;
  justify-content: space-between;
  background-color: #FCDBCF;

  @media (max-width: 599px) {
    height: 55px;
  }

  .testQuiz_titleTest {
    font-weight: 400;

    @media (max-width: 599px) {
      font-size: 12px;
    }
  }

  .testQuiz_titleQuestions {
    @media (max-width: 599px) {
      font-size: 12px;
    }
  }
}

.headerTitle1
{
  color: #000;
  font-weight: "bold";
  font-size: 14px;
}
.headerTitle2
{
  color: #004178;
  font-weight: "bold";
  font-size: 14px;
}

.testQuiz_quizStart {
  background-color: #FFF;
}

.testQuiz_containerQuestionsOverflow {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .testQuiz_contentDescription {
    width: 100%;
    display: flex;
    font-size: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;

    @media (max-width: 599px) {
      font-size: 16px;
    }
  }
  .testQuiz_description {
    overflow: auto;
    width: 85%;
    font-weight: bold;

    @media (max-width: 599px) {
      font-size: 14px;
    }
  }
  .testQuiz_containerQuestions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .testQuiz_contentBtn {
    width: 100%;
    display: flex;
    margin-top: 50px;
    margin-bottom: 30px;
    padding: 0px 10% 0px 10%;
    justify-content: space-between;

    @media (max-width: 599px) {
      margin-top: 10px;
    }
  }

  .testQuiz_contentDescription {
    width: 100%;
    display: flex;
    font-size: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;

    @media (max-width: 599px) {
      font-size: 16px;
    }
  }
  .testQuiz_description {
    overflow: auto;
    width: 85%;
    font-weight: bold;

    @media (max-width: 599px) {
      font-size: 14px;
    }
  }

  .testQuiz_containerQuestions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .testQuiz_contentAskCol {
    width: 60%;
    display: flex;
    flex-direction: column;
    // position: absolute;
    @media (max-width: 599px) {
      width: 90%;
    }
  }

  .testQuiz_contentAskRow {
    width: 90%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .testQuiz_respsV {
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    border: 2px solid #d3d3d3;
    border-radius: 3px;
    width: 100%;
    height: 40px;
    cursor: pointer;
    margin-top: 12px;
    font-weight: 500;
    font-size: 16px;

    &:hover {
      border: 2px solid #80d9bd;
      color: #80d9bd;
    }

    @media (max-width: 700px) {
      min-width: 350px;
    }

    @media (max-width: 599px) {
      font-size: 12px;
      width: 100%;
      flex-basis: 45px;
      min-height: 45px;
      margin-bottom: 10px;
      height: 100%;
    }
  }

  .testQuiz_respsH {
    display: flex;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    border: 2px solid #d3d3d3;
    border-radius: 3px;
    cursor: pointer;
    margin-left: 6px;
    margin-right: 6px;
    font-weight: 500;
    font-size: 16px;
    flex-grow: 1;
    flex-basis: 100;
    align-items: center;
    text-align: center;
    min-width: 80%;
    max-width: 80%;
    flex-basis: 45px;
    margin-bottom: 10px;
    height: 100%;

    &.active {
      border: 2px solid #80d9bd;
      color: #80d9bd;
    }

    @media (max-width: 700px) {
      min-width: 100%;
    }

    @media (max-width: 599px) {
      font-size: 12px;
    }
  }
}

// imagens
.testQuiz_flexImages {
  display: flex;
  width: 100%;
  justify-content: center;
  overflow: hidden;
  margin-top: 30px;
  flex-wrap: wrap;

  img {
    margin: 20px;
    width: 300px;
    height: 300px;
    opacity: 0.8;
    cursor: pointer;
    transition: all 0.2s;
    object-fit: cover;

    &:hover {
      opacity: 0.9;
    }
  }

  .testQuiz_respImage {
    &.active {
      border: 5px solid #80d9bd;
      color: #80d9bd;
      opacity: 1;
    }
  }

}

.testQuiz_buttonContainer
{
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  padding-bottom: 30px;

  @media (max-width: 434px) {
    padding-right: 0px;
    justify-content: center;
  }

  @media (max-width: 362px){
    display: block;
    width: auto;
    margin: 0 auto;

    button{
      margin-top: 5px;
    }
  }
}

@media (max-width: 959px){
  .quizDescriptionSection{
    margin-top: 10px!important;
    margin-bottom: 20px;
  }

  #quizGrid{
    align-items: flex-start!important;
  }
}

@media (max-width: 362px){
  .quizDescriptionSection{
    align-items: flex-start!important;
  }

 
}

