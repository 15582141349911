.contentCoins {
  display        : flex;
  width          : 100%;
  align-items    : center;
  justify-content: space-between;
  padding        : 20px;
}

.infoBalance {
  width      : 100%;
  margin-left: 30px;
}

.titleBalance {
  font-size  : 28px;
  font-weight: 500;
  color      : #2E2E2E;
  margin-top : -20px;
}

.textBalance {
  font-size  : 16px;
  font-weight: 400;
  color      : #2E2E2E;
}

.linkBalance {
  font-size      : 16px !important;
  font-weight    : 400 !important;
  color          : #2E2E2E !important;
  text-decoration: none !important;
  cursor         : pointer !important;
  margin-left    : 1px !important;
}

.coinsBalance {
  font-size  : 80px;
  font-weight: 500;
  color      : #2E2E2E;
}

.imgBalance {
  width: 92px;
}

@media screen and (max-width: 1024px) {

  .infoBalance {
    margin-left: 15px;
    margin-top : 20px;
  }

  .titleBalance {
    font-size : 22px;
    margin-top: -16px;
  }

  .textBalance {
    font-size: 12px;
  }

  .link {
    font-size: 12px !important;
  }

  .coinsBalance {
    font-size : 50px;
    margin-top: 0px;
  }

  .imgBalance {
    width : 60px;
    height: 60px;
  }
}

@media screen and (max-width: 800px) {
  .titleBalance {
    font-size : 18px;
    margin-top: -20px;
  }
}

@media screen and (max-width: 599px) {
  .contentCoins {
    height: 160px;
  }

  .titleBalance {
    font-size : 13px;
    margin-top: -20px;
  }

  .textBalance {
    font-size: 11px;
  }

  .linkBalance {
    font-size: 12px !important;
  }

  .coinsBalance {
    font-size : 32px;
    margin-top: 0px;
  }

  .imgBalance {
    width : 55px;
    height: 55px;
  }
}