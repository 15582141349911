.avatarContainer {
  display        : flex;
  align-items    : center;
  justify-content: center;
  flex-wrap      : wrap;
  padding        : 40px 36px 0px 0px;

  &.notAccess {
    justify-content: flex-start;
  }

  .avatar_alert {
    z-index          : 999999;
    position         : fixed;
    min-width        : 300px;
    font-size        : 20px;
    right            : 40px;
    top              : 100px;
    -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation        : slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  content {
    display        : flex;
    align-items    : center;
    justify-content: center;
    border-radius  : 100px;
    position       : relative;
    width          : 140px;
    height         : 140px;
    margin         : 0px 0px 36px 36px;
    border-bottom  : none !important;
    background     : rgba(0, 0, 0, 0.25);

    .imagePreview {
      position     : relative;
      width        : 140px !important;
      height       : 140px !important;
      border-radius: 100px !important;
    }

    section {
      display        : flex;
      justify-content: center;
      align-items    : center;
      flex-direction : column;

      button {
        cursor       : pointer;
        position     : absolute;
        border-radius: 10px;
        border       : none;
        width        : 110px;
        height       : 30px;
        color        : #fff;
        background   : #00000094;
        z-index      : 9999;

        &:hover {
          background: #67676794;
          transition: all 600ms;
        }

        &:nth-child(1) {
          margin  : -45px 0px 0px -135px;
          position: relative;

          input {
            // display: none;
            opacity      : 0;
            cursor       : pointer;
            border-radius: 20px;
            width        : 110px;
            height       : 30px;
            margin       : -8px 0px 0px -75px;
            z-index      : 99999;
            position     : absolute;
          }
        }

        &:nth-child(2) {
          margin: 50px 0px 0px -135px;
        }
      }
    }
  }

  .contentAvatar {
    background: #fff;
    margin    : 0px 0px 36px 36px;
    cursor    : pointer;
    transition: all 2s;

    img {
      width        : 140px;
      height       : 140px;
      border-radius: 100px;
      transition   : all 0.2s ease;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.contentAvatarDisabled {
  background: #fff;
  margin    : 0px 0px 36px 36px;
  transition: all 0.5s;

  img {
    width        : 140px;
    height       : 140px;
    border-radius: 100px;
    opacity      : 0.4;
  }
}

.avatar_disabled {
  display      : flex;
  align-items  : center;
  width        : 140px;
  height       : 140px;
  border-radius: 100px;
  background   : #000;
  opacity      : 0.5;
  z-index      : 99999;
}

.avatar_access {
  display    : flex;
  align-items: center;
}

.avatar_custom_file_upload {
  width          : 100%;
  height         : 100%;
  align-items    : center;
  justify-content: center;
  display        : flex;

  input[type="file"] {
    // display: none;
    opacity      : 0;
    cursor       : pointer;
    border-radius: 100px;
    width        : 140px;
    height       : 140px;
    position     : absolute;
    left         : 0;
  }

  label {
    text-transform: uppercase;
    font-weight   : 700;
    color         : #676767;
  }

  input {
    border-radius: 15px;
    border       : 1px solid #b7b7b7;
    padding      : 5px 5px 5px 10px;
    font-size    : 18px;
    transition   : 0.2s;
  }
}

.modal {
  display        : flex;
  align-items    : center;
  justify-content: center;
  overflow       : auto;

  .form {
    display       : flex;
    height        : 100%;
    flex-direction: column;
  }

  .close {
    position  : absolute;
    top       : -10px;
    right     : 0;
    background: none;
    border    : none;
    font-size : 36px;
    cursor    : pointer;
    z-index   : 9999;
    color     : #2e2e2e;
    margin    : 1px 6px 0px 0px;
  }

  .share {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;

    img {
      width : 80px;
      height: 80px;
    }

    span {
      &:nth-child(2) {
        margin-top: 10px;
        font-size : 14px;
      }
    }

    .positionBtn {
      display        : flex;
      justify-content: space-around;
      width          : 90% !important;
      margin         : 20px 0px 20px 0px;

      button {
        background: var(--alternativeBlue);
        color     : #fff;

        &:hover {
          background: var(--primary);
        }

        &:nth-child(2) {
          background: var(--danger);

          &:hover {
            background: #ee694b;
          }
        }
      }
    }
  }
}



@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform        : translateY(0);
  }

  100% {
    -webkit-transform: translateY(-100px);
    transform        : translateY(-20px);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform        : translateY(0);
  }

  100% {
    -webkit-transform: translateY(-100px);
    transform        : translateY(-20px);
  }
}