.mission_cust_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
}

.mission_cust_itemLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px 0px 5px;
}

.mission_cust_text {
  display: flex;
  margin-left: 15px;
  font-size: 18px;

  @media (max-width: 599px) {
    font-size: 14px;
  }
}

.mission_cust_itemRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 25px;
  width: 280px;

  @media (max-width: 599px) {
    margin-right: 8px;
  }
}

.mission_cust_buttonBlue {
  display: flex;
  width: 45%;
  height: 40px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  border: #004178 1px solid;
  background-color: #004178;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  transition: color 0.3s ease-out, border-color 0.3s ease-out;

  &:hover {
    color: #5dacfc;
    border-color: #5dacfc;
    cursor: pointer;
  }
  @media (max-width: 599px) {
    font-size: 12px;
  }
}

.mission_cust_buttonOrange {
  display: flex;
  width: 45%;
  height: 40px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  border: #f86d49 1px solid;
  background-color: #fff;
  color: #f86d49;
  font-size: 14px;
  font-weight: bold;
  transition: color 0.3s ease-out, border-color 0.3s ease-out;

  &:hover {
    color: #fe9250;
    border-color: #fe9250;
    cursor: pointer;
  }
  @media (max-width: 599px) {
    font-size: 12px;
  }
}

.mission_cust_checkBox {
  margin: 0px 0px 0px 2px;
  width: 20px;
  height: 20px;
  cursor: pointer;

  @media (max-width: 599px) {
    width: 40px;
    height: 40px;
    padding: 0px;
  }
}
