.feed_item_feedItem {
  min-height: 200px;
  background-color: #fff;
  border-radius: 0px;
  border: none;
  padding: 20px;
  padding-top: 25px;
  overflow: auto;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 25px;

  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-right: 0px;
  }
}

.feed_item_overflowHidden {
  border: none;
  width: 100% !important;
  padding: 20px;
  border-radius: 0px;
  margin-bottom: 25px;
  background-color: #fff;
}

.feed_item_titleBar {
  padding-bottom: 20px;
  color: #2e2e2e;
}

.feed_item_title {
  color: #2e2e2e;
  font-size: 20px;
  font-family: Caros Soft Bold;

  @media (max-width: 599px) {
    font-size: 16px;
  }
}

.feed_item_subtitle {
  font-size: 16px;
  margin-bottom: 5px;

  @media (max-width: 599px) {
    font-size: 14px;
  }
}

.feed_item_contentFixed {
  overflow: auto;
  max-height: 570px;
  width: 100%;
}

.feed_item_content {
  width: 100%;
}

.feed_feedContainer {
  width: 100%;

  @media (max-width: 599px) {
    margin-bottom: 60px;
  }
}

.line_feedLine {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-radius: 0px;
}
