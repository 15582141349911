.helpItem {
  min-height: 300px;
  background: #fff;
  margin-bottom: 10px;
  padding: 20px;
  padding-top: 25px;
}

.helpTitleBar {
  color: #2e2e2e;
  font-size: 28px;
  font-weight: bold;
  margin-left: 20px;
  padding-bottom: 20px;

  @media (max-width: 599px) {
    font-size: 18px;
  }
}

.helpContainer {
  width: 100%;
  padding-right: 10px;
  margin-bottom: 60px;

  @media (max-width: 599px) {
    padding-right: 10px;
  }
}

.help_breadCrumbLink {
  color: #fff !important;
  text-transform: uppercase !important;
  text-decoration: none !important;
  font-size: 10pt !important;

  @media (max-width: 436px) {
    font-size: 11px !important;
  }
}

.help_breadCrumb {
  color: #fff !important;
  margin-bottom: 20px !important;
  font-weight: bold !important;

  @media (max-width: 599px) {
    margin-bottom: 10px !important;
    margin-top: -14px !important;
  }
}

.help_breadCrumbLabel {
  color: #fff !important;
  text-transform: uppercase !important;
  font-size: 10pt !important;
}

.helpSearchInput {
  float: right;
  height: 55px;
  width: 40%;
  padding: 0px 10px 0px 10px;
  font-size: 20px;

  @media (max-width: 599px) {
    margin-top: 10px;
    margin-right: 20px;
    width: 100%;
    height: 35px;
    font-size: 18px;
  }
}

.helpContainerHidden {
  display: flex;
  flex-direction: row;
}

.helpContentHidden {
  display: flex;
  max-width: 280px;
  min-width: 280px;
  max-height: 170px;
  min-height: 170px;
  margin-bottom: 15px;
  margin-left: 20px;

  &:nth-child(1) {
    margin-left: 0px;
  }
}

.helpContentHiddenQuestions {
  display: flex;
  max-width: 280px;
  min-width: 280px;
  max-height: 170px;
  min-height: 170px;
  margin-bottom: 15px;
  margin-left: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center;

  &:nth-child(1) {
    margin-left: 0px;
  }
}
