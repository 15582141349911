.progress-floater {
  position: relative;
  text-align: center;

  .progress-label-container {
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .progress-label {
    text-align: center;
    color: #cccccc;
  }

  .progress-container {
    width: 100%;
    height: 6px;
    background: #e5e5e5;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    // #CBDDFE

    .progress {
      height: 100%;
      background: #4faf98;
      border-radius: 0 5px 5px 0;
      transition: width 0.3s ease-in;
    }
  }
}

.registerStepper3 {
  min-height: 65vh;
  max-height: 65vh;
  position: relative;

  @media (max-width: 700px) {
    min-height: 40vh;
    max-height: 40vh;
  }

  .welcome-title {
    color: #004178;
  }

  .questions-description,
  .select-interests-description,
  .select-categories-description {
    color: #004178;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .select-interests-description,
  .select-categories-description {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .questions-container {
    .question-spacer {
      height: 100px;
      width: 100%;

      @media (max-width: 700px) {
        height: 20px;
      }

      &.end {
        border-bottom: 2px solid #e8e8e8;
      }
    }

    .question-item {
      text-align: center;
      //margin    : 100px auto 100px auto;
      position: relative;
      opacity: 0.4;
      transition: opacity 1s ease-in;

      &.active {
        opacity: 1;
      }

      // &:before {
      //     content         : "";
      //     display         : block;
      //     width           : 100%;
      //     height          : 100%;
      //     position        : absolute;
      //     top             : 0;
      //     left            : 0;
      //     background-color: white;
      //     z-index         : 5;
      //     opacity         : 0.7;
      // }

      // &.active:before {
      //     z-index   : -5;
      //     transition: 2s;
      //     opacity   : 0;
      // }

      // &:last-child {
      //     margin-bottom: 0px;
      // }

      .question-title {
        color: #2e2e2e;
        font-size: 16px;
        width: 80%;
        display: inline-block;
        margin-bottom: 60px;
      }

      .question-options {
        justify-content: center;

        @media (max-width: 599px) {
          justify-content: flex-start;
        }

        .question-option {
          @media (max-width: 599px) {
            margin-bottom: 15px;

            &:last-child {
              margin-bottom: 0px;
            }
          }

          .question-option-container {
            cursor: pointer;

            @media (max-width: 599px) {
              display: flex;
              width: 90%;
              margin: 0 auto;
              align-items: center;

              .question-option-indicator,
              .question-option-label {
                display: inline-flex;
                margin: unset;
              }

              .question-option-label {
                margin-left: 15px;
                width: 70%;
                justify-content: flex-start;
                text-align: left;
              }
            }

            .question-option-indicator {
              width: 60px;
              height: 60px;

              @media (min-width: 599px) and (max-width: 799px) {
                width: 40px;
                height: 40px;
              }

              border: 2px solid #c4c4c4;
              border-radius: 50%;
              margin: 0 auto 10px auto;
            }

            &.active {
              .question-option-indicator {
                background-color: #4faf98;
                border: 2px solid #8ae0cc;
                position: relative;
                transition: 0.3s ease-in;

                &:before {
                  content: '';
                  background-image: url(/images/check-icon.svg);
                  display: block;
                  width: 25px;
                  height: 17px;
                  top: 50%;
                  margin-top: -8px;
                  position: absolute;
                  left: 50%;
                  margin-left: -12px;
                }
              }

              .question-option-label {
                color: #4faf98;
              }
            }

            .question-option-label {
              @media (min-width: 599px) and (max-width: 799px) {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  .interests-holder, .categories-holder {
    opacity: 0.4;
    transition: opacity 1s ease-in;

    &.active {
      opacity: 1;
    }

    .interests-container, .categories-container {
      max-height: 475px;
      overflow: auto;      

      .interest, .categorie {
        padding: 4px;
        cursor: pointer;

        .interest-img, .categorie-img {
          position: relative;
          background-position: center !important;
          background-size: 100% !important;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            z-index: 2;
          }

          height: 150px;

          text-align: center;

          span {
            z-index: 3;
            position: absolute;
            color: white;
            width: 100%;
            height: 20px;
            top: 50%;
            left: 0;
            margin-top: -10px;
            font-size: 14px;
            font-weight: bold;
          }

          &:after {
            content: '';
            position: absolute;
            display: block;
            width: 85px;
            height: 65px;
            background: url(/images/interest-check.svg);
            left: 0;
            top: 0;
            z-index: 3;
            opacity: 0;
            transition: 0.2s ease-in;
          }
        }

        &.selected {
          .interest-img, .categorie-img {
            //border  : 5px solid #4faf98;
            position: relative;

            &:after {
              opacity: 1;
            }
          }
        }
      }

      .categorie{
        height: 240px;

        .categorie-img{
          height: 100%;
          background-size: cover !important;
          background-position: 50% 50% !important;

          span{
            text-transform: uppercase;
            font-size: 18px;
          }
        }
      }

    }
  }
}
