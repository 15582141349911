.objective_list_linkDecoration {
  text-decoration: none;
  color: #2e2e2e;

  &.disabled{
    cursor: unset;
    opacity: 0.6;
  }
}

.objective_list_containerObjective {
  display: flex;
  flex-direction: row;
  height: 100px;
  min-width: 300px;
  text-decoration: none;
  margin-right: 20px;
  border: 1px solid #e5e5e5;
  margin-bottom: 10px;
  cursor: pointer;

  &.disabled{
    cursor: unset;
    opacity: 0.6;
  }
}

.objective_list_itemsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  overflow: auto;

  @media (max-width: 599px) {
    width: 100%;
    overflow: hidden;
  }
}

.objective_list_rootContainer {
  overflow: hidden;
}
