.modal_reset_user_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 380px;
  height: 392px;

  &::after {
    content: "";
    height: 5px;
    width: 40px;
    background: #45AF98;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .modal_reset_noneIcon {
    margin-top: 40px;
  }

  .modal_reset_user_close {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 60px;
    }
  }

  section {
    text-align: center;
    margin-top: 30px;
    max-width: 70%;

    span {
      color: #f86d49;
      font-weight: bold;
      font-size: 16px;
    }

    p {
      margin-bottom: 50px;
    }
  }

  button:nth-child(4),
  a:nth-child(4) {
    margin-bottom: 30px !important;
    width: 162px !important;
    height: 40px !important;
    text-align: center !important;
  }
}
