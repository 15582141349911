.achievement-modal {
    justify-content: center;
    align-items    : center;
    display        : flex;

    .achievement-container-holder {
        width            : 593px;
        height           : 593px;
        z-index          : 9999;
        background-repeat: no-repeat;
        justify-content  : center;
        align-items      : center;
        display          : flex;
        flex-direction   : column;
        animation        : show 0.5s cubic-bezier(0.1, 0.82, 0.25, 1);
        position         : relative;

        @media (max-width: 592px) {
            width          : 356px;
            height         : 356px;
            background-size: 356px;
        }

        @keyframes show {
            0% {
                transform: scale(1.4);
                opacity  : 0;
            }

            100% {
                transform: scale(1);
                opacity  : 1;
            }
        }

        .achievmennt-badge-holder {
            width   : 281px;
            height  : 281px;
            position: absolute;

            @media (max-width: 592px) {
                width          : 168px;
                height         : 168px;
                background-size: 168px;
            }
        }

        .achievement-container {
            display       : flex;
            align-items   : center;
            flex-direction: column;
            color         : #fff;

            .achievement-header {
                font-size     : 38px;
                text-align    : center;
                text-transform: uppercase;
                position      : absolute;
                top           : 73px;
                font-weight   : bold;

                @media (max-width: 592px) {
                    font-size: 28px;
                    top      : 33px;
                }
            }

            .achievement-bottom {
                text-align    : center;
                position      : absolute;
                bottom        : 80px;
                font-weight   : bold;
                font-size     : 26px;
                text-transform: uppercase;

                @media (max-width: 592px) {
                    font-size: 16px;
                    bottom   : 66px;

                    span {

                        &:nth-child(2) {
                            font-size: 14px !important;
                        }
                    }
                }

                span {
                    display: block;

                    &:nth-child(2) {
                        font-size: 22px;
                    }
                }
            }
        }
    }
}