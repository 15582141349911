.coin-container.quiz {
    .coin {
        width           : 100px;
        background-color: #FBDBD0;
        font-size       : 11px;
        text-align      : center;
        height          : 25px;
        line-height     : 25px;
        color           : #9e4326;
        font-weight     : bold;
        position        : absolute;
    }
}