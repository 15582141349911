@page {
  size  : landscape;
  margin: 0px;
}

@media print {

  .MainLayout-header,
  .mainHeader,
  .greeting,
  .contentPoints {
    display   : none !important;
    visibility: hidden !important;
  }

  .articleButton.darkBlue {
    display   : none;
    visibility: hidden;
  }

  .cotainerBtn {
    display   : none !important;
    visibility: hidden;
  }

  .certificate {
    // overflow                  : hidden !important;
    // -webkit-print-color-adjust: exact !important;
    // /*Chrome; Safari */
    // color-adjust              : exact !important;
    // /*Firefox*/
    // position                  : fixed;
    // left                      : 0px;
    // min-width                 : 1050px !important;
    // min-height                : 790px !important;
    // max-height                : 100vh;
    // // width: 100% !important;
    // background-size           : cover !important;
    // height                    : 100% !important;
  }

  .history {
    margin-left: 82px !important;
  }

  .contentDescriptionCertificate {
    //margin-top: -65px !important;
  }

  .contentCertificate {
    height    : 760px !important;
    min-height: 777px;
    max-height: 2000px !important;
    width     : 1050px;
    min-width : 1050px !important;
  }

  .MuiBottomNavigation-root {
    display   : none;
    visibility: hidden;
  }

  .cotainerBtn {
    display   : none;
    visibility: hidden;
  }

  .containerInfoHeader {
    //margin-top: -15px !important;
  }

  .containerGrid {
    height  : 110% !important;
    overflow: hidden !important;
  }

  .mainHeader,
  .headerTools,
  .greeting,
  .contentPoints,
  .fontPoints,
  .cotainerBtn,
  .MuiBreadcrumbs-ol,
  .MuiBreadcrumbs-li {
    visibility: hidden;
  }

  .certificate_BreadCrumbLink {
    visibility: hidden !important;
    display   : none !important;
  }

  .certificate_BreadCrumb {
    visibility: hidden !important;
    display   : none !important;
  }

  .certificate_BreadCrumbLabel {
    visibility: hidden !important;
    display   : none !important;
  }
}

.certificate-content-container {

  .certificate_BreadCrumbLink {
    color          : #fff !important;
    text-transform : uppercase !important;
    text-decoration: none !important;
    font-size      : 10pt !important;

    @media (max-width: 436px) {
      font-size: 11px !important;
    }
  }

  .certificate_BreadCrumb {
    color        : #fff !important;
    margin-bottom: 20px !important;
    font-weight  : bold !important;

    @media (max-width: 599px) {
      margin-bottom: 10px !important;
      margin-top   : -14px !important;
    }
  }

  .certificate_BreadCrumbLabel {
    color         : #fff !important;
    text-transform: uppercase !important;
    font-size     : 10pt !important;
  }

  .certificate-container {
    background-color: #fff;
  }
}