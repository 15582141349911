@import '../../../Layout/variables';
.dialog-content{
    // padding: 60px;
    .loading {
        margin: 120px 0;
    }
}
.relationship-modal-container {
    .header {
    
      padding: 40px 0px 20px;

      @media (max-width:  550px) {
        padding:0 ;
          
      }
        
    }
    padding: 40px!important;

    .hidden {
        display: none;
    }

    .green {
        color: $secondary-color;
    }
    .orange {
        color: #F6B29D;
    }
    .gray {
        color: #a6a6a6;
    }
    .img-logo {
        display: flex;
        justify-content: center;
    }
    img {
        margin: 0;
        width: 128px;
        height: 128px;
        position: absolute;
        top: 80px;
        right: 40px;

        @media (max-width:  550px) {

            position: inherit;
           margin-bottom: 20px;
            
        }
    }
    .name {
        font-size: 18px;
        color: #2E2E2E;
        text-transform: uppercase;
    }
    .relationship {
        
        padding-top: 8px;
        padding-bottom: 16px;
        font-size: 12px;
        font-weight: 700;
        color: #2E2E2E;
        text-transform: uppercase;

        span{
            font-family: 'Caros Soft ExtraBold';
            font-weight: 800;
        }

        
    }
    .description{
        margin-bottom: 24px;
    }
    .relationship-modal-container-actions {
        padding: 16px 24px;
        margin-bottom: 24px;

        .action-container{
            .subtitle {
                font-family: 'Caros Soft ExtraBold';
                strong {
                    text-transform: uppercase;
                }
            }
            .description {
                margin-bottom: 16px;
            }
            label.switch{
                font-weight: 700;
                text-transform: uppercase;
            }
        }
    }

    .border-green {
        border: 1px solid $secondary-color;
        border-radius: 1px;
    }
    .border-orange {
        border: 1px solid #F6B29D;
        border-radius: 1px;
    }
    .connect-green-btn {
        padding-top: 8px;
        font-size: 12px;
        font-weight: 700;
        color: $secondary-color;
        text-transform: uppercase;
        cursor: pointer;
        background-color: transparent;
        border: none;
    }
    button {
        line-height: 1.5;
    }
    .cpf-message {
        padding-top: 10px;
        p{
            font-size: 16px;
        }
    }
    .collapse-error{
        width: 100%;
        
    }
}