.classContainer {
  margin-bottom: 60px;
  padding-right: 10px;

  @media only screen and (max-width: 599px) {
    padding-right: 0px;
  }

  .card-favorite {
    display: flex;
    min-height: 100px;
    background-color: #45af98;
    width: 100%;
    padding: 25px 20px 25px 20px;
    color: #fff;
    font-size: 28px;
    font-weight: bold;
    align-items: center;

    
    @media only screen and (max-width: 599px) {
      margin-top: 20px;
      padding: 20px;
      min-height: 50px;
      font-size: 20px;
    }
  }
  .btn-back {
    padding: 20px 40px;
    margin: 0 !important;
    background-color: var(--white);

    & button {
      height: 40px;

      @media only screen and (max-width: 649px) {
        height: 24px;
      }
    }

    &.bottom {
      margin-top: -35px !important;
    }

    
  }

  .container-recomendados {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    box-sizing: border-box;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom:0px;
  }
}
