@import '../../../Layout/variables';

.persona-detail-body {
    .content-holder {
        padding: 0;
    }

    .persona-detail-content {

        .persona-image-holder {
            height: 450px;

            img {
                max-width : 80%;
                max-height: 80%;
            }
        }

        .persona-item-holder {

            padding: 5px;

            .persona-item {
                position  : relative;
                min-height: 250px;

                .persona-label {
                    position: absolute;
                    top     : 5px;
                    right   : 10px;
                }
            }
        }

        a {
            color          : $primary-color;
            text-decoration: none;
        }

        ul {
            list-style: none;

            li::before {
                content    : "\2022";
                color      : $primary-color;
                font-weight: bold;
                display    : inline-block;
                width      : 1em;
                margin-left: -1em;
            }
        }
    }
}