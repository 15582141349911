
  .objective_item_containerObjective {
    display: flex;
    flex-direction: row;
    height: 100px;
    min-width: 300px;
    text-decoration: none;
    margin-right: 20px;
    border: 1px solid #E5E5E5;
    margin-bottom: 10px;
  }

  .objective_item_descriptionTextNumber {
    display: flex;
    background-color: #45AF98;
    color: #fff;
    padding-bottom: 50px ;
    font-size: 20px;
    font-weight: bold;
    width: 65px;
    justify-content: center !important;
    align-items: center;
    padding-top: 25px;
  }

  .objective_item_descriptionBox {
    display: flex;
    flex-direction: row;
    border: none;
    border-left: 0px;
    align-items: center
  }

  .objective_item_descriptionText {
    color: #2E2E2E;
    width: 190px;
    display: -webkit-box;
    padding: 1px 15px 0px 15px;
    overflow: hidden;
    font-size: 16px;
    max-height: 97px;
    white-space: normal;
    text-overflow: ellipsis;
    -webkit-box-pack: center;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }

  .objective_item_descriptionIcon {
    display: flex;
    align-items: center;
    justify-content: center !important;
    color: #2E2E2E;
    font-size: 18px;
  }

  .objective_item_courseButtonIcon {
    width: 45px !important;
    height: 45px !important;
    color: #45AF98;
  }

  .objective_item_content {
    display: flex;
    justify-content: center !important;
    align-items: center;
  }

  .objective_item_box {
    display: column;
    width: 120px;
    color: #2E2E2E;
    height: 140px;
    box-shadow: 4px 4px 10px #0003;
    margin-bottom: 20px;
  }

  .objective_item_linkDecoration {
    text-decoration: none;
    color: #2E2E2E;
  }

  .objective_item_header {
    width: 100%;
    background: #45AF98;
    display: flex;
    justify-content: center !important;
    align-items: center;
    height: 30px;
    font-size: 16px;
  }

  .objective_item_number {
    font-weight: bold;
    color: #FFF;
  }

  .objective_item_description {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center !important;
    padding: 20px 10px 0px 10px;
    text-align: center;
    height: 85px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
