@import '../../../logged_in/Layout/variables';
.perfil_positionBtn {
  display: flex;
  margin: 0px 0px 30px 0px;

  @media (max-width: 599px) {
    margin: 15px 0px 0px 8px;

    .articleButton {
      height: 25px;
      margin-bottom: 10px !important;
    }
  }
}

.perfil_positionBtnFlex {
  display: flex;
  width: 100%;
  margin-top: 20px;

  button:nth-child(2) {
    margin-left: 20px;
  }
}

.MuiFormControl-root .MuiTextField-root .perfilInputText .MuiFormControl-fullWidth {
  @media (min-width: 599px) {
  }
}

.avatarPerfil {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-weight: bold;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  section {
    display: none;
    transition: 10ms !important;
  }

  &:hover {
    section {
      display: block;
      position: absolute;
      background: #00000085;
      width: 140px;
      height: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        width: 120px;
        height: 30px;
        background: var(--primary);
        color: #fff;
        font-size: 14px;
        border-radius: 10px;
        &:hover {
          background: var(--darkBlue);
        }
      }

      @media (max-width: 599px) {
        width: 80px;
        height: 80px;
        border-radius: 50%;

        a {
          flex-wrap: nowrap;
          font-size: 10px;
          width: 75px;
          height: 20px;
          border-radius: 5px;
        }
      }
    }
  }

  @media (max-width: 599px) {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    font-size: 40px;
    font-weight: bold;
  }
}

.profileClassContainer {
  padding-right: 10px;
  margin-bottom: 60px;
}

.perfilContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-color: #fff;
}

.perfilHeader {
  display: flex;
  background-color: #9ae5ce;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 110px;

  @media (max-width: 599px) {
    height: 70px;
  }
}

.perfilAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e6e6e6;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  margin-top: -65px;

  @media (max-width: 599px) {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-top: -39px;
  }
}

.perfilForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 599px) {
    width: 90%;

    input[type='select'] {
      height: 50px;
    }
  }
}

.perfilQuestion {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.perfilInputText {
  display: flex;
  align-items: center;
  height: 40px;
  width: 60%;
  border-radius: 1px;
  font-size: 16px;
  border: 2px solid #d3d3d3;
  background-color: #fff;
  padding-left: 5px;
  overflow-x: auto;
  overflow-y: hidden;

  @media (max-width: 599px) {
    font-size: 12px;
    height: 30px;
  }
}

.perfilBoxText {
  width: 90px;
  font-weight: bold;
  font-size: 16px;
  margin-right: 5px;

  @media only screen and (max-width: 750px) {
    width: 54px;
    font-size: 12px;
    margin-right: 5px;
    font-weight: bold;
  }
}

.customAlert {
  position: absolute;
  right: 20px;
  top: 80px;
  animation: 2s AnimationAlert;
  background-color: #cbddfe;
  color: #004178;
}

@keyframes AnimationAlert {
  from {
    opacity: 0;
    top: 200px;
  }
  to {
    opacity: 1;
  }
}

.tab-container {
  background-color: #fff;
  padding: 30px;
  .MuiTab-root {
    min-width: 80px;
    @media (max-width: 800px) {
      padding: 6px 6px;
    }
    @media (max-width: 410px) {
      padding: 6px 2px;
    }
  }
  .Mui-disabled {
    color: #a6a6a6;
  }
  .MuiButtonBase-root {
    @media (max-width: 800px) {
      font-size: 12px;
    }
  }

  header {
    box-shadow: none;
    width: calc(100% + 60px);
    padding-left: 6px;
    padding-right: 6px;
  }
  .MuiTabs-root {
    button {
      font-family: 'Caros Soft Regular';
      opacity: 1;
    }
    .Mui-selected {
      font-family: 'Caros Soft ExtraBold';
    }
  }

  .tab-description {
    font-size: 16px;
    color: #797979;
  }
  .MuiAppBar-colorPrimary {
    background-color: #f1efe3;
    color: $primary-color;
  }

  .MuiTabs-indicator {
    bottom: 8px;
    height: 5px;
  }
}

.optins-container {
  .optin-item {
    .optin-item-label {
      text-transform: uppercase;
      line-height: 37px;
      font-weight: bold;
      color: #004178;
    }
  }
}

.two-buttons {
  display: flex;

  button {
    margin-left: 10px;
  }
}

.relationship-container {
  .default-title {
    padding: 0 0 8px 0;
  }
  .tab-description {
    padding-bottom: 28px;
  }
  .connected-container {
    margin-bottom: 28px;
  }
  .cards-container {
    margin-right: -16px;
    @media (max-width: 776px) {
      justify-content: center;
    }
  }
  .card-container {
    cursor: pointer;
    margin-right: 16px;
    margin-bottom: 16px;
    border: 1px solid #e6e6e6;
    border-radius: 1px;
    height: 180px;
    width: 152px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 776px) {
      margin-right: 12px;
      margin-left: 12px;
    }

    position: relative;
    .MuiCardContent-root {
      padding-top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        margin: 25px 0px 10px 0px;
        width: 64px;
        overflow: hidden;
        height: 64px;
      }
      .bank-name {
        overflow: hidden;
        height: 35px;
        font-size: 12px;
        color: #2e2e2e;
        text-align: center;
      }
      .status {
        padding-top: 8px;
        font-size: 12px;
        font-weight: 700;
        color: $primary-color;
        text-transform: uppercase;

        &.orange {
          color: #f6b29d;
        }
        &.green {
          color: $secondary-color;
        }
        &.gray {
          color: #a6a6a6;
        }
      }
    }

    .green-circle {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: $secondary-color;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin: 0;
      padding: 0;
    }
    .gray-circle {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: #e6e6e6;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin: 0;
      padding: 0;
    }
    .green-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: $secondary-color;
      height: 6px;
    }
    .gray-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #e6e6e6;
      height: 6px;
    }
  }
}

.error-dialog-profile.MuiDialog-root {
  h2 {
    color: #fe6240;
    text-transform: uppercase;
    font-family: 'Caros Soft Bold';
  }
  .MuiDialogActions-root {
    margin: 16px 24px;
  }
}

.profile-div-container {
  .default-title {
    color: #2e2e2e;
    font-weight: bold;
  }
  .default-subtitle {
    color: #2e2e2e;
    // font-size: 14px;
  }
  .tab-description {
    color: #2e2e2e;
    font-size: 14px;
  }
}
