.initial_modal_container {
  background: var(--white);
  max-width: 530px;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .initial_modal_header {
    height: 120px;
    width: 100%;
    background: var(--green);
    padding: 10px;

    &.primaryLight {
      background-color: var(--primaryLight);
    }

    &.danger {
      background-color: var(--danger);
    }
  }

  .initial_modal_border {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--white);
    height: 100%;
  }

  .initial_modal_emoji {
    font-size: 50px;
  }

  .initial_modal_content {
    display: flex;
    flex-direction: column;
    align-self: center;
    padding: 22px;
    text-align: center;


    span {
      font-size: 18px;
    }

    h1 {
      font-size: 38px;
    }

    p {
      margin: 0 0 10px 0;
      text-align: left;
      font-size: 14px;

      a {
        color: var(--primary);
      }
    }
  }

  .intial_modal_psButton {
    display: flex;
    justify-content: center;
    margin: 15px 50px;
  }
}
