.perfil_user_container {
  padding-top: 8px;
}

.conteudoContainer {
  display: flex;
  flex-direction: column;
}
.conteudoBox1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .conteudoBoxSelect {
    display: flex;
    flex-direction: column;
    margin: 10px 0 10px 0;
    font-weight: 800;
    color: #4b4b4b;
    font-size: 12px;

    select {
      width: 230px;
      height: 40px;
      font-size: 14px;
      border-radius: 0px;
      background-color: #fff;
      border: 1px solid rgba(15, 39, 118, 0.1);
    }

    .selectClass {
      font-weight: 400;
      margin-top: 5px;
      font-size: 14px;
      height: 40px;
      border: 1px solid rgba(15, 39, 118, 0.1);
      color: #787777;
      padding-left: 15px;
    }
  }

  .conteudoBoxText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #a3d2b1;
    height: 40px;
    width: 520px;
    font-size: 12px;
    font-weight: bold;
    color: #4b4b4b;
    margin-top: 22px;

    span {
      margin-left: 5px;
      font-weight: normal;
    }
  }
}

.conteudoboxSide {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #4b4b4b;
  font-size: 18px;
  font-weight: 1000;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 230px;
    height: 90px;
    background-color: #cbddfe;
    // font-weight: bold;
    margin-bottom: 8px;

    span.largeNumber {
      font-weight: bold;
      font-size: 20px;
    }

    span {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
.conteudoBox2 {
  
  overflow-x: auto;
  .MuiTableBody-root {
    display: table-footer-group;
  }
}
.displayNavigation {
  div {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;

    span {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.user_navigation_iconPlay {
  width: 20px;
  height: 20px;
  margin-left: -30px;
}

.user_navigation_cell {
  margin-left: -30px !important;
}

.user_navigation_loading {
  width: 100%;
  position: absolute;
  background: #00000017;
  align-items: center;
  height: 320px;
  display: flex;
  justify-content: center;
}

.user_navigation_footer_conteudo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 100%;

  .MuiInput-input {
    margin: -3px 0 0 10px;
    padding: 3px 0 0 0;
  }
}
// .conteudoboxSide{
//   font-size: 18px;
//   font-weight: 1000;
//   span {
//     font-size: 14px;
//     font-weight: 500;
//   }
// }
