.login_user_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #d3dae6;
  background: rgba(197, 197, 197, 0.44);
  // height: 100%;
  width: 100%;
  padding: 38px;

  .login_user_card {
    display: flex;
    flex-direction: column;
    background: #fff;
    height: 450px;
    width: 400px;
    padding: 40px;

    &.centerAlign {
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 500px;
      padding: 10px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 60px;
        height: 5px;
        background: #45AF98;
      }

      strong {
        font-size: 18px;
      }

      span {
        font-size: 15px;
        margin: 20px 0 30px 0;
      }

      button {
        font-weight: bold !important;
        height: 40px !important;
        width: 150px !important;
        margin: 0 !important;
      }
    }

    header {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      text-align: center;

      strong {
        color: #004178;
      }
    }

    label {
      font-size: 16px;
      margin: 20px 0 0 0px;
    }

    button {
      &:nth-child(6) {
        width: 100px;
        margin: 30px auto auto 200px;
      }
    }

    .progressPrivactiy{
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center
    }

    .msgPrivactiy{
      margin-top: 10px;
      color: #ff0000;
      text-align: center;
      // text-transform:capitalize;
    }
  }
}
