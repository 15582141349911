.courseLoaderContainer {
  position: absolute;
  top: 50%;
  left: 50%;
}

.classLoaderContainer {
  position: absolute;
  top: 50%;
  left: 50%;
}

.classDetailContainer {
  margin-bottom: 60px;
  padding-right: 10px;
  position: relative;

  * {
    font-family: "Caros Soft Regular", "trebuc", "Trebuchet MS",
      "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif !important;
  }

  img {
    // max-width: unset !important;
    margin: 0 auto;
    width: 100%;
    max-width: unset !important;
  }

  @media (max-width: 599px) {
    padding-right: 0px;
    margin-bottom: 60px !important;
  }
}

.msgCorrect {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 10px;
  color: var(--green);
  font-size: 18px;

  &.false {
    color: #f00;
  }

  @media (max-width: 599px) {
    font-size: 12px;
  }
}

.classDetail_containerAnswer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.classDetailContainerQuestion {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .classDetaiTextQuestion {
    margin-bottom: 30px;
    font-size: 20px;
  }

  .test_containerAnswer2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    min-height: 60px;
    margin-bottom: 10px;
    border: 2px solid #d3d3d3;
    cursor: pointer;
    font-size: 18px;
    color: #2e2e2e;
    padding: 0 10px 0 20px;

    @media (max-width: 960px) {
      width: 100%;
      font-size: 14px;
      padding: 0 8px 0 8px;
    }

    @media (max-width: 599px) {
      min-height: 50px !important;
    }
  }

  .test_containerAnswerSelected2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    min-height: 60px;
    margin-bottom: 10px;
    border: 2px solid var(--darkBlue);
    background-color: var(--darkBlue);
    cursor: pointer;
    font-size: 18px;
    color: #fff;
    padding-left: 20px;
    padding-right: 10px;

    @media (max-width: 960px) {
      width: 100%;
      font-size: 14px;
      padding: 0 8px 0 8px;
    }

    @media (max-width: 599px) {
      min-height: 50px;
    }
  }

  .answerCorret {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    min-height: 60px;
    margin-bottom: 10px;
    border: 2px solid var(--green);
    background-color: var(--green);
    cursor: pointer;
    font-size: 18px;
    color: #fff;
    padding-left: 20px;
    padding-right: 10px;

    &.false {
      border: 2px solid #f00;
      background-color: #f00;
    }

    @media (max-width: 960px) {
      width: 100%;
      font-size: 14px;
      padding: 0 8px 0 8px;
    }

    @media (max-width: 599px) {
      min-height: 50px;
    }
  }

  @media (max-width: 599px) {
    span {
      font-size: 11px;
    }
  }
}

.classDetailItem {
  min-height: 200px;
  background-color: #fff;
  border: none;
  margin-bottom: 10px;
  padding: 20px;
  padding-top: 25px;

  @media (max-width: 700px) {
    padding: 20px 0;
  }
}

.titleDetailBar {
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 20px;

  @media (max-width: 599px) {
    font-size: 20px;
    padding: 20px;
  }
}

.class_detail_breadCrumbLink {
  color: #fff !important;
  text-transform: uppercase !important;
  text-decoration: none !important;
  font-size: 10pt !important;

  @media (max-width: 436px) {
    font-size: 11px !important;
  }
}

.class_detail_breadCrumb {
  color: #fff !important;
  margin-bottom: 20px !important;
  font-weight: bold !important;

  @media (max-width: 599px) {
    margin-bottom: 10px !important;
    margin-top: -14px !important;
  }
}

.class_detail_breadCrumbLabel {
  color: #fff !important;
  text-transform: uppercase !important;
  font-size: 10pt !important;
}

.codigo_licao {
  font-size: 12px;
  color: #898888;
  font-weight: bold;

  @media (max-width: 599px) {
    padding: 20px;
  }
}

.classDetailContentVideo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .vimeo-player {
    @media (max-width: 1004px) {
      width: 100% !important;
      height: unset !important;
      max-height: 360px !important;
      min-height: 360px;
    }
  }
}

.classDetailContentText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;
  margin-bottom: 15;
}

.classTextMinute {
  font-size: 14px;
  color: #2e2e2e;
  float: right;
  display: flex;
  align-items: center;
}

.classIconTime {
  width: 20px !important;
  height: 20px !important;
  margin-right: 5px;
}

.classContainer_sub {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.buttonBarContainerClass {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: all 1s !important;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  @media (max-width: 960px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.classContentsButtonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 960px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: 599px) {
    margin: 20px 0 0px 0;
  }
}

.classContentBtnContinueSmUp {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  @media (max-width: 960px) {
    margin-top: 20px;
  }
}

.classContentContinuBtn {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.classbtnContinueSmUp {
  color: #fff;
  height: 35px;
  display: flex;
  font-size: 12px;
  margin-right: 15px;
  text-transform: uppercase;
  background-color: #004178;
  align-items: center;
  justify-content: center;
  border: none;
}

.classDetailVideoSmUp {
  @media (max-width: 599px) {
    height: 200px !important;
  }
}

[data-f-id="pbf"] {
  display: none !important;
}

.content-body table {
  margin-bottom: 15px;
}

.content-body table td {
  border: #999 1px solid;
  padding: 7px;
}

.content-body div.img-container-with-background {
  padding: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #eee;
}

.content-body img {
  max-width: 100%;
}

.content-body img.bordered {
  border: #666 2px solid;
  border-radius: 3px;
  box-shadow: 10px 15px 15px #00000033;
}

.content-body img.full-width {
  width: 100%;
}

.content-body img.with-background::parent {
}
