.videoDialog-modal{
  .MuiDialogTitle-root{
    height: 56px;
  }
  .videoDialog-container {
    padding: 0;
    margin:0 56px 56px 56px;
    @media screen and (max-height: 780px) {
      margin:0 96px 56px 96px;
    }

    @media screen and (max-height: 780px) {
      margin:0 96px 56px 96px;
    }
   
    .video-container {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 0;
      height: 0;
      overflow: hidden;
      height: 100%;
    }
  
    .video-container iframe,
    .video-container object,
    .video-container embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

