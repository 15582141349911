.header-flex {
  overflow: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .buttons {
    display: flex;
  }

  .articleButton {
    border-color: #fff;
    color: #fff;
    transition: all 0.2s;
    margin-left: 10px;

    &:hover {
      background-color: #fff;
      color: var(--primary);
      border-color: var(--primary);
    }
  }
}

.share-box {
  padding: 50px !important;

  .close {
    top: 0 !important;
  }

  .header {
    margin: 10px 0 20px 0 !important;
  }
  .share {
    flex-direction: row !important;
    justify-content: space-evenly !important;
  }
}

.report-container-report {
  width: 100%;
  background: #f5f5f5 !important;
  box-sizing: border-box;

  header {
    border: none;
    // padding: 0px 5vw 0px 5vw;
    padding: 0px 30px 0px 0px;
    // height: 230px;
    height: 160px;
    position: relative;
    background-color: #004178;
  }
  .logo-container {
    height: 70px;
    padding: 8px 16px;
    
    .logo {
      height: 100%;
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 40px);
  font-size: 30px;
}
