@import '../../../logged_in/Layout/variables';

.incentive-class-container {
  .MuiGrid-root{
    nav{
      @media (max-width: 600px) {
        margin-bottom: 24px;
      }
    }
    .MuiPaginationItem-page {
      margin-top: 8px;
  }
  }
  

  padding-right: 10px;
  .default-title {
    color: #2e2e2e;
    font-weight: bold;
    padding-bottom: 8px;
  }
  .default-subtitle {
    color: #2e2e2e;
    // font-size: 14px;
  }
  .tab-description {
    color: #2e2e2e;
    font-size: 14px;
  }
  .loading-container {
    margin: 60px 0px;
  }

  .header-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    background-color: #fff;
    justify-content: flex-start;

    img {
      margin: 20px;
      width: 56px;
    }
    div {
      padding: 20px 0;
      flex: 1;
      h2 {
        margin: 0;
        padding: 0;
        font-size: 18px;
        font-weight: bold;
      }
      p {
        margin: 0;
        padding: 0;
        font-size: 12px;
      }
    }
    .points {
      margin: 0;
      font-size: 38px;
      font-weight: 700;
      padding: 20px 40px 20px 20px;
      color: $primary-color;
    }
  }
  .tab-container {
    padding: 0 30px;
  }
  .ordered-container {
    .cards-container {
      padding-top: 24px;
      @media (max-width: 1035px) {
        justify-content: center;
      }
    }
    .card-container {
      margin-right: 24px;
      margin-bottom: 24px;
      border: 1px solid #e6e6e6;
      border-radius: 1px;
      height: 450px;
      width: 265px;
      min-width: 265px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      position: relative;

      @media (max-width: 1035px) {
        margin-right: 12px;
        margin-left: 12px;
      }

      .MuiCardContent-root {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: left;

        .image-container {
          height: 265px;
          width: 265px;
          overflow: hidden;
          text-align: center;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        .description {
          margin: 32px 32px 0 32px;
          overflow: hidden;
          height: 45px;
          text-align: left;
          font-size: 14px;
          color: #2e2e2e;
        }
        .point-cost {
          margin: 8px 32px 0 32px;
          overflow: hidden;
          text-align: left;
          font-size: 16px;
          font-weight: bold;
          color: $primary-color;
        }

        .redeem {
          width: 110px;
          height: 32px;
          border: 1px solid $secondary-color;
          margin: 24px 32px 0 32px;
          color: $secondary-color;
          text-align: center;
          p {
            padding-top: 5px;
            text-transform: uppercase;
            margin: 0;
          }
        }
        .status {
          padding-top: 8px;
          font-size: 12px;
          font-weight: 700;
          color: $primary-color;
          text-transform: uppercase;

          &.orange {
            color: #f6b29d;
          }
          &.green {
            color: $secondary-color;
          }
        }
      }
    }
  }
  .connected-container {
    a {
      text-decoration: none;
      color: #4eaf98;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    .cards-container {
      @media (max-width: 776px) {
        justify-content: center;
      }
    }
    
  }
}
