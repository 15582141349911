.lesson_nameLesson {
  font-size: 16px;
  font-weight: bold;
  margin-left: 5px;
  text-decoration: none;
  color: #2e2e2e;
}

.lesson_containerItems {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;

  > a {
    text-decoration: none;

    display: flex;
    align-items: center;
  }
}

.lesson_icon_item {
  width: 40px !important;
  height: 40px !important;
  color: #00b47c;
}

.lesson_icon_item_checked {
  width: 40px !important;
  height: 40px !important;
  color: #00b47c;
}

.circleOff {
  width: 40px !important;
  height: 40px !important;
  color: #979797;
}
