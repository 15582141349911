.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: auto;

  .positionBtn {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .inputField {
    margin-bottom: 20px;
  }

  .close {
    position: absolute;
    top: -10px;
    right: 0;
    background: none;
    border: none;
    font-size: 40px;
    cursor: pointer;
    z-index: 9999;
    color: #2E2E2E;
    margin: 1px 6px 0px 0px;
  }
}
.customAlert {
  position: absolute;
  right: 20px;
  top: 80px;
  animation: 2s AnimationAlert;
  background-color: #cbddfe;
  color: #004178;
}

.customAlertError {
  position: absolute;
  right: 20px;
  top: 80px;
  animation: 2s AnimationAlert;
  background-color: #b43737 !important;
  color: #fff;
  z-index: 99999;

}

@keyframes AnimationAlert {
  from {
    opacity: 0;
    top: 200px;
  }
  to {
    opacity: 1;
  }
}
