.course_list_addFavorite {
  padding: 3px;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  position: relative;
  top: -123px;
  left: 5px;
  border: none;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}

.course_list_contentCoins {
  top: -161px;
  width: 130px;
  position: relative;
  border-top: 6px solid #cbddfe;
  border-left: none;
  margin-left: 103px;
  border-right: none;
  border-bottom: none;
}

.course_list_coins {
  color: #004178;
  font-weight: 700;
  width: 94px;
  height: 30px;
  font-size: 11px;
  display: flex;
  background: #cbddfe;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.course_list_courseItemContainer {
  width: 99%;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
  margin-bottom: 15px;
  height: 124px;
  flex-wrap: nowrap;
  display: flex;
  min-width: 520px;
}

.course_list_courseImage {
  width: 185px;
  height: 100%;

  @media (max-width: 599px) {
    width: 35%;
  }
}

.course_list_courseInfoContainer {
  flex-grow: 2;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.course_list_courseInfoTitle {
  width: 100%;
  padding: 30px 20px 0px 23px;

  font-size: 18px;
  font-weight: bold;
  color: #2e2e2e;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: -webkit-box;
  overflow: hidden;
  min-height: 100px;
  white-space: normal;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  @media (max-width: 599px) {
    width: 250px;
    height: 40px;
    overflow: auto;
    font-size: 12px;
  }
}

.course_list_buttonContainer {
  width: 170px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.course_list_border-right {
  background-color: #cbddfe;
  width: 5px;
  height: 100%;
}

.course_list_btn_desc_class {
  background-color: #fff;
  font-weight: bold;
  border: none;
  cursor: pointer;
  margin-right: 20;

  &:hover {
    color: #004178;
    border-bottom: 2px solid #4faf98;
  }
}

.course_list_btn_desc_class_active {
  background-color: #fff;
  font-weight: bold;
  border: none;
  cursor: pointer;
  color: #004178;
  border-bottom: 2px solid #4faf98;
  margin-right: 20;
}

.cotent_actions_btn {
  display: flex;
  width: 100%;
}

.course_list_imageCourse {
  width: 182px;
  height: 124px;
}
