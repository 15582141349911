.commom_containerQuests {
  width: 100%;
  height: 60px;
  margin: 10px 20px 0px 20px;
  display: flex;
  align-items: center;
  padding: 0px 10px 0px 10px;
  border: 1px solid #b8b8b8;
  overflow-y: auto;
  text-decoration: none;
  color: #2e2e2e;

  &:hover {
    border: 1px solid #013670;
  }

  @media (max-width: 599px) {
    min-width: 200px;
    height: 70px;
  }
}

.commom_description {
  font-size: 18px;

  @media (max-width: 599px) {
    font-size: 14px;
  }
}
