@page {
  size   : landscape;
  margin : 0;
  padding: 0;
}

@media print {}

.certificateContainer {
  margin: 30px;

  .certificate {
    max-width: 1200px;
  }

  /*display                          : flex;
  height                             : 725px;
  justify-content                    : center;
  min-height                         : 725px;
  min-width                          : 1000px;
  max-width                          : 1677px;
  overflow-y                         : hidden;
  overflow-x                         : auto;

  .certificate {
    width                            : auto;
    height                           : auto;
    min-height                       : 725px;
    min-width                        : 1000px;
    background-size                  : contain;
    background-repeat                : no-repeat;
    text-align                       : center;
    flex-direction                   : column;
    display                          : flex;
    justify-content                  : center;
    align-items                      : center;

    @media only screen and (max-width: 999px) {
      min-width                      : 600px;
    }
  }

  @media only screen and (max-width  : 999px) {
    min-width                        : 700px;
  }*/
}