.help_action_containerCard {
  width: 100%;
}

.help_action_headerBtn {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 60px 0px 10px 0px;
  padding: 0px 20% 0px 10px;
}

.help_action_btnHeader {
  background-color: #FFF;
  font-size: 20px;
  font-weight: bold;
  border: none;
  cursor: pointer;

  &:hover {
    color: #004178;
    border-bottom: 2px solid #4FAF98;
  }

  @media (max-width: 599px) {
    font-size: 15px;
  }
}

.help_action_btnHeaderActive {
  background-color: #FFF;
  font-size: 20px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  color: #004178;
  border-bottom: 2px solid #4FAF98;

  @media (max-width: 599px) {
    font-size: 15px;
  }
}

.help_action_pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 30px 0px 10px 0px;

  @media (max-width: 599px) {
    margin-left: -5px;
  }
}

.help_action_btnPagination {
  display: flex;
  justify-content: center;
  font-weight: bold;
  align-items: center;
  cursor: pointer;
  background: none;
  border: 1px solid #024C9C;
  color: #024C9C;
  width: 50px;
  height: 50px;
  margin-left: 20px;

  &:hover {
    color: #FFF;
    background-color: #024C9C;
  }

  @media (max-width: 599px) {
    width: 30px;
    height: 30px;
    margin-left: 10px;
  }
}

.help_action_btnPaginationActive {
  display: flex;
  justify-content: center;
  font-weight: bold;
  align-items: center;
  cursor: pointer;
  color: #FFF;
  background-color: #024C9C;
  border: 1px solid #024C9C;
  width: 50px;
  height: 50px;
  margin-left: 20px;

  @media (max-width: 599px) {
    width: 30px;
    height: 30px;
    margin-left: 10px;
  }
}