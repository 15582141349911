.modal_delete_user_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 406px;
  height: 392px;

  .modal_delete_user_close {
    margin-top: 30px;
    width: 45px;
    height: 45px;
    border: 4px solid #C5C5C5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
  }

  &::after {
    content: "";
    height: 5px;
    width: 40px;
    background: #F86D49;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  section {
    text-align: center;
    margin-top: 30px;
    max-width: 70%;


    span {
      color: #F86D49;
      font-weight: bold;
    }

    p {
      margin-bottom: 50px;
    }
  }

  button:nth-child(4), a:nth-child(4){
    margin-bottom: 30px !important;
    width: 162px !important;
    height: 40px !important;
    text-align: center !important;
  }
}