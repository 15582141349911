.first_challenge_container {
  padding: 0px 15px 15px 15px;

  @media (max-width: 599px) {
    width         : 100%;
    display       : flex;
    padding       : 5px;
    max-width     : 269px;
    min-width     : 269px;
    flex-direction: column;
    background    : #fff;
    border-radius : 10px;
  }
}

.first_challenge_challengesContainer {
  display       : flex;
  flex-direction: column;
  width         : 100%;

  a:active,
  a:visited,
  a {
    text-decoration-color: #2e2e2e;
  }

  @media (max-width: 599px) {
    width         : 100%;
    display       : flex;
    flex-direction: column;
    overflow      : auto;
    height        : 135px;
    margin-top    : 0px;
  }
}

.first_challenge_icon {
  width : 40px !important;
  height: 40px !important;
  color : #d3d3d3;

  &.green {
    color: #5ab098;
  }

  @media (max-width: 599px) {
    width : 30px !important;
    height: 30px !important;
  }
}

.first_challenge_box {
  display   : flex;
  height    : 40px;
  width     : 100%;
  margin-top: 20px;

  @media (max-width: 599px) {
    // height: 40px !important;
    height        : 20px;
    display       : flex;
    margin-top    : 20px;
    flex-direction: row;
    align-items   : center;
  }
}

.first_challenge_positionText {
  color      : #2e2e2e;
  font-size  : 14px;
  background : none;
  margin-left: 5px;
  display    : flex;
  align-items: center;
  width      : 310px;

  @media (max-width: 1570px) {
    height            : 42px;
    overflow          : hidden;
    align-items       : end;
    display           : -webkit-box;
    white-space       : normal;
    text-overflow     : ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  @media (max-width: 599px) {
    width      : 220px;
    font-size  : 12px;
    margin-left: 5px;
  }
}

.first_challenge_textLink {
  color          : #0d52aa;
  text-decoration: none;
  margin-left    : 4px;
  cursor         : pointer !important;
}

.first_challenge_msg {
  font-size: 20px;
  color    : #646464;
}