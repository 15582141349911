.classContainer2 {
  display: flex;
  background-color: #fff;
  height: 725px;
  justify-content: center;
  min-height: 725px;
  min-width: 1000px;
  max-width: 1677px;
  overflow-y: hidden;
  overflow-x: auto;
}

.header-flex {
  overflow: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .buttons {
    display: flex;
  }

  .articleButton {
    border-color: #fff;
    color: #fff;
    transition: all 0.2s;
    margin-left: 10px;

    &:hover {
      background-color: #fff;
      color: var(--primary);
      border-color: var(--primary);
    }
  }
}

.share-box {
  padding: 50px !important;

  .close {
    top: 0 !important;
  }

  .header {
    margin: 10px 0 20px 0 !important;
  }

  .share {
    flex-direction: row !important;
    justify-content: space-evenly !important;
  }
}

.report-container-report {
  width: 100%;
  background: #f5f5f5 !important;
  box-sizing: border-box;

  header {
    border: none;
    // padding: 0px 5vw 0px 5vw;
    padding: 0px 30px 0px 0px;
    // height: 230px;
    height: 160px;
    position: relative;
    background-color: #004178;
  }

  .logo {
   
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 40px);
  font-size: 30px;
}

@page {
  size: landscape;
  margin: 0px;
}

@media print {
  .MainLayout-header-1 {
    display: none;
    visibility: hidden;
  }

  .articleButton.darkBlue {
    display: none;
    visibility: hidden;
  }

  .MainLayout-greeting-9 {
    display: none;
    visibility: hidden;
  }

  .MainLayout-fontPoints-29 {
    display: none;
    visibility: hidden;
  }

  .MuiGrid-root {
    margin-top: -90px !important;
  }

  .Certificate-cotainerBtn-56 {
    display: none !important;
    visibility: hidden;
  }

  .Certificate-certificate-46 {
    overflow: hidden !important;
    -webkit-print-color-adjust: exact !important;
    /*Chrome; Safari */
    color-adjust: exact !important;
    /*Firefox*/
    position: fixed;
    left: 0px;
    min-width: 1056px !important;
    min-height: 780px !important;
    max-height: 100vh;
    width: 100% !important;
    background-size: cover !important;
    height: 100% !important;
  }

  .Certificate-history-51 {
    margin-left: 82px !important;
  }

  .Certificate-contentDescriptionCertificate-47 {
    margin-top: -90px !important;
  }

  .certificate-contentCertificate-53 {
    height: 760px !important;
    min-height: 777px;
    max-height: 2000px !important;
    width: 1050px;
    min-width: 1050px !important;
  }

  .MuiBottomNavigation-root {
    display: none;
    visibility: hidden;
  }

  .Certificate-cotainerBtn-183 {
    display: none;
    visibility: hidden;
  }

  .Certificate-containerGrid-60 {
    height: 110% !important;
    overflow: hidden !important;
  }
}

.public_certificate_breadCrumbLink {
  color: #fff !important;
  text-transform: uppercase !important;
  text-decoration: none !important;
  font-size: 10pt !important;

  @media (max-width: 436px) {
    font-size: 11px !important;
  }
}

.public_certificate_breadCrumb {
  color: #fff !important;
  margin-bottom: 20px !important;
  font-weight: bold !important;

  @media (max-width: 599px) {
    margin-bottom: 10px !important;
    margin-top: -14px !important;
  }
}

.public_certificate_breadCrumbLabel {
  color: #fff !important;
  text-transform: uppercase !important;
  font-size: 10pt !important;
}
