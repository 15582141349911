@import '../../../Layout/variables';
.incomes-types-select {

  .container{
    display: flex;
    justify-content: center;
    background-color: #fff;
  }
  .dialog-content{
    max-width: 480px;
    padding: 0 20px;
  }
 
  .content-holder {
  
    .card-container{
      cursor: pointer;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 1px;
      text-align: start;
      padding: 24px 30px;
      margin-top: 24px;
      transition: 300ms ease-in;
      
      &:hover {
        border: 1px solid #4FAF98;
        box-shadow: 0 0 3px #4FAF98;
      }
      
      &.selected {
        border: 1px solid #4FAF98;
        box-shadow: 0 0 3px #4FAF98;
      }
      .text-container{
        padding-right: 8px;

        & h5{
          margin: 0 0 16px 0;
          font-size: 16px;
          font-family: 'Caros Soft Bold';
          text-transform: none;
          text-align: start;
          color:#444448;
        }
        
        & p {
          color: #707070;
          font-size: 14px;
          line-height: 20px;
        }
      }
      
      
    }
  }
}
