.favoriteContainer {
  margin-bottom: 60px;
  padding-right: 10px;

  @media (max-width: 599px) {
    padding-right: 0px;
  }
}


.favorite_breadCrumbLink {
  color: #fff !important;
  text-transform: uppercase !important;
  text-decoration: none !important;
  font-size: 10pt !important;

  @media (max-width: 436px) {
    font-size: 11px !important;
  }
}

.favorite_breadCrumb {
  color: #fff !important;
  margin-bottom: 20px !important;
  font-weight: bold !important;

  @media (max-width: 599px) {
    margin-bottom: 10px !important;
    margin-top: -14px !important;
  }
}

.favoriteCardContainer {
  min-height: 300px;
  background: none;
  width: 100%;
  margin-bottom: 10px;
  padding: 20px;
  padding-top: 25px;
  margin-top: -90px;

  @media (max-width: 599px) {
    min-height: 250px;
  }
}

.cardFavorite {
  min-height: 300px;
  background-color: #45af98;
  width: 100%;
  margin-bottom: 10px;
  padding: 20px;
  padding-top: 25px;

  @media (max-width: 599px) {
    min-height: 250px;
  }
}

.favoriteTitleBar {
  color: #fff;
  font-size: 25px;
  font-weight: bold;
  padding-bottom: 20px;

  @media (max-width: 599px) {
    font-size: 20px;
  }
}

.favoriteContainerRecomendados {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: -250px;
  overflow-x: scroll;
  overflow-y: hidden;
  box-sizing: border-box;
  padding: 30px 50px 10px 50px;

  @media (max-width: 599px) {
    margin-top: -205px;
  }
}
