.course_reco_button_icon {
  margin    : 5px;
  padding   : 3px;
  width     : 30px;
  height    : 30px;
  background: rgba(0, 0, 0, 0.2);
  cursor    : pointer;
  border    : none;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}

.course_reco_text_obj {
  font-size      : 17px;
  font-weight    : 410;
  display        : flex;
  justify-content: flex-start;
  align-items    : center;
  flex-direction : column;

  @media (max-width: 499px) {
    font-size: 12px;
  }
}

.course_reco_card_recomendados {
  background-color: #FFF !important;
  width           : 300px !important;
  height          : 300px !important;
  margin-left     : 50px !important;
  object-fit      : cover !important;

  @media (max-width: 499px) {
    margin-top: 20px !important;
    display   : flex !important;
  }
}

.course_reco_background_img {
  width            : 300px;
  height           : 50%;
  background-size  : cover;
  background-repeat: no-repeat;
  object-fit       : cover;

  @media (max-width: 499px) {
    height      : 200px;
    width       : 180px;
    border-right: 4px solid #004178;
  }
}

.course_reco_border_back {
  position     : relative;
  float        : right;
  margin-top   : -30px;
  width        : 150px;
  border-bottom: 5px solid #CBDDFE;

  @media (max-width: 499px) {
    width: 125px;
  }
}

.course_reco_box_back {
  display         : flex;
  justify-content : center;
  align-items     : center;
  font-weight     : bold;
  font-size       : 11px;
  line-height     : 15px;
  color           : #004178;
  text-transform  : uppercase;
  float           : right;
  background-color: #CBDDFE;
  width           : 100px;
  padding-top     : 10px;

  @media (max-width: 499px) {
    width      : 90px;
    font-size  : 10px;
    height     : 20px;
    margin-top : 5px;
    padding-top: 5px;
  }
}

.course_reco_container_description {
  display    : flex;
  align-items: center;
  padding    : 0px 15px 0px 15px;
  height     : 80px;
  width      : 100%;
  overflow   : hidden;

  &:nth-child(3) {
    height : 65px;
    display: flex;
  }
}

.course_reco_icons {
  width  : 20px !important;
  height : 20px !important;
  padding: 0;

  &:nth-child(3) {
    margin-left: 20px;
  }
}

.course_reco_textDetails {
  font-size  : 14px;
  margin-left: 3px;
  font-weight: 500;
  margin-top : 2px;

  @media (max-width: 499px) {
    font-size: 12px;
  }
}

.course_reco_textDetailsRelevance {
  font-size  : 14px;
  margin-left: 10px;
  font-weight: 500;
  margin-top : 2px;

  @media (max-width: 499px) {
    font-size: 12px;
  }
}

.course_reco_contentDetails {
  padding-left  : 20px;
  padding-right : 10px;
  display       : flex;
  flex-direction: row;
  align-items   : center;

  &:nth-child(3) {
    padding: 0px;
  }

  @media (max-width: 499px) {
    padding: 0px;
  }
}

.course_reco_positionBtn {
  width      : 130px;
  margin-left: 15px;
  text-align : center;
  margin-top : 20px;

  @media (max-width: 499px) {
    width      : 90px;
    margin-left: 10px;
    margin-top : 5px;
  }
}

.course_reco_contentCollumn {
  display       : flex;
  flex-direction: column;
  align-items   : center;
}