.classContainer {
  padding-right: 10px;
}
.open_containerCorrency {
  background-color: #fff !important;
  width: 100%;
  height: 160px;
  margin: 0px 0px 25px 0px;

  @media only screen and (max-width: 599px) {
    height: 160px !important;
  }
}