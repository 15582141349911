.progressCardContent {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding-right: 30px;

  @media (max-width: 599px) {
    padding: 0px;
    margin-top: 20px;
  }
}

.progressCardContentCertificate {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding-right: 30px;

  @media (max-width: 599px) {
    padding: 0px;
    display: flex;
    flex-direction: column;
  }
}

.progressClassContainer {
  width: 100%;
  padding-right: 10px;
  margin-bottom: 70px;
}

.progress_breadCrumbLink {
  color: #fff !important;
  text-transform: uppercase !important;
  text-decoration: none !important;
  font-size: 10pt !important;

  @media (max-width: 436px) {
    font-size: 11px !important;
  }
}

.progress_breadCrumb {
  color: #fff !important;
  margin-bottom: 20px !important;
  font-weight: bold !important;

  @media (max-width: 599px) {
    margin-bottom: 10px !important;
    margin-top: -14px !important;
  }
}

.progressMsg {
  color: #d3d3d3;
  margin-left: 15px;
  font-size: 14px;
}

.progressContentBenefits {
  min-height: 260px;
  background-color: #45af98;
  padding: 20px;
  border-radius: 0px;

  @media (max-width: 599px) {
    min-height: 260px;
  }
}

.progressTitleBar {
  color: #fff;
  font-size: 28px;
  margin-left: 10px;
  font-weight: 500;
  padding-bottom: 20px;

  @media (max-width: 599px) {
    font-size: 20px;
  }
}

.containerNextBenefits {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: -240px;
  overflow-x: scroll;
  overflow-y: hidden;
  box-sizing: border-box;
  padding: 30px 0px 10px 0px;

  @media (max-width: 805px) {
    margin-top: -235px !important;
  }

  @media (max-width: 730px) {
    margin-top: -221px !important;
  }

  @media (max-width: 599px) {
    margin-top: -247px !important;
  }
}

.progress_containerCorrency {
  background-color: #fff;
  width: 100%;
  height: 160px;
  margin: 0px 0px 25px 0px;

  @media only screen and (max-width: 599px) {
    height: 160px !important;
  }
}
