.expanded_modal {
  display        : flex;
  justify-content: center;
  align-items    : center;
  width          : 100vw;
  overflow       : auto;
  z-index        : 999999 !important;

  @media only screen and (max-width: 999px) {
    //display: block;
    margin-top: 10%;
  }
}