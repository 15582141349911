@import './logged_in/Layout/variables';

.backoffice_footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 10px;
  background: var(--green);
}

.backoffice-content {
  padding: 20px;
  margin-top: 10px;

  .MuiAppBar-root {
   margin-top: 16px;
  }

  .page-title {
    font-size: 18px;
    color: $primary-color;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .page-sub-title {
    font-size: 14px;
    color: #999ea3;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .mtop {
    margin-top: 20px;
  }

  .dimension-container {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .dimension-label {
    flex-basis: 150px;
  }

  .dimension-slider {
    flex: 1;
  }

  .rewards-setup-container {
    display: flex;
    flex-direction: row;

    .reward-container {
      margin-right: 20px;
    }

    .dimension-label {
      flex-basis: 150px;
    }
  }

  .field-label {
    color: #666;
    font-size: 12px;
  }

  .box {
    border: #ccc 1px solid;
    padding: 20px;
    box-shadow: 4px 4px 10px #0002;
    box-sizing: border-box;
  }

  .user_positionSearch {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;

    button {
      margin-left: 20px;
      justify-content: center !important;
    }

    input {
      width: 250px;
    }

    .user_footer_table {
      margin-top: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .MuiInput-formControl {
        margin-left: 10px;
      }

      .MuiSelect-selectMenu {
        text-overflow: inherit;
      }

      .MuiSelect-select {
        margin: -5px 0 0 0;
        width: 10px;
      }
    }
  }

  .filter-container {
    padding: 30px 0px 30px 0px;
    display: flex;
    flex-direction: column;

    .filter-elements {
      display: flex;
      flex-direction: row;
      padding: 5px;
      margin-top: 5px;

      input {
        height: 40px;
      }

      .filter-element {
        margin-right: 20px;
      }
    }
  }

  .backoffice-fields-container {
    display: flex;
    flex-direction: column;

    .MuiFormControl-root {
      margin-top: 15px;
    }

    .thumbnail-container {
      img {
        width: 300px;
        height: 180px;
        object-fit: cover;
      }
    }

    .buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 20px 0px;

      // button {
      //   border : #999 1px solid;
      //   background-color: #FFF;
      //   height: 42px;
      //   padding: 3px 20px;
      //   display: flex !important;
      //   justify-content: center;
      //   color: #333;
      //   font-size: 16px;
      //   margin-left: 0 !important;
      // }

      // button.blue {
      //   background-color: $primary-color;
      //   color: #fff;
      // }
    }
  }

  .table-list-items {
    table-layout: fixed;

    .tb-data-row {
      cursor: pointer;
      padding: 0px;
    }

    .tb-data-cell {
      display: table-cell;
      text-align: left;
      vertical-align: middle;
      padding: 10px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .tb-data-cell-thumb {
        width: 100px;
        height: 60px;
        object-fit: cover;
      }
      .listing-thumb-name {
        display: flex;
        flex-direction: row;
        align-items: center;

        div {
          margin-left: 20px;
        }
      }
    }
  }

  .breadcrumb-container {
    margin-bottom: 20px;
  }

  .class-content-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .class-content-card {
      width: 150px;
      height: 150px;
      margin-bottom: 10px;
      margin-right: 10px;
      border: #999999 1px solid;
      display: flex;
      flex-direction: column;

      .class-content-type {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: $primary-color;
        padding: 3px;
        color: #fff;
      }
      .class-content-text {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        // padding: 25px;
      }
      .class-content-buttons {
        display: flex;
        flex-direction: row;
        padding: 5px;
      }
    }

    .class-content-add {
      width: 150px;
      height: 150px;
      margin-bottom: 10px;
      margin-right: 10px;
      border: $primary-color 1px solid;
      color: $primary-color;
      display: flex;
      flex-direction: column;

      .class-content-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-wrap: wrap;
        padding: 5px;

        .button-add {
          cursor: pointer;
          display: grid;
          place-items: center;
          margin: 3px;
          background-color: $primary-color;
          color: #fff;
          width: 35px;
          height: 35px;

          &:hover {
            background-color: #52af98;
          }
        }
      }

      .class-content-text {
        flex-grow: 1;
        padding: 10px;
        display: grid;
        place-items: center;
        line-height: 1em;
        text-align: center;
      }
    }
  }

  .button-right-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      margin-left: 8px;
    }
  }

  .slides-container {
    margin-top: 25px;
    display: flex;
    flex-direction: row;

    .data-panel {
      width: 400px;

      .slides-top {
        background-color: #ddd;
        display: flex;
        flex-direction: row;
        padding: 5px;
        height: 40px;

        .current-slide {
          flex-grow: 1;
          display: grid;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          font-size: 13px;
          font-weight: bold;
          margin-right: 10px;
          flex-grow: 1;

          .button-slide-arrow {
            display: grid;
            place-items: center;
            background-color: $primary-color;
            color: #fff;
            height: 25px;
            width: 25px;
            cursor: pointer;
            border-radius: 10%;
          }

          .prev {
            margin-right: 10px;
            padding-left: 5px;
          }
          .next {
            margin-left: 10px;
          }

          .disabled {
            background-color: #ccc;
            color: #eee;
            cursor: default;
          }
        }

        .buttons {
          display: flex;
          flex-direction: row;

          .button-delete-slide {
            background-color: #900;
            width: 30px;
            height: 30px;
            color: #fff;
            display: grid;
            place-items: center;
            margin-left: 5px;
            cursor: pointer;
          }
        }
      }

      .slide-fields {
        height: 660px;
        padding-left: 10px;
        padding-right: 10px;
        overflow: auto;
        background-color: #eee;

        display: flex;
        flex-direction: column;
      }

      .background-selection {
        display: flex;
        flex-direction: column;
      }

      .slide-contents {
        .slide-content {
          margin-top: 15px;
          display: flex;
          flex-direction: column;
          padding-top: 0px;
          border: #ccc 1px solid;
          background-color: #fff;
          border-radius: 5px;

          .content-top-bar {
            height: 20px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            background-color: #eee;

            .content-top-title {
              flex-grow: 1;
              font-size: 13px;
              padding-left: 5px;
            }

            .content-mini-button {
              width: 20px;
              height: 20px;
              display: grid;
              place-items: center;
              margin-left: 2px;
              cursor: pointer;
            }
          }

          .content-content {
            display: flex;
            flex-direction: column;
            padding: 10px;

            .color-picker {
              margin-top: 10px;

              .label {
                font-size: 12px;
                color: #666;
                margin-bottom: 3px;
              }
            }
          }
        }
      }

      .picker-content {
        display: flex;
        flex-direction: row;
        margin-top: 5px;

        .picker-preview {
          margin: 15px;
          width: 70px;
          height: 70px;
          background-color: #ccc;
          box-shadow: #00000033 0px 2px 10px;
        }
      }

      .add-content-button {
        display: flex;
        flex-direction: row-reverse;
        text-align: right;
        margin-top: 20px;
      }
    }

    .preview-panel {
      // border: #ddd 1px solid;
      // width: 400px;
      // height: 800px;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      position: relative;

      .move-slide {
        position: absolute;
        background-color: $primary-color;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        top: 50%;
        margin-top: -20px;
        display: grid;
        place-items: center;
        color: #fff;
        cursor: pointer;

        &.left {
          left: -20px;
        }

        &.right {
          right: -20px;
        }
      }
    }
  }

  .questions-container {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: #ccc 1px solid;
  }

  .quiz-container,
  .miniquiz-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 30px;
    border: #ccc 1px solid;
    padding: 30px;
    box-shadow: #00000022 5px 7px 14px;
    position: relative;

    .delete-button {
      width: 30px;
      height: 30px;
      border: #900 2px solid;
      position: absolute;
      top: 10px;
      right: 10px;
      border-radius: 50%;
      display: grid;
      place-items: center;
      color: #900;
      cursor: pointer;
    }

    .quiz-question {
      textarea {
        color: $primary-color;
      }
      margin-top: -15px;
      margin-bottom: 25px;
    }

    .quiz-options-container,
    .miniquiz-options-container {
      .quiz-option {
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
          white-space: nowrap;
        }
      }

      label {
        margin-left: 20px;
      }
    }

    .miniquiz-options-container {
      label {
        margin-left: 0;
      }
    }
  }

  .quiz-edit-container {
    display: flex;
    flex-direction: column;

    .inner-title {
      margin-top: 20px;
    }

    .quiz-edit-results-container {
      display: flex;
      flex-direction: row;

      .quiz-edit-result-item {
        border: $primary-color 1px solid;
        // padding: 20px;
        width: 90px;
        height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 10px;

        .quiz-result-item-plus {
          display: grid;
          place-items: center;
          font-size: 30px;
          color: $primary-color;
          width: 100%;
          height: 50px;
        }

        .quiz-result-item-image {
          width: 100%;
          height: 50px;
          object-fit: cover;
        }

        .quiz-result-item-text {
          display: grid;
          place-items: center;
          padding: 5px;
          flex: 1;
          font-size: 11px;
          min-width: 10px;
          text-align: center;
          vertical-align: center;
          color: $primary-color;
        }
      }
    }
  }
}

.edit-quiz-question-dialog {
  width: 100%;

  .quiz-question-options-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .question {
      border: #999 1px solid;
      margin-top: 5px;
      padding: 10px;
      display: flex;
      flex-direction: column;

      .option-label {
        color: $primary-color;
      }
    }

    table {
      margin-top: 5px;
      border: #999 1px solid;
      border-collapse: collapse;

      th {
        font-size: 11px;
        text-align: left;
        background-color: #ddd;
        border: #999 1px solid;
        padding: 3px;
      }

      td {
        font-size: 11px;
        text-align: left;
        border: #999 1px solid;
        padding: 3px;

        .MuiInputBase-root {
          width: 100%;
        }
      }
    }
  }

  .miniquiz-container {
    margin-top: 50px;
  }
}

.edit-quiz-result-dialog {
  padding: 30px;
  display: flex;
  flex-direction: column;

  .edit-result-title {
    margin-bottom: 15px;
    font-size: 18px;
  }

  .MuiTextField-root {
    margin-top: 15px;
  }
  .buttons-container {
    padding-top: 35px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .image-error-message {
    color: #f00;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }
}

.before-valid-id-container {
  text-transform: uppercase;
  margin: 60px 0 40px 0;
  text-align: center;
  color: #606060;
  font-weight: bolder;
  font-size: 14px;
}

.pagination-container {
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.pagination-button {
  border: 1px solid #004178;
  background-color: #fff;
  color: #004178;
  padding: 3px 7px 3px 7px;
  margin-right: 3px;
  margin-bottom: 3px;
  width: fit-content;
  white-space: nowrap;
  cursor: pointer;
}

.pagination-button-current {
  background-color: #004178;
  color: #fff;
  padding: 3px 7px 3px 7px;
  margin-right: 3px;
  margin-bottom: 3px;
  width: fit-content;
  white-space: nowrap;
  cursor: pointer;
} 