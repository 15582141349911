
.MuiTooltip-popper {
    .MuiTooltip-tooltip {
      background-color: #f5f5f9;
      color: #2e2e2e;
      max-width: 220;
      font-size: 14px;
      border: 1px solid #dadde9;
    }
  
}

.toolsItem {
  min-height: 220px;
  background: #fff;
  margin-bottom: 70px;
  padding: 20px 20px 20px 0px;
}

.toolsTitleBar {
  color: #2e2e2e;
  font-size: 25px;
  font-weight: bold;
  padding-bottom: 20px;
  height: 40px;
  margin-left: 40px !important;
  .toolsTitle {
    display: flex;
    flex-direction: row;
    button {
      margin: 0 8px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: #004178;
    }
  }

  @media only screen and (max-width: 599px) {
    font-size: 20px;
  }
}

.classContainertools {
  padding-right: 10px;
  overflow-x: auto;

  @media (max-width: 599px) {
    padding-right: 0px;
  }

  .toolsTitleBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    margin: 10px 0 10px 0;

    h1 {
      margin: 0;
      font-size: 24px;
    }

    .select-date {
      .MuiInputBase-root {
        height: 40px;
        width: 170px;
        border-radius: 0;
        border: 1px solid #024c9c;
        font-family: 'Caros Soft Bold';
        font-size: 14px;
        color: #004178;

        .MuiSvgIcon-root {
          color: #004178;
        }

        fieldset {
          border: none;
        }
      }

      .MuiSelect-root {
        padding: 11px 34px 11px 14px;
        border: none;

        border-radius: 0;
        &:focus {
          background-color: rgba(203, 221, 254, 0.25);
        }
      }
    }
  }
  .card-container {
    margin-left: 40px;
    .info {
      span {
        font-family: 'Caros Soft Bold';
      }

      a {
        color: #004178;
        cursor: pointer;
        font-weight: bold;
        text-decoration: underline;
        font-size: 14px;
      }
    }

    .card {
      overflow: hidden;
      min-width: 400px;
      // margin: 0 20px 20px 0;
      h3 {
        margin: 0;
        font-size: 20px;
      }
      .content {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 20px;

        width: 100%;
        height: 200px;
        border: 2px solid #efefef;

        .info {
          padding-right: 20px;
          position: relative;

          .percent {
            position: absolute;
            top: 88px;
            text-transform: uppercase;

            h6 {
              margin: 0;
              font-size: 11px;
            }

            &.red {
              color: #f6b29d;
            }

            &.green {
              color: #4faf98;
            }
          }
        }

        h2 {
          margin: 10px 0;
          font-size: 40px;
          font-weight: bold;
        }

        &.income h2 {
          color: #4aa896;
        }

        &.expense h2 {
          color: #f6b29d;
        }

        p {
          margin: 24px 0 0 0;
          max-width: 400px;
          span {
            color: #004178;
            cursor: pointer;
            font-weight: bold;
            text-decoration: underline;
            font-size: 16px;
          }
        }

        .bottom-bar {
          height: 5px;
          width: 100%;
          background-color: #f6b29d;
          position: absolute;
          bottom: 0;
          left: 0;

          &.expense {
            background-color: #f6b29d;
          }

          &.income {
            background-color: #4aa896;
          }
        }
      }
    }
    .chart-box {
      width: 350px;
    }
    .container-chart {
      display: flex;
      justify-content: center;
    }
    .chart {
      width: 100%;
      padding: 20px;
    }
    .flex {
      display: block;
    }
    .none {
      display: none;
    }
  }
}

.tool_finances_breadCrumbLink {
  color: #fff !important;
  text-transform: uppercase !important;
  text-decoration: none !important;
  font-size: 10pt !important;

  @media (max-width: 436px) {
    font-size: 11px !important;
  }
}

.tool_finances_breadCrumb {
  color: #fff !important;
  margin-bottom: 20px !important;
  font-weight: bold !important;

  @media (max-width: 599px) {
    margin-bottom: 10px !important;
    margin-top: -14px !important;
  }
}

.tool_finances_breadCrumbLabel {
  color: #fff !important;
  text-transform: uppercase !important;
  font-size: 10pt !important;
}

.containerTools {
  margin-top: 30px;
  margin-bottom: 30px;
  flex-direction: row;
  justify-content: center;
  width: 50%;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 599px) {
    margin-top: 10px;
    width: 100%;
    overflow: hidden;
  }
}

@import '../../Layout/variables';

.modal-info-tutorial {
  z-index: 1000 !important;

  .loading-area {
    justify-content: center;
    margin-top: 40px;
  }

  .MuiPaper-rounded {
    border-radius: 0;
  }

  .dialog-header {
    margin: 0;
    display: flex;
    padding: 10px;
    position: relative;
    height: 60px;
    background-color: $primary-color;
    align-items: center;

    .MuiFormControl-root {
      padding: 0;
      margin: 0;
    }

    .MuiInputBase-root {
      #month-select {
        margin-top: 1px;
      }

      border-radius: 0;
      border: none;
      font-family: 'Caros Soft Regular';
      font-size: 14px;
      color: white;
      padding-left: 4px;
      text-decoration: underline;
      input {
        cursor: pointer;
        padding: 0;
        font-size: 14px;
        text-transform: uppercase;
        text-decoration: underline;
      }

      .MuiSvgIcon-root {
        color: white;
      }

      fieldset {
        border: none;
        padding: 0;
      }
    }

    .MuiSelect-root {
      border: none;
      padding: 0 30px 0 0px;

      border-radius: 0;
      &:focus {
        background-color: inherit;
      }
    }

    .close-button {
      position: absolute;
      right: 5px;
      top: 5px;
      color: white;
    }

    &::before {
      content: '';
      background-image: url(/images/mdb-icon-on-dark.svg);
      height: 41px;
      width: 38px;
      display: block;
      position: absolute;
      top: 7px;
      left: 15px;
    }

    h2 {
      margin: 0 0 0 48px;
      color: #fff;
      font-size: 14px;
      font-family: 'Caros Soft Regular';
      font-weight: 300;
      text-transform: uppercase;
    }
  }

  .progress-floater {
    .close-button {
      position: absolute;
      right: 5px;
      top: 5px;
      color: white;
    }
  }

  .dialog-content {
    margin: 0;
    padding: 0 !important;
    height: 550px;
    @media (max-height: 800px) {
      height: 400px;
    }
    @media (max-height: 615px) {
      height: 300px;
    }

    .tutorial-container {
      overflow: scroll;
      background-color: #fff;
      padding: 0 30px 16px 16px;
      text-align: left;
      border-top: none;
      color: #444448;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      ol {
        display: flex;
        flex-direction: column;
        li{
          margin-bottom: 16px;
          a {
            color: #004178;
            cursor: pointer;
            font-weight: bold;
            text-decoration: underline;
            
          }
        }

        li::marker {
          font-weight: bold;
          color: #2e2e2e;
          font-size: 20px;

        }
      }

      h1 {
        margin-top: 16px;
        color: $primary-color;
        font-size: 24px;
        text-align: center;
      }

      h2 {
        margin-bottom: 8px;
        color: #2e2e2e;
        font-size: 20px;
        span {
          color: #004178;
          font-weight: bold;
        }
      }
      .img{
        margin-top: 24px;
        display: flex;
        width: 100%;
        justify-content: center;
        
        
        img {
          align-items:center;

          width: 400px;
        }
        
      }
     
    }
  }

  .dialog-footer {
    background-color: #fff;
    padding: 20px 40px 30px 40px;

    &.space-between {
      justify-content: space-between;
    }
  }

  .MuiButton-contained.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .MuiDialog-paperWidthXs {
    max-width: 475px;
  }
  .MuiDialog-paperWidthMd {
    max-width: 820px;
  }
}
