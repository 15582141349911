.toolsItem {
  min-height: 220px;
  background: #fff;
  margin-bottom: 70px;
  padding: 20px 20px 20px 20px;
}

.toolsTitleBar {
  color: #2e2e2e;
  font-size: 25px;
  font-weight: bold;
  padding-bottom: 20px;
  height: 40px;
  margin-left: 20px !important;

  @media only screen and (max-width: 599px) {
    font-size: 20px;
  }
}

.classContainertools {
  padding-right: 10px;
  overflow-x: auto;

  @media (max-width: 599px) {
    padding-right: 0px;
  }

  .toolsTitleBar{
    padding-right: 10px;
  }
}

.tool_finances_breadCrumbLink {
  color: #fff !important;
  text-transform: uppercase !important;
  text-decoration: none !important;
  font-size: 10pt !important;

  @media (max-width: 436px) {
    font-size: 11px !important;
  }
}

.tool_finances_breadCrumb {
  color: #fff !important;
  margin-bottom: 20px !important;
  font-weight: bold !important;

  @media (max-width: 599px) {
    margin-bottom: 10px !important;
    margin-top: -14px !important;
  }
}

.tool_finances_breadCrumbLabel {
  color: #fff !important;
  text-transform: uppercase !important;
  font-size: 10pt !important;
}

.containerTools {
  margin-top: 30px;
  margin-bottom: 30px;
  flex-direction: row;
  justify-content: center;
  width: 50%;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 599px) {
    margin-top: 10px;
    width: 100%;
    overflow: hidden;
  }
}
