.calc_indice_container {
  .calc_indice {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;

    img {
      width: 800px;
    }
  }
}
