@import '../../../Layout/variables';

// .modal-info {
//   z-index: 4000 !important;
//   .MuiPaper-rounded {
//     border-radius: 0;
//   }

//   .dialog-content {
//     border-radius: 0px;
//     display: flex;
//     flex-direction: column;
//     padding: 40px !important;
//     align-items: center;
//     justify-content: center;
//     text-align: center;
//     .img-container {
//       img {
//         width: 200px;
//         height: 200px;
//       }
//     }
//     h1 {
//       font-family: 'Caros Soft ExtraBold';
//       font-weight: bold;
//       text-transform: uppercase;
//       font-size: 36px;
//       margin: 16px 0;
//       color: #004178;
//     }

//     h2 {
//       font-family: 'Caros Soft Regular';
//       font-size: 24;
//       font-weight: normal;
//       margin: 0 0 16px 0;
//       color: #004178;
//     }
//     span {
//       color: #a3d2b1;
//     }
//   }
// }

.container-tenant-shop-modal {
  .dialog-footer {
    margin: 0 40px;

    @media (max-width: 510px) {
      margin: 0px 40px;
    }
    button {
      line-height: 1.5;
    }
  }
}
.dialog-content {

  // .dialog-container-tenant {
  //   // height: 520px;
  // }

  .loading-container {
    margin: 60px 0px;
  }
}
.tenant-catalog-container {
  height: 685px;
  padding: 40px 40px 24px 40px !important;

  @media (max-height: 850px) {
    height: 480px;
  }

  .header-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    background-color: #fff;
    justify-content: flex-start;
    margin-bottom: 32px;
    



    img {
      width: 180px;
      height: 180px;
      position: absolute;
      top: -20px;
      right: 0px;
      display: border-box;

      @media (max-width:  680px) {
        position: inherit;
          
      }
    }
    .header-content{
      max-width: 600px;
      flex-direction: column-reverse;

      @media (max-width:  900px) {
        max-width: 450px;
          
      }

      @media (max-width:  750px) {
        max-width: 380px;
          
      }

      @media (max-width:  680px) {
        flex-direction: column;
        max-width: 100%;
          
      }
      

    }
    .tenant-name {
      padding: 20px 0;
      flex: 1;
      flex-direction: column;
      h2 {
        margin: 0;
        padding: 8px 0;
        font-size: 18px;
        font-weight: bold;
      }
      p {
        margin: 0;
        padding: 0;
        font-size: 12px;
      }
    }
    .points-container {
      flex-direction: column;
      padding: 0px 0px 0px 0px;
      
      line-height: 1;
      color: $primary-color;
      font-size: 12px;
      .points {
        padding: 0;
        margin: 0;
        font-size: 38px;
        font-weight: 700;
      }
    }
  }
  .cards-container {
    // position: relative;

    // &::after {
    //   content: ' ';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   background-color: rgba(200, 200, 200, 0.5);
    //   margin-left: -40px;
    //   margin-right: -40px;
    //   margin-top: -230px;
    //   margin-bottom: -24px;
    // }

    @media (max-width: 680px) {
      justify-content: center;
    }
    .card-container {
      margin-right: 24px;
      margin-bottom: 24px;
      border: 1px solid #e6e6e6;
      border-radius: 1px;
      min-height: 465px;
      width: 265px;
      min-width: 265px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 24px;
      @media (max-width: 680px) {
        margin-right: 12px;
        margin-left: 12px;
      }

      position: relative;

      .MuiCardContent-root {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: left;

        .image-container {
          height: 265px;
          width: 265px;
          overflow: hidden;
          text-align: center;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        .description {
          margin: 32px 20px 0 20px;
          min-height: 60px;
          text-align: left;
          font-size: 14px;
          color: #2e2e2e;
        }
        .point-cost {
          margin: 8px 20px 0 20px;
          overflow: hidden;
          text-align: left;
          font-size: 16px;
          font-weight: bold;
          color: $primary-color;
        }

        .button-redeem {
          margin: 24px 20px 0 32px;
          color: $primary-color;
          button {
            line-height: 1.5;
            margin: 0 auto;
          }
        }
        .status {
          padding-top: 8px;
          font-size: 12px;
          font-weight: 700;
          color: $primary-color;
          text-transform: uppercase;

          &.orange {
            color: #f6b29d;
          }
          &.green {
            color: $secondary-color;
          }
        }
      }
    }
  }
}

.item-shop-container {
  height: 605px;
  @media (max-height: 850px) {
    height: 395px;
  }
  padding: 40px 40px 24px 40px !important;

  .item-container {
    padding-top: 24px;
    padding-left: 0px;
    @media (max-width: 510px) {
      padding-left: 0px;
    }

    .item-content {
      flex-direction: row;
      flex-wrap: nowrap;
      @media (max-width: 770px) {
        flex-wrap: wrap;
      }

      .image-container {
        margin-right: 20px;
        height: 200px;
        width: 300px;
        min-width: 300px;
        overflow: hidden;
        // margin-left: 100px;
        @media (max-width: 770px) {
          margin-right: 0;
          text-align: left;
          margin-bottom: 16px;
        }

        img {
          height: 200px;
          width: 200px;
        }
      }
      .redeemed-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;

        .info-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
        }

        h2 {
          font-size: 26px;
          color: $primary-color;
          margin: 8px 0;
        }

        p {
          margin-top: 16px;
          &.date {
            color: #797979;
          }
        }
        span {
          font-weight: bold;
          // color: #000;
        }
      }
      .redeem-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 770px) {
          justify-content: flex-start;
        }

        .info-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          @media (max-width: 770px) {
            display: block;
            justify-content: flex-start;
          }

          .top-info {
            flex: 1;
          }
          .bottom-info {
            color: #797979;
          }
        }

        h2 {
          font-size: 18px;
          color: $primary-color;
          margin: 8px 0 16px 0;
        }
        p {
          margin-top: 8px;
        }

        span {
          font-weight: bold;
          // color: #000;
        }
        .buttons-container {
          margin-top: 20px;
          display: flex;
          @media (max-width: 770px) {
            flex-wrap: wrap;
          }

          button {
            @media (max-width: 463px) {
              margin-top: 8px;
            }
            line-height: 1.5;
            &:nth-child(1) {
              margin-right: 8px;
            }
          }
        }
      }
    }
    .item-description {
      display: flex;
      flex-direction: column;
      margin: 36px 0 24px 0;
      h3 {
        font-size: 14px;
      }
      p {
        color: #797979;
        font-size: 12;
      }
    }
  }
}
