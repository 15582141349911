.storie-container {
  .dark-mode{
    aside {
      button{
        color: #ffffff;
      }
    }
    
  }
  .storie {
    @media (min-width: 790px) {
      width: 432px !important;
      height: 768px !important;
      margin: 0 auto !important;
    }

    @media (max-width: 789px) {
      width: 302px !important;
      height: 537px !important;
      margin: 0 auto !important;
    }

    @media (max-height: 768px){
      width: 302px !important;
      height: 537px !important;
      margin: 0 auto !important;
    }
  }

  button{
    @media (max-width: 650px) {
      padding: 25px;
    }
  }
}

html.reveal-full-page {
  overflow: unset;

  .reveal-viewport {
    overflow: unset;
  }

  .slides {
    inset: 151px auto auto 50% !important;
  }

  
}
