.current_study_f14 {
  font-size: 14px;
}

.current_stydy_ul_study {
  list-style: none;
  margin: 0;
  padding: 0;
}

.current_stydy_icon_study {
  width: 76px;

  @media (max-width: 599px) {
    width: 60px;
    margin-top: 20px;
    height: 60px;
  }
}

.current_stydy_mt {
  margin-top: 20px;
}

.current_stydy_pL {
  padding-left: 20px;
}
