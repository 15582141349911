@import '../../../Layout/variables';

.index-detail-body {
    .content-index-detail {
        a {
            color          : $primary-color;
            text-decoration: none;
        }

        ul {
            list-style: none;

            li::before {
                content    : "\2022";
                color      : $primary-color;
                font-weight: bold;
                display    : inline-block;
                width      : 1em;
                margin-left: -1em;
            }
        }
    }
}