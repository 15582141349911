.privacity_box {
  border: 1px solid #d3dae6;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 520px;
  width: 100%;
  border-radius: 2px;

  section {
    display: flex;
    flex-direction: column;
    text-align: center;

    .privacity_content {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;

      .privacity_circular {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: #2e2e2e;
        margin-right: 6px;
      }
    }
  }
}
.privacity_hrs{
  font-weight: 500;
  font-size: 12px;
  text-align: right;
}

.privacity_border {
  border: 1px solid #ccc;
}

.privacity_buttonPosition {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  width: 100%;

  &.privacity_isLoading {
    button {
      background: #cfcfcf !important;
      color: #cfcfcf !important;
      border: none;
      display: flex;
      padding-left: 30px;
      min-width: 80px !important;

      svg {
        width: 20px !important;
        height: 20px !important;
        margin-left: 0px !important;
      }

      .MuiCircularProgress-indeterminate {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}
.privacity_buttonPosition2{
  margin-top: 40px;
}
.privacity_isLoading2{
  button {
    background: #cfcfcf !important;
    color: #cfcfcf !important;
    border: none;
    display: flex;
    min-width: 80px !important;

    svg {
      width: 20px !important;
      height: 20px !important;
      margin-left: 0px !important;
    }

    .MuiCircularProgress-indeterminate {
      width: 20px !important;
      height: 20px !important;
    }
  }
}
