.last_trophy_icon_study {
  width     : 70px;
  margin-top: -26px;

  @media (max-width: 599px) {
    width        : 60px;
    height       : 60px;
    margin-top   : 20px;
    margin-bottom: 22px;
  }

  @media (max-width: 1066px) {
    margin-left: -6px;
  }

  @media (max-width: 1135px) {
    margin-top: -20px;
  }
}

.last_trophy_pL {
  padding: 0 20px;
}

.last_trophy_see_more {
  font-weight: 600;
  color      : #024c9c;
}

.last_trophy_container {
  width: 100%;
}

.last_trophy_text {
  display       : flex;
  flex-direction: column;
}

.last_trophy_txtTitle {
  font-size  : 42px;
  font-weight: 600;
}

.last_trophy_msg {
  font-size  : 16px;
  font-weight: 400;
  line-height: 20px;
}