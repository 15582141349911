.classDetailContainerQuestionQuiz {
  width: 100%;

  .classDetaiTextQuestion {
    margin-bottom: 30px;
    font-size: 20px;
  }

  .test_containerAnswer2Quiz {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 94%;
    min-height: 60px;
    margin-bottom: 10px;
    border: 2px solid #d3d3d3;
    cursor: pointer;
    font-size: 18px;
    color: #2e2e2e;
    padding: 0 10px 0 20px;

    @media (max-width: 970px) {
      width: 90%;
      font-size: 14px;
      padding: 0 8px 0 8px;
    }

    @media (max-width: 599px) {
      min-height: 50px !important;
    }
  }
  .test_containerAnswerSelected2Quiz {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 94%;
    min-height: 60px;
    margin-bottom: 10px;
    border: 2px solid var(--darkBlue);
    background-color: var(--darkBlue);
    cursor: pointer;
    font-size: 18px;
    color: #fff;
    padding-left: 20px;
    padding-right: 10px;

    @media (max-width: 960px) {
      width: 90%;
      font-size: 14px;
      padding: 0 8px 0 8px;
    }

    @media (max-width: 599px) {
      min-height: 50px;
    }
  }
  .answerCorretQuiz {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 94%;
    min-height: 60px;
    margin-bottom: 10px;
    border: 2px solid var(--green);
    background-color: var(--green);
    cursor: pointer;
    font-size: 18px;
    color: #fff;
    padding-left: 20px;
    padding-right: 10px;

    &.false {
      border: 2px solid #f00;
      background-color: #f00;
    }

    @media (max-width: 960px) {
      width: 94%;
      font-size: 14px;
      padding: 0 8px 0 8px;
    }

    @media (max-width: 599px) {
      min-height: 50px;
    }
  }

  @media (max-width: 599px) {
    span {
      font-size: 11px;
    }
  }
}
.classDetail_containerAnswerQuiz {
  width: 100%;
  margin: 24px 0 25px 12px;
}

.dailyQuiz_fade {
  &.none {
    display: none;
  }

  &.out {
    -webkit-animation: fade-out 1s ease-out both;
    animation: fade-out 1s ease-out both;
  }

  @-webkit-keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
