@font-face {
  font-family: 'trebuc';
  src: url(./assets/font/trebuc.ttf);
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Caros Soft Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Caros Soft Thin'), url('./assets/font/CarosSoftThin.woff') format('woff');
}

@font-face {
  font-family: 'Caros Soft Light';
  font-style: normal;
  font-weight: normal;
  src: local('Caros Soft Light'), url('./assets/font/CarosSoftLight.woff') format('woff');
}

@font-face {
  font-family: 'Caros Soft Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Caros Soft Regular'), url('./assets/font/CarosSoft.woff') format('woff');
}

@font-face {
  font-family: 'Caros Soft Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Caros Soft Bold'), url('./assets/font/CarosSoftBold.woff') format('woff');
}

@font-face {
  font-family: 'Caros Soft ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Caros Soft ExtraBold'), url('./assets/font/CarosSoftExtraBold.woff') format('woff');
}
  
// src: url(./assets/font/trebuc.ttf);

:root {
    --lightBlue: #CBDDFE;
    --offWhite: #F1EFE3;
    --beige: #EDE1C2;
    --gray: #C0C0C0;
    --grayLight: #d3d3d3;
    --green: #4FAF98;
    --lightGreen: #A3D2B1;
    --alternativeBlue: #004178;
    --primary: #0D509B;
    --primaryLight: #5DACFC;
    --secondary: #F8B249;
    --secondaryLight: #ffaa25;
    --white: #fff;
    --whiteLight: #ddd;
    --darkBlue: #024C9C;
    --danger: #FE6240;

    body {
      font-family: "Caros Soft Regular", trebuc, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      overflow-x: hidden;
    }

    input, textarea, select, button, a {
      font-family: "Caros Soft Regular", trebuc, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }

    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #FFF;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #959191;
    }
  }