.progress_course_courseItemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-height: 100%;
  background-color: #fff;
  box-shadow: 2px 2px 6px #0002;
  margin-bottom: 15px;
  height: 120px;
  border-right: 5px solid #FCDBCF;
}

.progress_course_container_courses {
  padding: 15px;
}

.progress_course_title {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  height: 100px;
  overflow-x: auto;
  margin-left: 10px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  height: 45px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (max-width: 700px) {
    font-size: 12px;
    height: 55px;
  }
}

.progress_course_containerLeft {
  height: 100%;
  width: 30%;
}

.progress_course_containerRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 70%;
  padding: 5px;
}

.progress_course_img {
  display: flex;
  align-items: center;
  width: 100%;
  background-position: center;
  background-size: cover;
  height: 100%;
}
