.points_bala_text_value_level {
  font-size  : 42px;
  font-weight: 600;
  color      : #2e2e2e;
}

.points_bala_ul_study {
  list-style: none;
  margin    : 0;
  padding   : 0;
}

.points_bala_icon_study {
  width     : 76px;
  margin-top: -8px;

  @media (max-width: 599px) {
    width        : 60px;
    height       : 60px;
    margin-top   : 20px;
    margin-bottom: 22px;
  }

  @media (max-width: 1322px) {
    margin-top : -22px;
    margin-left: -14px;
  }

  @media (max-width: 760px) {
    margin-left: -10px;
  }
}

.points_bala_pL {
  padding-left: 20px;
}

.points_bala_containerText {
  width: 195px;
}

.points_bala_msg {
  font-size  : 16px;
  font-weight: 400;
  line-height: 20px;
}