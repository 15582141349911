@import '../../../Layout/variables';
.expenses-select {
  .datepicker-container{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 10;

    .MuiSvgIcon-root{
      position: absolute;
      top: -2px;
      right: 24px;
      color: #fff;
      z-index: -1;
    }
    input{
      background-color: transparent;
    }
  }
  .dialog-header{
    h2{
      position: relative;
    }
    .datepicker-icon{
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .dialog-content {
    padding: 30px 32px 0 32px;
    
    .content-holder {
      h6 {
        text-transform: none;
        margin: 0 0 8px 0;
      }
      & > p {
        color: #004178;
        font-size: 18px;
        & span {
          font-weight: bolder;
        }
      }
      .card-container {
        border-radius: 1px;
        text-align: start;
        margin-top: 8px;

        @media (max-width: 646px) {
          justify-content: center;
        }

        .card {
          border-radius: 3px;
          cursor: pointer;
          background-color: #fff;
          border: 2px solid #004178;
          max-width: 170px;
          min-width: 170px;
          height: 140px;
          margin-bottom: 8px;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: #004178;
          margin: 8px;
          transition: 300ms ease-in;
          
          &:hover {
            border: 2px solid #4faf98;
            box-shadow: 0 0 3px #4faf98;
          }

          &.selected {
            border: 2px solid #4faf98;
            box-shadow: 0 0 3px #4faf98;
            color: #4faf98
          }

          & h5 {
            margin: 8px 0 0 0;
            font-size: 20px;
          }

          & p {
            margin: 12px 0 0 0;
            font-size: 14px;
            font-weight: bold;
          }
        }

        // &:hover {
        //   border: 1px solid #4FAF98;
        //   box-shadow: 0 0 3px #4FAF98;
        // }

        // &.selected {
        //   border: 1px solid #4FAF98;
        //   box-shadow: 0 0 3px #4FAF98;
        // }
        // & h5{
        //   margin: 0 0 16px 0;
        //   font-size: 16px;
        //   font-family: 'Caros Soft Bold';
        //   text-transform: none;
        //   text-align: start;
        //   color:#444448;
        // }
      }
    }
  }
}
