.perfil_user_container {
  padding-top: 8px;
}

.box1User{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 260px;
  margin-top: -40px;
  margin-bottom: 25px;
  // background-color: #559;

  .box1UserLeft{
    width: 385px;
    height: 233px;
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    // justify-content: space-around;
    margin-top: -30px;
    // background-color: #1185;

    div:first-child {
      margin-bottom: 60px;
     }

    div{
      width: 385px;
      height: 63px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 30px;
      flex-direction: row;
      font-size: 14px;
      font-weight: bold;
      color: #4B4B4B;
      border: 1px solid #D3DAE6;

      .textUser{
        margin-left: 8px;
        font-weight: normal;
        text-transform: lowercase;

      }
    }
  }

  .box1UserRight{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 385px;
    height: 233px;
    border: 1px solid #D3DAE6;
    padding: 20px;
    color: #4B4B4B;
    font-size: 12px;
    margin-top: -30px;

    .qrcodeBo{
      width: 170px;
      height: 170px;
    }

    .itensBo{
      width: 270px;
      height: 180px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .itemTitleBo{
        font-weight: bold;
        font-size: 14px;
      }

      div{
        font-weight: bold;
        font-size: 14px;
        span{
          font-weight: normal;
          margin-left: 4px;
          font-size: 14px;
        }
      }
    }
  }
}

.box2User{
  height: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -30px;

    div{
      margin-left: -22px;
    }
}

.skeletonProfile{
  padding: 15px;
  display: flex;
  flex-direction: column;

  div{
    padding: 15px;
    display: flex;
    flex-direction: row;
  }
  .skeletonItem{
    margin-left: 15px
  }
}
.titleGraphic{
  width: 100%;
  color: #4b4b4b;
  text-align: left;
  padding-left: 12px;
  font-size: 16px;
  font-weight: 700;
  margin-top: -20px;
  margin-bottom: 25px;
}