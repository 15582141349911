.customClassContainer {
  padding-right: 10px;

  @media (max-width: 599px) {
    padding-right: 10px;
  }
}

.customContainer {
  background-color: #fff;
  display: flex;
  min-height: 200px;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding-top: 25px;
  margin-bottom: 10px;
}

.buttonBlue {
  display: flex;
  width: 152px;
  height: 40px;
  text-transform: uppercase;
  justify-content: space-between;
  align-items: center;
  border: #004178 1px solid;
  background-color: #fff;
  color: #004178;
  font-size: 14px;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 25px;
  transition: color 0.3s ease-out, border-color 0.3s ease-out;

  &:hover {
    color: #5dacfc;
    border-color: #5dacfc;
    cursor: pointer;
  }
}

.iconPlus {
  margin-left: 10px;
  border-radius: 100px;
  width: 15px;
  height: 15px;
  background-color: #013670;
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.customTitle {
  width: 100%;
  margin-left: 20px;

  @media (max-width: 599px) {
    font-size: 20px;
    margin-left: 5px;
  }
}
