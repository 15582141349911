.report-container {
  min-width: 1600px !important;
  max-width: 1600px !important;
  min-height: 2500px;
  max-height: 2500px;
  height: 140vh;
  background: #f5f5f5 !important;
  box-sizing: border-box;
  margin-top: 0px;

  .loadingPdf {
    z-index: 99999;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    font-size: 30px;
    background: #fff;
    position: fixed;
  }

  header {
    width: 100%;
    border: none;
    // padding: 0px 5vw 0px 5vw;
    padding: 0px 0px 0px 0px;
    // height: 230px;
    height: 120px;
    position: relative;
    background-color: #004178;
  }
  .logo-container-pdf {
    height: 120px;
    padding: 16px;

    .logo-pdf {
      height: 100%;
    }
  }
}

.sidebar {
  padding-right: 25px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -100px;

  @media (max-width: 600px) {
    padding-left: 25px;
  }

  .MuiPaper-root {
    margin-bottom: 20px;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 40px);
  font-size: 30px;
}

.home-saude-financeira {
}

.msg {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #ccc;
  padding-left: 20px;
}

.containerChart {
  width: 100%;
}

.my-index-result-label {
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600;
}

.my-index-result {
  color: #45af98;
  font-size: 48px;
  font-weight: 700;
  margin-top: 30px;
}

.paper.print {
  height: 280px !important;
}
