.current_level_text_value_level {
  font-size  : 42px;
  font-weight: 600;
  color      : #2e2e2e;
}

.current_level_ul_study {
  list-style: none;
  margin    : 0;
  padding   : 0;
}

.current_level_icon_study {
  width      : 94px;
  margin-left: -10px;
  margin-top : -15px;

  @media (max-width: 599px) {
    width        : 60px;
    height       : 60px;
    margin-top   : 20px;
    margin-bottom: 22px;
  }

  @media (max-width: 1330px) {
    margin-top : -30px;
    margin-left: -25px;
  }
}

.current_level_pL {
  padding-left: 20px;
}

.current_level_msg {
  font-size  : 16px;
  font-weight: 400;
  line-height: 20px;
}

.current_level_containerText {
  width: 195px;
}