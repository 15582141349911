@import "../../logged_in/Layout/variables";
.registerStepper2 {
  min-height: 70vh;
  max-height: 80vh;

  @media (max-width: 700px) {
    min-height: 60vh;
    max-height: 60vh;
  }

  .welcome-title {
    color: $primary-color;
  }

  h4 {
    margin-bottom: 10px;
    margin-top: 10px;
    color: $secondary-color;
    font-size: 16px;
  }

  .persona-description {
    position: relative;
  }

  .ornament {
    font-size: 18px;
    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }
  }

  .profile-content {
    h1 {
      color: $secondary-color;
      margin-bottom: 0;

      span {
        color: #ccc;
      }
    }
  }
}
