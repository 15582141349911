.level_cardContent {
  display        : flex;
  justify-content: flex-start;
  align-items    : center;
  margin-top     : 10px;
  margin-bottom  : 20px;

  @media (max-width: 599px) {
    margin-top    : 40px;
    margin-bottom : 0px;
    flex-direction: column;
  }
}

.level_imgIcon {
  width: 75%;

  @media (max-width: 599px) {
    width : 110px;
    height: 110px;
  }

  @media only screen and (max-width: 900px) {
    width : 135px;
    height: 135px;
  }
}

.level_profile {
  display        : flex;
  align-items    : center;
  justify-content: center;
  width          : 160px;
  height         : 160px;

  @media (max-width: 599px) {
    width     : 110px;
    height    : 110px;
    margin-top: "";
  }
}

.level_containerLeft {
  display         : flex;
  align-items     : center;
  flex-direction  : column;
  width           : 190px;
  height          : 220px;
  margin-left     : 20px;
  background-color: #fff;

  @media (max-width: 599px) {
    width          : 110px;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
    margin-left    : 5px;
    height         : 100%;
  }
}

.level_titleProgress {
  font-size  : 20px;
  font-weight: 500;

  @media (max-width: 599px) {
    font-size: 16px;
  }
}

.level_subTitleProgress {
  font-size  : 28px;
  font-weight: bold;

  @media (max-width: 599px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 900px) {
    font-size: 24px;
  }
}

.level_texts {
  display       : flex;
  flex-direction: column;
  width         : 97%;
  height        : 160px;
  margin-left   : 3%;

  @media (max-width: 599px) {
    height     : 110px;
    align-items: center;
    margin-left: 0px;
  }
}

.level_title {
  font-size         : 18px;
  font-weight       : bold;
  color             : #2e2e2e;
  margin-top        : 8px;
  width             : 266px;
  text-align        : center;
  display           : -webkit-box;
  overflow          : hidden;
  text-overflow     : ellipsis;
  white-space       : normal;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  @media (max-width: 599px) {
    width: 80vw;
  }
}

.level_subtitle {
  bottom    : 0;
  color     : #7f7f7f;
  box-shadow: 0px -40px 13px -9px #000;
  text-align: center;
  width     : 100px;

  @media (max-width: 599px) {
    font-size : 12px;
    box-shadow: 0px -25px 12px -7px #000;
    width     : 90px;
  }
}

.level_progressDivided {
  width        : 100%;
  margin-left  : 20px;
  display      : block;
  /* default   : inline-block */
  border       : 0 none;
  background   : #e5e5e5;
  border-radius: 14px;

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    height     : 10px;
    margin-left: 0px;
  }

  &::-webkit-progress-value {
    border-radius: 12px;
    background   : #5088c3;
  }

  &::-webkit-progress-bar {
    background: transparent;
  }

  &::-moz-progress-bar {
    border-radius: 12px;
    background   : #5088c3;
  }
}

.level_contentProgressDivided {
  margin-top : 30px;
  width      : 80%;
  display    : flex;
  align-items: center;

  @media (max-width: 599px) {
    margin-top: 20px;
  }
}

.level_skeleton {
  width      : 100vw !important;
  height     : 250px !important;
  margin-left: 10px !important;

  @media (max-width: 599px) {
    width : 35vw !important;
    height: 170px !important;
  }
}