@import "../../Layout/variables";

#___reactour > div:first-of-type {
  opacity: 0.7;
}

#___reactour div.reactour__helper {
  opacity: 1;
}

div.reactour__helper {
  border-radius: 0 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  padding: 35px !important;
}

.modal {
  &-container {
    width: 540px;
    height: auto;
    position: relative;
    box-shadow: 4px 4px 10px #0003;
    background-color: #fff;
    padding-bottom: 30px;
  }

  &-leave {
    color: var(--primary);
    font-weight: 600;
    margin-top: 20px;
    text-align: center;
    cursor: pointer;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 48px;

    &-top {
      background: #a3d2b1;
      height: 142px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 80px;
      padding: 10px;
    }

    &-border {
      border: 2px solid #fff;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-middle {
      margin-top: 25px;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
    }

    &-bottom {
      button {
        padding: 10px 20px;
      } 
    }
  }
}

.indiceContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  span {
    text-align: center;
    font-size: 16px;
    margin: 10px 0px 20px 0px;
  }
}

.positionGauge {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;

  span {
    width: 55%;
  }
}

.positionMission {
  padding: 0px 20px 0px 20px;
  display: flex;

  span {
    font-size: 14px;
    width: 100%;
    padding: 0px 30px 30px 0px;
  }

  img {
    width: 94px;
    height: 94px;
    margin-top: -24px;

    @media only screen and (max-width: 1220px) {
      margin-top: 0px;
    }

    @media only screen and (max-width: 780px) {
      width: 50px;
      height: 50px;
      margin-top: 10px;
    }
  }
}

.feedContainer {
  width: 100%;

  @media only screen and (max-width: 599px) {
    margin-bottom: 60px;
  }
}

.containerHome {
  margin-bottom: 60px;
  padding-right: 10px;

  @media only screen and (max-width: 599px) {
    padding-right: 0px;
    margin-bottom: 70px;
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: auto;
}

.home-journey-locked {
  background-color: #cbddfe;
  padding: 20px;
  background-image: url("/images/home-content-locked-bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  background-position-y: bottom;

  @media only screen and (max-width: 1100px) {
    background-size: 35%;
  }

  @media only screen and (max-width: 430px) {
    background-image: unset;
  }

  h1 {
    margin-bottom: 5px;
    margin-top: 0;
    color: $primary-color;
    font-size: 28px;
    font-weight: 800;
  }

  p {
    margin-top: 0;
    font-size: 16px;
  }

  ol {
    margin-top: 0;
    margin-bottom: 28px;
    color: $primary-color;
    font-size: 16px;
    font-weight: bold;

    li{
      &.done {
        color: rgba(67, 175, 152, .7);
        text-decoration: line-through;
      }
    }
  }

  span {
    color: $primary-color;
    cursor: pointer;
    font-size: 18px;
    text-align: center;
  }
}
