.help_list_containerCard {
  margin-top: 30px;
  min-height: 220px;
  width: 100%;
  background-color: #fff;
  margin-bottom: 10px;
  box-shadow: 4px 4px 10px #0003;
  padding: 10px 10px 10px 20px;
  border-right: 5px solid #59aaff;

  &:nth-child(1) {
    p {
      font-size: 20px;
      font-weight: bold;
    }
  }
}

.help_list_title {
  font-size: 20px;
  font-weight: bold;

  @media (max-width: 599px) {
    font-size: 16px;
  }
}

.help_list_description {
  font-size: 16px;

  @media (max-width: 599px) {
    font-size: 14px;
  }
}
